import PropTypes from 'prop-types';
import InputOutputPort from '../nodes/InputOutputPort';

const InputOutputPorts = ({ nodes, parentId, handleType, handlePosition }) => {
  if (!nodes?.length) {
    return;
  }

  return (
    <div key={`${parentId}-${handleType}`} className="mb-2 mt-2 w-full">
      {nodes.map(node => (
        <InputOutputPort
          parentId={parentId}
          key={`${node.id}_${handleType}_${node.name}`}
          nodeName={node.name}
          label={node.label}
          nodeId={node.id}
          handleType={handleType}
          unit={node.type.unit}
          handlePosition={handlePosition}
          anchorable={node.anchorable}
        />
      ))}
    </div>
  );
};

InputOutputPorts.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  parentId: PropTypes.string,
  handleType: PropTypes.string,
  handlePosition: PropTypes.string,
};

export default InputOutputPorts;
