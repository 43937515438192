import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import userApi from '@/api/user';
import { userActions } from './userStore';

const initialValues = {
  csrfToken: null,
};

const useAuthStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'authStore',
      version: 1,
    }),
  ),
);

const { setState: set } = useAuthStore;

export const authActions = {
  login: async loginData => {
    try {
      const { data } = await userApi.login(loginData);
      const { csrf_token: csrfToken } = data;

      set({ csrfToken });
    } catch (error) {
      return {
        error: error?.response?.data?.detail,
      };
    }
  },
  logout: async () => {
    await userApi.logout();
    set({ csrfToken: null });
  },

  isAuthenticated: async () => {
    try {
      const { data } = await userApi.getUserInfo();
      const { csrf_token: csrfToken, email, name } = data;

      userActions.setUserInfo({ email, name });

      set({ csrfToken });

      return true;
    } catch (error) {
      return false;
    }
  },
};

export default useAuthStore;
