import PropTypes from 'prop-types';
import { Link, Form, redirect, json, useActionData, useParams, useLocation } from 'react-router-dom';
import Joi from 'joi';
import { addMember } from '@/api/projects';
import { USER_ROLES } from '@/consts';
import { parseErrors } from '@/utils/validators';

const schema = Joi.object({
  email: Joi.string().email({ tlds: false }).required(),
  role: Joi.string()
    .allow(...USER_ROLES)
    .required(),
});

const AddMemberModal = ({ title }) => {
  const errors = useActionData();
  const { state } = useLocation();
  const { projectId } = useParams();
  const member = state?.member ?? {};

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <Form method="post">
          <h3 className="font-bold text-lg">{title}</h3>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Email</span>
            </div>
            <input
              type="text"
              name="email"
              placeholder="Enter email"
              className="input input-bordered input-sm w-full"
              defaultValue={member?.email}
            />
            <div className="label">
              <span className="label-text-alt text-error">{errors?.email}</span>
            </div>
          </label>

          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Role</span>
            </div>
            <select
              name="role"
              defaultValue={member?.role}
              className="select select-bordered select-sm w-full capitalize"
            >
              <option value="">Choose role</option>
              {USER_ROLES.map(role => (
                <option key={role} value={role}>
                  {role}
                </option>
              ))}
            </select>
            <div className="label">
              <span className="label-text-alt text-error">{errors?.role}</span>
            </div>
          </label>

          <div className="modal-action">
            <Link className="btn btn-sm btn-secondary" to={`/projects/${projectId}`} replace>
              Cancel
            </Link>
            <button type="submit" className="btn btn-primary btn-sm">
              Save
            </button>
          </div>
        </Form>
      </div>
    </dialog>
  );
};

AddMemberModal.propTypes = {
  title: PropTypes.string,
};

AddMemberModal.action = async ({ request, params }) => {
  const form = await request.formData();
  const member = Object.fromEntries(form);
  const { projectId } = params;
  const { error } = schema.validate(member, { abortEarly: false });

  if (error) {
    const errors = parseErrors(error);
    return json(errors);
  }

  try {
    await addMember(projectId, member);
    return redirect(`/projects/${projectId}`);
  } catch (err) {
    console.error('adding member failed');
  }

  return json({});
};

export default AddMemberModal;
