import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const ListItemLink = ({ children, ...props }) => (
  <NavLink
    className={({ isActive }) =>
      `flex items-center justify-between rounded-md py-2 pr-2 pl-6 text-sm leading-6 text-gray-900 ${
        isActive ? 'font-semibold bg-gray-100' : 'hover:bg-gray-100'
      }`
    }
    to={props.to}
    {...props}
  >
    {children}
  </NavLink>
);

ListItemLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  state: PropTypes.object,
  replace: PropTypes.bool,
  preventScrollReset: PropTypes.bool,
  relative: PropTypes.oneOf(['route', 'path']),
};

export default ListItemLink;
