import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { useRef } from 'react';
import ExportDropdown from './ExportDropdown';
import './plugins';

const buildOptions = (text, yAxisLabel) => ({
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text,
    },
    tooltip: {
      callbacks: {
        label: context => {
          const [start, end] = context.raw;
          return (end - start).toFixed(3);
        },

        title: context => {
          return context[0].label.replaceAll(',', ' ');
        },
      },
    },
    yAxisLabel: {
      enabled: !!yAxisLabel,
      text: yAxisLabel,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 30, // Increase top padding to fit the label
    },
  },
  scales: {
    x: {
      stacked: true,

      ticks: {
        autoSkip: false,
      },

      border: {
        color: '#e7e5e4',
      },

      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        display: true,
        lineWidth: 2,
      },
    },

    y: {
      title: {
        display: false,
      },

      beginAtZero: true,

      border: {
        dash: [4, 4],
        display: false,
      },
    },
  },
});

const buildData = (data, label) => {
  return {
    labels: data.map(item => item.name),
    datasets: [
      {
        label,
        data: data.map(item => [item.start, item.end]),
        backgroundColor: data.map(item => item.backgroundColor),
        borderRadius: 0,
        borderSkipped: false,
        barThickness: 41,
      },
    ],
  };
};

const WaterfallChart = ({ data, label, yAxisLabel }) => {
  const options = buildOptions(label, yAxisLabel);
  const barData = buildData(data);
  const ref = useRef();

  return (
    <div className="min-h-80 relative mr-2">
      <ExportDropdown forwardedRef={ref} />
      <Bar ref={ref} options={options} data={barData} />
    </div>
  );
};

WaterfallChart.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default WaterfallChart;
