import PropTypes from 'prop-types';
import PathwayAnalysesList from '../analyses/PathwayAnalysesList';
import SystemAnalysesList from '../analyses/SystemAnalysesList';
import ListPane from './ListPane';

const AnalyzeDropdownContent = ({ pathways = [], systems = [] }) => {
  return (
    <ListPane>
      <PathwayAnalysesList pathways={pathways} />
      <SystemAnalysesList systems={systems} />
    </ListPane>
  );
};

AnalyzeDropdownContent.propTypes = {
  pathways: PropTypes.arrayOf(PropTypes.object),
  systems: PropTypes.arrayOf(PropTypes.object),
};

export default AnalyzeDropdownContent;
