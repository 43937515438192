import { useWatch } from 'react-hook-form';
import { isDefined } from '@/utils/miscUtils';
import { convert } from '@/utils/unitUtils';

export const useIsUserDefined = ({ name, control, defaultValue, conversion }) => {
  const [currentValue, currentConversion] = useWatch({
    control,
    name: [name, `conversion.${name}`],
    defaultValue,
  });

  if (!isDefined(defaultValue)) {
    return {
      isUserDefined: false,
    };
  }

  const convertedDefaultValue =
    defaultValue && conversion && currentConversion
      ? convert(conversion, JSON.parse(currentConversion), defaultValue)
      : defaultValue;

  const isUserDefined =
    isDefined(convertedDefaultValue) && isDefined(currentValue) && currentValue !== convertedDefaultValue;

  return {
    isUserDefined,
    convertedDefaultValue,
  };
};
