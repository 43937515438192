import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import MCFieldsSummary from './MCFieldsSummary';
import useMcParams from '../parameters/useMcParams';

const MCFieldsWrapper = ({ children, param }) => {
  const { control } = useFormContext();
  const isMcEnabled = useWatch({
    control,
    name: 'mc_params._enabled',
  });

  const { min, max } = useMcParams(param);

  const minValue = min.defaultValue?.toString();
  const maxValue = max.defaultValue?.toString();

  return (
    <>
      {!isMcEnabled && <MCFieldsSummary min={minValue} max={maxValue} />}
      <div className={`flex gap-4 ${isMcEnabled ? 'visible' : 'hidden'}`}>{children}</div>
    </>
  );
};

MCFieldsWrapper.propTypes = {
  children: PropTypes.node,
  param: PropTypes.object,
};

export default MCFieldsWrapper;
