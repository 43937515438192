import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

const initialValues = {
  collapsed: false,
};

const useMenuStore = create()(immer(() => initialValues));

const { setState: set } = useMenuStore;

// actions
export const menuAction = {
  close: () => {
    set({ collapsed: true });
  },
  open: () => {
    set({ collapsed: false });
  },
};

// selectors
export const useCollapsed = () => useMenuStore(store => store.collapsed);

export default useMenuStore;
