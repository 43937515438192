import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavLinkList = ({ links = [] }) => {
  return (
    <ul className={`flex flex-col text-center gap-x-4`}>
      {links.map(({ url, label, icon, target, end, dropdown }) => (
        <li key={url} className="relative group">
          <NavLink
            tabIndex={0}
            to={url}
            end={end}
            target={target}
            className={({ isActive }) =>
              `flex flex-col items-center gap-2 p-2 text-sm font-normal rounded hover:text-blue-500 ${
                isActive ? 'rounded-b-none font-semibold text-blue-500' : 'text-gray-600'
              }`
            }
          >
            {({ isActive }) => (
              <>
                <span className={`flex items-center justify-center rounded p-2 ${isActive ? 'bg-slate-200' : ''}`}>
                  {icon}
                </span>
                <span className="text-xs">{label}</span>
              </>
            )}
          </NavLink>
          {dropdown && (
            <div tabIndex={0} className="absolute left-full top-0 z-[11] hidden group-hover:block -translate-y-[20%]">
              <div className="rounded-box border-gray-75 border shadow-lg shadow-slate-200 bg-white min-w-80">
                <div className="max-h-[80vh] overflow-y-auto">{dropdown}</div>
              </div>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

NavLinkList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default NavLinkList;
