import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import usePrevious from '@/hooks/usePrevious';
import isEqual from 'react-fast-compare';

const useSetDefaultValue = (name, defaultValue, watchedFields, conversion) => {
  const { setValue } = useFormContext();
  const prevWatchedFields = usePrevious(watchedFields);
  const conversionStr = conversion ? JSON.stringify(conversion) : '';

  useEffect(() => {
    if (prevWatchedFields && !isEqual(prevWatchedFields, watchedFields)) {
      if (conversionStr) {
        setValue(`conversion.${name}`, conversionStr, { shouldTouch: false, shouldDirty: true });
        setTimeout(() => {
          setValue(name, defaultValue);
        }, 10);
      } else {
        setValue(name, defaultValue);
      }
    }
  }, [setValue, defaultValue, name, prevWatchedFields, watchedFields, conversionStr]);
};

export default useSetDefaultValue;
