import { caseActions, useIsBalanced } from '@/stores/caseStore';
import { nodeActions } from '@/stores/nodeStore';
import { useNodeCount } from '@/stores/pathwayStore';
import { ScaleIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';
import { useRouteLoaderData } from 'react-router-dom';

const BalanceButton = () => {
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useFormContext();
  const isBalanced = useIsBalanced();
  const nodeCount = useNodeCount();
  const nodeData = useRouteLoaderData('node');

  const saveAndRun = async data => {
    if (isDirty && nodeData?.node?.id) {
      await nodeActions.patchParams(nodeData?.node?.id, data);
      reset(data);
    }
    caseActions.balanceCase();
  };

  if (isBalanced) {
    return null;
  }

  if (!isBalanced && nodeCount > 0) {
    return (
      <button onClick={handleSubmit(saveAndRun)} className="btn btn-sm btn-primary">
        <ScaleIcon className="w-5 h-5" />
        Balance Pathway
      </button>
    );
  }

  return (
    <button className="btn btn-sm btn-primary btn-disabled">
      <ScaleIcon className="w-5 h-5" />
      Balance Pathway
    </button>
  );
};

export default BalanceButton;
