import PropTypes from 'prop-types';
import Header from '@/components/core/Header';
import Sidebar from './Sidebar';
import { ToastContainer } from 'react-toastify';

const Layout = ({ children }) => (
  <div className="flex flex-col h-screen">
    <Header />
    <main className="flex flex-1 bg-slate-50 min-h-0">
      <Sidebar />
      {children}
    </main>
    <ToastContainer />
  </div>
);

Layout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default Layout;
