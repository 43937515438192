import PropTypes from 'prop-types';
import Joi from 'joi';
import { SYSTEM_TYPES_OPTIONS } from '@/consts';

import { Form, redirect, json, Link, useActionData, useLoaderData, useParams } from 'react-router-dom';
import { createSystem, duplicateSystem, patchSystem } from '@/api/systems';
import { parseErrors } from '@/utils/validators';
import ProjectSelect from '../projects/ProjectSelect';
import { isBuildPath } from '@/utils/miscUtils';

const addSchema = Joi.object({
  name: Joi.string().required(),
  system_type: Joi.string()
    .allow(...SYSTEM_TYPES_OPTIONS)
    .required(),
});

const editSchema = Joi.object({
  name: Joi.string().required(),
});

const EditSystemModal = ({ title, action }) => {
  const { system, projects } = useLoaderData() ?? {};
  const { name, system_type: systemType } = system ?? {};
  const errors = useActionData();
  const { projectId } = useParams();
  const projectOptions = projects?.map(({ id, name }) => ({ label: name, value: id }));
  const defaultName = projects?.length ? `${name} [duplicate]` : name;

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <Form method="put">
          <h3 className="font-bold text-lg">{title}</h3>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Name</span>
            </div>
            <input
              type="text"
              name="name"
              placeholder="Enter system name"
              className="input input-bordered input-sm w-full"
              defaultValue={defaultName}
            />
            {action === 'duplicate' && <input type="hidden" name="curName" defaultValue={name} />}
            <div className="label">
              <span className="label-text-alt text-error">{errors?.name}</span>
            </div>
          </label>
          {action === 'add' && (
            <label className="form-control w-full">
              <div className="label">
                <span className="label-text">System Type</span>
              </div>
              <select name="system_type" defaultValue={systemType} className="select select-bordered select-sm w-full">
                {SYSTEM_TYPES_OPTIONS.map(systemType => (
                  <option key={systemType.value} value={systemType.value}>
                    {systemType.label}
                  </option>
                ))}
              </select>
              <div className="label">
                <span className="label-text-alt text-error">{errors?.system_type}</span>
              </div>
            </label>
          )}
          {projectOptions?.length && <ProjectSelect defaultValue={projectId} options={projectOptions} />}
          <div className="modal-action">
            <Link className="btn btn-sm btn-secondary" to={-1} replace>
              Cancel
            </Link>
            <button type="submit" className="btn btn-primary btn-sm">
              Save
            </button>
          </div>
        </Form>
      </div>
    </dialog>
  );
};

EditSystemModal.duplicateAction = async ({ request, params }) => {
  const { projectId, systemId } = params;
  const form = await request.formData();
  const { name, curName, project_id } = Object.fromEntries(form);

  if (name === curName) {
    return json({ name: 'Please choose different name' });
  }

  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  try {
    const { data } = await duplicateSystem(systemId, { name, project_id });
    return redirect(matchBuild ? `${actionPath}/systems/${data.id}` : actionPath);
  } catch (err) {
    return redirect(matchBuild ? `${actionPath}/systems/${systemId}/duplicate` : `${actionPath}/${systemId}/duplicate`);
  }
};

EditSystemModal.addAction = async ({ request, params }) => {
  const form = await request.formData();
  const system = Object.fromEntries(form);
  const { projectId } = params;
  const { error } = addSchema.validate(system, { abortEarly: false });

  if (error) {
    const errors = parseErrors(error);
    return json(errors);
  }

  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  try {
    const { data } = await createSystem(projectId, system);
    return redirect(matchBuild ? `${actionPath}/systems/${data.id}` : actionPath);
  } catch (err) {
    console.error('adding system failed');
  }

  return json({});
};

EditSystemModal.editAction = async ({ request, params }) => {
  const form = await request.formData();
  const system = Object.fromEntries(form);
  const { projectId, systemId } = params;
  const { error } = editSchema.validate(system, { abortEarly: false });

  if (error) {
    const errors = parseErrors(error);
    return json(errors);
  }

  const matchBuild = isBuildPath(request.url);
  const actionPath = matchBuild ? `/projects/${projectId}/build` : `/projects/${projectId}/dashboard`;

  try {
    await patchSystem(systemId, system);
    return redirect(matchBuild ? `${actionPath}/systems/${systemId}` : actionPath);
  } catch (err) {
    console.error('edit system failed');
  }

  return json({});
};

EditSystemModal.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
};

export default EditSystemModal;
