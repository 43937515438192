import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

const ExportDropdown = ({ forwardedRef, data }) => {
  const exportToImage = format => {
    if (forwardedRef.current) {
      const link = document.createElement('a');
      link.download = `chart.${format}`;
      link.href = forwardedRef.current.toBase64Image(`image/${format}`, 1);
      link.click();
    }
  };

  const exportToCSV = () => {
    let csvContent = 'data:text/csv;charset=utf-8,';
    const { datasets, labels, labelsColumn } = data;
    const headers = [labelsColumn, ...datasets.map(dataset => dataset.label)].filter(Boolean).join(',');
    csvContent += `${headers}\r\n`;
    const total = datasets[0].data.length;

    for (let i = 0; i < total; i++) {
      const rows = [];
      datasets.forEach(dataset => {
        if (labelsColumn) {
          const label = typeof labels[i] === 'object' ? labels[i].join(' ') : labels[i];
          rows.push(`"${label}"`);
        }
        rows.push(dataset.data[i]);
      });

      csvContent += `${rows.join(',')}\r\n`;
    }

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  const exportToPng = () => exportToImage('png');
  const exportToJpeg = () => exportToImage('jpeg');

  return (
    <div className="dropdown dropdown-bottom dropdown-end absolute -right-2 -top-2">
      <div tabIndex={0} role="button" className="btn m-1 btn-circle btn-sm btn-ghost">
        <EllipsisVerticalIcon className="w-5 h-5" />
      </div>
      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        <li>
          <button onClick={exportToPng}>Export to PNG</button>
        </li>
        <li>
          <button onClick={exportToJpeg}>Export to JPEG</button>
        </li>
        {data && (
          <li>
            <button onClick={exportToCSV}>Export to CSV</button>
          </li>
        )}
      </ul>
    </div>
  );
};

ExportDropdown.propTypes = {
  forwardedRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  data: PropTypes.object,
};

export default ExportDropdown;
