import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import { useRef, useState } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import config from '@/config';

const libraries = ['places'];

const isValidLatLng = latLngStr => {
  const parts = latLngStr.split(',');

  if (parts.length !== 2) return false;

  const [latStr, lonStr] = parts;
  const lat = Number(latStr);
  const lon = Number(lonStr);

  return lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
};

const LocationField = ({ param, name }) => {
  const { label, tooltip, source } = param;
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const errorMessage = error?.message;
  const inputRef = useRef(null);

  const [autocomplete, setAutocomplete] = useState(null);
  const address = watch(`${name}.address`);
  const lat = watch(`${name}.lat`);
  const lon = watch(`${name}.lon`);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
  });

  const registration = register(name, {
    validate: () => {
      return (address && lat && lon) || 'Address is required';
    },
  });

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const lat = place.geometry?.location?.lat();
      const lon = place.geometry?.location?.lng();
      const address = place.formatted_address || '';

      setValue(`${name}.lat`, lat);
      setValue(`${name}.lon`, lon);
      setValue(`${name}.address`, address, { shouldDirty: true });
      setTimeout(() => {
        trigger(name);
      }, 0);
    }
  };

  const handleClearInput = () => {
    setValue(`${name}.address`, '', { shouldDirty: true });
    setValue(`${name}.lat`, null);
    setValue(`${name}.lon`, null);

    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const handleBlur = () => {
    const addressInput = inputRef.current?.value;

    if (isValidLatLng(addressInput)) {
      const [latValue, lonValue] = addressInput.split(',').map(Number);
      setValue(`${name}.lat`, latValue, { shouldDirty: true });
      setValue(`${name}.lon`, lonValue, { shouldDirty: true });
      setValue(`${name}.address`, addressInput, { shouldDirty: true });
    }

    setTimeout(() => {
      trigger(name);
    }, 0);
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <FormRow label={label} tooltip={tooltip} source={source} name={name}>
      <div className="flex flex-col flex-1">
        <div className="relative flex">
          <Autocomplete className="w-full" onLoad={setAutocomplete} onPlaceChanged={handlePlaceChanged}>
            <input
              id={name}
              {...registration}
              type="text"
              ref={inputRef}
              name={`${name}.address`}
              defaultValue={address}
              onBlur={handleBlur}
              className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none input input-bordered input-sm w-full flex-1 pr-8 ${errorMessage ? 'input-error' : ''}`}
            />
          </Autocomplete>
          {address && (
            <button
              type="button"
              onClick={handleClearInput}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              ✕
            </button>
          )}
        </div>
        {lat && lon && (
          <div className="text-xs text-gray-500 mt-1">
            lat: {lat}, lon: {lon}
          </div>
        )}
        <ErrorMessage text={errorMessage} />
      </div>
    </FormRow>
  );
};

LocationField.propTypes = {
  param: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  defaultValue: PropTypes.object,
  watchedFields: PropTypes.object,
  children: PropTypes.node,
};

export default LocationField;
