import PropTypes from 'prop-types';
import { Link, useParams, useRouteLoaderData } from 'react-router-dom';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { settingsActions, useGettingStartedSettings } from '@/stores/settingsStore';

const renderStepAction = (step, isCompleted, projectId) => {
  switch (step) {
    case 'project':
      return isCompleted ? (
        <Link to="/" className="btn btn-sm btn-secondary group-hover:flex hidden">
          Show projects
        </Link>
      ) : (
        <Link to="/create-project" className="btn btn-sm btn-secondary group-hover:flex hidden">
          Create project
        </Link>
      );
    case 'member':
      return (
        <>
          <button
            onClick={() => settingsActions.skipGettingStartedStep(projectId, 'member')}
            className="btn btn-sm btn-secondary mr-2 group-hover:flex hidden"
          >
            Skip
          </button>
          <Link
            to={`/projects/${projectId}/dashboard/new-member`}
            className="btn btn-sm btn-primary group-hover:flex hidden"
          >
            Invite members
          </Link>
        </>
      );
    case 'pathway':
      return (
        <Link
          to={`/projects/${projectId}/dashboard/pathways/new`}
          className="btn btn-sm btn-primary group-hover:flex hidden"
        >
          Build pathway
        </Link>
      );
    default:
      return null;
  }
};

const Step = ({ title, isCompleted, children, step, projectId }) => {
  const { skippedSteps } = useGettingStartedSettings(projectId);
  const isSkipped = skippedSteps.includes(step);

  return (
    <li className="flex group justify-between h-16 items-center p-4 rounded-md hover:bg-gray-100">
      <div className="flex">
        <CheckCircleIcon
          className={`h-5 w-5 mr-2 ${isCompleted || isSkipped ? 'stroke-green-500' : 'stroke-gray-300'}`}
        />
        <p className={isCompleted || isSkipped ? 'line-through' : ''}>{title}</p>
      </div>
      <div className="flex">{children}</div>
    </li>
  );
};

Step.propTypes = {
  title: PropTypes.string,
  isCompleted: PropTypes.bool,
  children: PropTypes.node,
  step: PropTypes.string,
  projectId: PropTypes.string,
};

const GettingStartedPrompt = () => {
  const { project, pathways } = useRouteLoaderData('project');
  const { projectId } = useParams();

  const { isPromptClosed, skippedSteps } = useGettingStartedSettings(projectId);

  if (isPromptClosed) {
    return null;
  }

  const steps = [
    { id: 'project', title: 'Create your first project', isCompleted: !!project },
    { id: 'member', title: 'Invite members to collaborate', isCompleted: false },
    { id: 'pathway', title: 'Build your first pathway', isCompleted: pathways.count > 0 },
  ];

  const stepsCompleted = steps.filter(step => step.isCompleted || skippedSteps.includes(step.id)).length;

  const handleClosePrompt = () => {
    settingsActions.closeGettingStartedPrompt(projectId);
  };

  return (
    <div className="flex justify-center">
      <div className="mt-4 border border-gray-200 rounded p-2 w-1/2">
        <button onClick={handleClosePrompt} className="btn btn-sm btn-link float-right">
          <XMarkIcon className="h-5 w-5 stroke-gray-300 hover:stroke-gray-800" />
        </button>

        <div className="flex justify-between mt-6 mx-4">
          <p className="text-lg font-semibold text-md">Getting Started</p>
          <p className="">
            {stepsCompleted}/{steps.length} Steps
          </p>
        </div>
        <div>
          <p className="ml-4">Follow the steps below to get started.</p>
        </div>
        <div>
          <ul className="mt-4">
            {steps.map(({ id, title, isCompleted }) => (
              <Step key={id} title={title} isCompleted={isCompleted} step={id} projectId={projectId}>
                {renderStepAction(id, isCompleted, projectId)}
              </Step>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GettingStartedPrompt;
