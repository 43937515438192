import config from '@/config';
import axios from 'axios';

const { get, post } = axios;

export const retrieveAnalysis = id => get(`/v1/analyses/${id}/`);

export const analysesMap = {
  lca: 'lca',
  tea: 'tea',
  tea_breakdown: 'tea_breakdown',
  cash_flows: 'tea',
  characteristics: 'tea',
  balance: 'lca',
  mc: 'mc',
  sensitivity_lca: 'sensitivity',
  sensitivity_tea: 'sensitivity',
};

export const getAnalysisResults = (id, analysisType, data) =>
  post(`/v1/analyses/${id}/${analysesMap[analysisType]}/`, data);

export const getSensitivitySweep = id => get(`/v1/analyses/${id}/sensitivity_sweep/`);
export const startSensitivitySweep = (id, data) => post(`/v1/analyses/${id}/sensitivity_sweep/`, data);
export const getSensitivitySweepResults = (sweepId, data) => post(`/v1/sensitivity_sweeps/${sweepId}/results/`, data);
export const exportSensitivitySweepResultsLink = sweepId =>
  `${config.serverUrl}/v1/sensitivity_sweeps/${sweepId}/export/`;
