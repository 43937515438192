import { processConditionals, parseConditionalNames } from '@/utils/conditionalUtils';
import { getDefaultValue } from '@/utils/paramsUtils';
import { zipObject } from '@/utils/miscUtils';
import { useFormContext } from 'react-hook-form';

// process field and default conditionals
const useCheckConditionals = (params, prefix = '') => {
  const { watch, getValues } = useFormContext();
  const conversionParams = getValues('conversion');

  return params.reduce((acc, param) => {
    const { name, conditionals, baseConversion } = param;
    const conditionalNames = parseConditionalNames(param, prefix);

    const conditionalValues = conditionalNames?.length ? watch(conditionalNames) : [];
    const watchedFields = zipObject(conditionalNames, conditionalValues);

    const defaultValue = getDefaultValue(param, watchedFields, conversionParams, prefix);

    const fieldCondMet = processConditionals(conditionals, prefix, conversionParams, baseConversion, watchedFields);

    acc[`${prefix}${name}`] = {
      fieldCondMet,
      defaultValue,
      watchedFields,
    };
    return acc;
  }, {});
};

export default useCheckConditionals;
