import { FormProvider, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import Select from '@/components/core/form/Select';
import { useEffect } from 'react';
import { getNodeOptions } from '@/utils/pathwayUtils';
import debounce from 'debounce';
import { useNodes } from '@/stores/analysisStore';
import { useNodeParamsOptions, useParamCategories, useWorkbench, workbenchActions } from '@/stores/workbenchStore';
import { unitToString } from '@/utils/unitUtils';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';

const WorkbenchTableForm = ({ defaultValues, onSubmit, showAddButton, showRemoveButton }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch, register, control, setValue } = methods;
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);
  const nodes = useNodes();
  const nodeOptions = [{ label: '', value: '' }, ...getNodeOptions(nodes)];
  const defaultSteps = defaultValues?.steps ?? 5;
  const [nodeId, category, paramName] = useWatch({
    control,
    name: ['nodeId', 'category', 'paramName'],
    defaultValue: defaultValues,
  });

  const categories = [{ value: '', label: '' }, ...useParamCategories(nodeId)];
  const paramOptions = useNodeParamsOptions(nodeId, category);
  const param = useWorkbench(defaultValues.key);
  const defaultMin = param?.min;
  const defaultMax = param?.max;
  const baseline = param?.baseline;
  const unit = unitToString(param?.unit);

  useEffect(() => {
    if (nodeId !== defaultValues.nodeId) {
      setValue('category', '');
    }
  }, [nodeId, defaultValues.nodeId, setValue]);

  useEffect(() => {
    if (category !== defaultValues.category) {
      setValue('paramName', '');
    }
  }, [category, defaultValues.category, setValue]);

  useEffect(() => {
    setValue('min', defaultMin);
    setValue('max', defaultMax);
    setValue('unit', unit);
    setValue('baseline', baseline);
    setValue('steps', defaultSteps);
  }, [paramName, setValue, defaultMin, defaultMax, baseline, unit, defaultSteps]);

  useEffect(() => {
    const subscription = watch(debouncedSave);
    return () => subscription.unsubscribe();
  }, [watch, debouncedSave]);

  return (
    <FormProvider {...methods}>
      <tr>
        <td>
          <Select name="nodeId" className="select-xs min-w-full" options={nodeOptions} />
        </td>
        <td>
          <Select name="category" className="select-xs min-w-full" options={categories} />
        </td>
        <td>
          <Select name="paramName" className="select-xs min-w-full" options={paramOptions} />
        </td>
        <td>
          <input {...register('min')} name="min" type="text" className="w-16 input input-xs input input-bordered" />
        </td>
        <td>
          <input
            readOnly
            {...register('baseline')}
            name="baseline"
            type="text"
            className="w-16 input input-xs input input-bordered"
          />
        </td>
        <td>
          <input {...register('max')} name="max" type="text" className="w-16 input input-xs input input-bordered" />
        </td>
        <td>
          <input
            readOnly
            {...register('unit')}
            name="unit"
            type="text"
            className="w-14 input input-xs input input-bordered"
          />
        </td>
        <td>
          <div className="join">
            <input
              {...register('steps')}
              name="steps"
              type="text"
              className="w-14 join-item input input-xs input input-bordered"
            />
            {showAddButton && (
              <button onClick={workbenchActions.add} className="btn btn-xs join-item btn-ghost">
                <PlusCircleIcon className="h-5 w-5" />
              </button>
            )}

            {showRemoveButton && (
              <button
                onClick={() => workbenchActions.remove(defaultValues.key)}
                className="btn btn-xs join-item btn-ghost"
              >
                <XCircleIcon className="h-5 w-5" />
              </button>
            )}
          </div>
        </td>
      </tr>
    </FormProvider>
  );
};

WorkbenchTableForm.propTypes = {
  defaultValues: PropTypes.object,
  pathwaysOptions: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
  showAddButton: PropTypes.bool,
  showRemoveButton: PropTypes.bool,
};

export default WorkbenchTableForm;
