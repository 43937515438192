import { useEffect, useRef } from 'react';

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  // destroy previous value on unmount
  useEffect(
    () => () => {
      ref.current = null;
    },
    [],
  );

  return ref.current;
};

export default usePrevious;
