import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { confirmable } from 'react-confirm';

const ConfirmModal = ({ show, proceed, label = 'Are you sure?' }) => {
  useEffect(() => {
    const handleEsc = event => {
      if (event.key === 'Escape') {
        proceed(false);
      }
    };

    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [proceed]);

  return (
    <dialog open={show} className="modal">
      <div className="modal-box">
        <h3 className="font-bold text-lg"></h3>
        <p className="py-4">{label}</p>
        <div className="modal-action">
          <form method="dialog">
            <button onClick={() => proceed(false)} className="btn btn-sm btn-secondary mr-2">
              No
            </button>
            <button onClick={() => proceed(true)} className="btn btn-sm btn-primary">
              Yes
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

ConfirmModal.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  proceed: PropTypes.func,
  show: PropTypes.bool,
};

const ConfirmableModal = confirmable(ConfirmModal);

export default ConfirmableModal;
