import PropTypes from 'prop-types';

const AnalysisBox = ({ children }) => (
  <div className="p-6 bg-base-100 rounded-xl flex-1 shadow-sm flex flex-col gap-4 min-h-0">{children}</div>
);

AnalysisBox.propTypes = {
  children: PropTypes.node,
};

export default AnalysisBox;
