import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useInteractions,
  FloatingPortal,
  safePolygon,
} from '@floating-ui/react';
import MarkdownViewer from './MarkdownViewer';

const Tooltip = ({ label, text, source }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  const hover = useHover(context, { handleClose: safePolygon() });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss]);

  return (
    <>
      <InformationCircleIcon
        ref={refs.setReference}
        {...getReferenceProps()}
        role="button"
        className="h-5 w-5 text-gray-300 hover:text-purple-500"
      />
      <FloatingPortal>
        {isOpen && (
          <div
            className="menu dropdown-content z-1000 w-80 rounded border border-gray-75 bg-base-100 p-10 shadow-md"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className="relative mb-6 flex">
              <div className="absolute -left-4 h-7.5 w-1.5 bg-purple-500"></div>
              <p className="text-sm font-bold">{label}</p>
            </div>
            <MarkdownViewer
              content={text}
              className="prose w-full mx-auto pb-0 prose-sm prose-h2:text-sm prose-h3:text-sm prose-p:text-sm"
            />
            {source && (
              <span className="mt-2 text-xs">
                {
                  <a className="text-purple-500" target="_blank" rel="noreferrer" href={source}>
                    [ Source ]
                  </a>
                }
              </span>
            )}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

Tooltip.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  source: PropTypes.string,
};

export default Tooltip;
