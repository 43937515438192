import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import DataGrid from '@/components/datagrids/DataGrid';
import { useDataframes } from '@/stores/systemAnalysisStore';

const DataFrameResults = () => {
  const dataframes = useDataframes();

  return dataframes.map((dataframe, index) => {
    return (
      <AnalysisContainer key={index} title={dataframe.label}>
        <AnalysisBox>
          <DataGrid rows={dataframe.rows} columns={dataframe.columns} />
        </AnalysisBox>
      </AnalysisContainer>
    );
  });
};

export default DataFrameResults;
