import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';

import GroupInputField from './GroupInputField';
import useCheckConditionals from '../useCheckConditionals';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import { buildGroupValidators } from '@/utils/validators';
import Tooltip from '@/components/core/Tooltip';

const GroupField = ({ param }) => {
  const {
    getValues,
    formState: { errors },
    control,
  } = useFormContext();

  const { name, params, initValidators, label, tooltip, source } = param;
  const error = get(errors, name);
  const errorMessage = error?.message;
  const prefix = `${name}.`;
  const conditionalChecks = useCheckConditionals(params, prefix);
  const values = getValues(name);
  const validate = buildGroupValidators(initValidators, params);

  const renderParams = field => {
    return params.map(param => {
      const childName = `${prefix}${param.name}`;
      const { watchedFields, defaultValue } = conditionalChecks[childName];

      return (
        <GroupInputField
          key={childName}
          parentName={name}
          onChange={field.onChange}
          name={childName}
          parentValues={values}
          param={param}
          defaultValue={defaultValue}
          watchedFields={watchedFields}
        />
      );
    });
  };

  return (
    <>
      <div className="mb-2 pt-1 relative">
        <div className="flex items-center gap-1 self-center">
          {label && <div className="block truncate text-xs">{label}</div>}
          {tooltip && <Tooltip label={label} text={tooltip} source={source} />}
        </div>
      </div>
      <div className="border rounded">
        <table className="">
          <thead className="text-xs border-b">
            <tr>
              <td className="p-2 pl-4 w-1/4">Name</td>
              <td className="p-2 w-1/2">Value</td>
              <td className="p-2 pr-4 w-1/4">Unit</td>
            </tr>
          </thead>
          <tbody>
            <Controller
              control={control}
              rules={{ validate }}
              name={name}
              render={({ field }) => renderParams(field)}
            />
          </tbody>
        </table>
        <ErrorMessage text={errorMessage} />
      </div>
    </>
  );
};

GroupField.propTypes = {
  param: PropTypes.object,
};

export default GroupField;
