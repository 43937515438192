import PropTypes from 'prop-types';
import { useLocation, useParams, useRouteLoaderData } from 'react-router-dom';
import Tabs from './Tabs';
import { getTabs } from '@/utils/projectUtils';

const ProjectSidebarLinks = ({ isCollapsed }) => {
  const data = useRouteLoaderData('project');
  const location = useLocation();
  const params = useParams();
  const tabs = getTabs(location, data, params);

  return <Tabs tabs={tabs} isCollapsed={isCollapsed} />;
};

ProjectSidebarLinks.propTypes = {
  isCollapsed: PropTypes.bool,
};

export default ProjectSidebarLinks;
