import split from 'just-split';

// split data by size (default by hours in a week)
export const divideSeries = (series, size = 168) => {
  return series.map(({ label, unit, data }) => {
    const chunks = split(data, size).map(data => ({ data }));

    return {
      label,
      unit,
      data,
      chunks,
    };
  });
};

export const chooseSeriesByPage = (series, index) => {
  return series.map(({ label, unit, chunks }) => {
    return {
      label,
      unit,
      data: chunks?.[index]?.data,
    };
  });
};

export const parseStackedWaterfallData = data => {
  const columns = [...new Set(data.map(({ category }) => category[0]))];
  columns.push('Total');

  const columnsByName = columns.reduce((acc, label, index) => {
    acc[label] = index;
    return acc;
  }, {});

  const columnSums = [];
  const series = {};

  // calculate total for each columns
  data.forEach(({ category, cost }) => {
    const column = category[0];
    const index = columnsByName[column];

    if (!columnSums[index]) {
      columnSums[index] = 0;
    }
    columnSums[index] += cost;
  });

  columnSums.forEach((sum, index) => {
    if (index > 0) {
      columnSums[index] = sum + columnSums[index - 1];
    }
  });

  // build empty series
  data.forEach(({ category }) => {
    const name = category[1];

    if (!series[name]) {
      const columnData = [];

      columns.forEach(label => {
        columnData.push({ label, start: 0, end: 0, cost: 0 });
      });

      series[name] = {
        data: columnData,
        label: name,
      };
    }
  });

  data.forEach(({ category, cost }) => {
    const [columnName, seriesName] = category;
    const index = columnsByName[columnName];
    series[seriesName].data[index].cost = cost;
    series[seriesName].data[index].end = cost;
  });

  const datasets = Object.values(series);

  datasets.forEach(({ data }, index) => {
    data.forEach((_, colIndex) => {
      const prevEnd = datasets?.[index - 1]?.data[colIndex].end ?? 0;
      const prefix = colIndex > 0 && index === 0 ? columnSums[colIndex - 1] : 0;
      data[colIndex].start = prefix + prevEnd;
      data[colIndex].end = data[colIndex].start + data[colIndex].cost;
    });
  });

  const columnData = [];
  const sum = columnSums[columnSums.length - 1];

  columns.forEach((label, index) => {
    columnData.push({ label, start: 0, end: 0 });

    if (columns.length - 1 === index) {
      columnData[index].start = 0;
      columnData[index].end = sum;
    }
  });

  datasets.push({
    label: 'Total',
    data: columnData,
  });

  return {
    labels: columns,
    datasets,
  };
};
