import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const Select = ({ name, options, onChange, className = '', bordered = true, registerOptions = {} }) => {
  const { register } = useFormContext();
  const sizeClass = className.match('select-') ? '' : 'select-xs';
  const selectProps = onChange ? { onChange } : {};
  const inputType = bordered ? 'select-bordered' : '';

  return (
    <select
      id={name}
      name={name}
      {...register(name, registerOptions)}
      {...selectProps}
      className={`select ${sizeClass} ${inputType} ${className}`}
    >
      {options?.map((option, index) => (
        <option key={`${index}-${option.value ?? option}`} value={option.value ?? option}>
          {option.label ?? option}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any),
  className: PropTypes.string,
  onChange: PropTypes.func,
  bordered: PropTypes.bool,
  registerOptions: PropTypes.object,
};

export default Select;
