import PropTypes from 'prop-types';
import AnalysisContainer from '../core/AnalysisContainer';
import { PARAM_TYPES_WITH_DEPENDENCIES } from '@/consts';

const ParameterGridView = ({ categories, renderParamsBySubCategory, conditionalChecks }) => {
  const anyDependencyParams = Object.values(categories).some(category =>
    category.params.some(({ type }) => PARAM_TYPES_WITH_DEPENDENCIES.includes(type)),
  );

  const containerClasses = anyDependencyParams ? 'grid grid-cols-6' : 'overflow-y-auto';
  const leftSideClasses = anyDependencyParams ? 'col-span-2 grid-cols-1 overflow-y-auto' : 'grid-cols-2';

  const renderLeftSideParamsByCategory = categoryName => {
    const paramsByCategory = categories[categoryName]?.params?.filter(
      ({ type }) => !PARAM_TYPES_WITH_DEPENDENCIES.includes(type),
    );

    if (!paramsByCategory?.length) {
      return null;
    }

    const isGenerator = paramsByCategory.some(({ name }) => name === 'generators');
    const title = `${categoryName} ${isGenerator ? 'Generator Parameters' : ''}`;

    return (
      <AnalysisContainer key={categoryName} title={title}>
        <div className="p-4 rounded-xl shadow-sm bg-white">{renderParamsBySubCategory(paramsByCategory)}</div>
      </AnalysisContainer>
    );
  };

  const renderRightSideParamsByCategory = categoryName => {
    const paramsByCategory = categories[categoryName]?.params?.filter(({ type }) =>
      PARAM_TYPES_WITH_DEPENDENCIES.includes(type),
    );

    if (!paramsByCategory?.length) {
      return null;
    }

    return renderParamsBySubCategory(paramsByCategory);
  };

  return (
    <div className={`h-full ${containerClasses}`}>
      <div className={`grid gap-4 ${leftSideClasses}`}>
        {Object.values(categories).map(({ categoryName, params }) => {
          const showCategory =
            categoryName && params.some(({ name: paramName }) => conditionalChecks[paramName].fieldCondMet);
          return showCategory && renderLeftSideParamsByCategory(categoryName);
        })}
        {renderLeftSideParamsByCategory('')}
      </div>
      {anyDependencyParams ? (
        <div className="col-span-4">
          {Object.values(categories).map(({ categoryName }) => renderRightSideParamsByCategory(categoryName))}
        </div>
      ) : null}
    </div>
  );
};

ParameterGridView.propTypes = {
  categories: PropTypes.object,
  renderParamsBySubCategory: PropTypes.func,
  conditionalChecks: PropTypes.object,
};

export default ParameterGridView;
