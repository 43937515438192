import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import sesameLogo from '@/assets/sesame.svg';
import { authActions } from '@/stores/authStore';
import MaintenanceBanner from '@/components/core/MaintenanceBanner';

const LoginRoute = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const onSubmit = async data => {
    setError('');
    const result = await authActions.login(data);

    if (result?.error) {
      return setError(result?.error);
    }

    navigate('/');
  };

  return (
    <div className="min-h-screen bg-base-200 flex flex-col items-center">
      <img src={sesameLogo} alt="Logo" className="w-48 h-32 mt-4 mb-8" />
      <div className="card w-full max-w-xl shadow">
        <div className="grid grid-cols-1 bg-base-100 rounded">
          <div className="py-20 px-10">
            <h2 className="text-2xl font-semibold mb-2 text-center">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <div className="mb-4">
                  <div className="form-control w-full mt-4">
                    <label className="label">
                      <span className="label-text text-base-content">Email</span>
                    </label>
                    <input {...register('email', { required: true })} className="input input-bordered w-full" />
                  </div>

                  <div className="form-control w-full mt-4">
                    <label className="label">
                      <span className="label-text text-base-content">Password</span>
                    </label>
                    <input
                      type="password"
                      {...register('password', { required: true })}
                      className="input input-bordered w-full"
                    />
                  </div>
                </div>
              </div>
              {error && <p className="text-center text-error">{error}</p>}
              <button type="submit" className="btn mt-2 w-full btn-primary">
                Login
              </button>
            </form>
          </div>
          <MaintenanceBanner />
        </div>
      </div>
    </div>
  );
};

export default LoginRoute;
