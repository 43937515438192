import PropTypes from 'prop-types';

import { Bar } from 'react-chartjs-2';
import { useRef } from 'react';
import { CHART_COLORS } from '@/consts';

import './plugins';

const buildOptions = yAxisLabel => ({
  plugins: {
    legend: {
      display: true,
    },
    title: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: context => {
          const [start, end] = context.raw;
          return `${context.dataset.label} ${(end - start).toFixed(3)}`;
        },
        title: context => {
          return context[0].label.replaceAll(',', ' ');
        },
      },
    },
    yAxisLabel: {
      enabled: true,
      text: yAxisLabel,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 30,
    },
  },
  scales: {
    x: {
      stacked: true,

      ticks: {
        autoSkip: false,
      },

      border: {
        color: '#e7e5e4',
      },

      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        display: true,
        lineWidth: 2,
      },
    },

    y: {
      title: {
        display: false,
      },

      beginAtZero: true,

      border: {
        dash: [4, 4],
        display: false,
      },
    },
  },
});

const buildData = (datasets, labels) => {
  return {
    labels,
    datasets: datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data.map(item => (item.start === item.end ? null : [item.start, item.end])),
      backgroundColor: CHART_COLORS[index],
      borderRadius: 0,
      borderSkipped: false,
      barThickness: 41,
    })),
  };
};

const WaterfallStackedChart = ({ label, datasets, labels }) => {
  const options = buildOptions(label);
  const barData = buildData(datasets, labels);
  const ref = useRef();

  return (
    <div className="min-h-80 relative mr-2">
      <Bar ref={ref} options={options} data={barData} />
    </div>
  );
};

WaterfallStackedChart.propTypes = {
  datasets: PropTypes.array,
  labels: PropTypes.array,
  label: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default WaterfallStackedChart;
