import { Feedback, getClient } from '@sentry/react';

const FeedbackButton = () => {
  const client = getClient();
  const feedback = client?.getIntegration(Feedback);

  if (!feedback) {
    return null;
  }

  return (
    <button type="button" className="btn btn-xs btn-accent" onClick={() => feedback.openDialog()}>
      Report Bug
    </button>
  );
};

export default FeedbackButton;
