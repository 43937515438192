import { Position, MarkerType } from '@xyflow/react';
import ELK from 'elkjs/lib/elk.bundled.js';
import { keyBy } from './miscUtils';
import { unitToString } from './unitUtils';
import { CO2_EQUIVALENT, CO2_EQUIVALENT_EMISSION_TYPE, NODE_TYPES } from '@/consts';
import sortBy from 'just-sort-by';
import { resourcesActions } from '@/stores/resourcesStore';

// elk layouting options can be found here:
// https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
const layoutOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'RIGHT',
  'elk.layered.spacing.edgeNodeBetweenLayers': '150',
  'elk.spacing.nodeNode': '200',
  'elk.layered.nodePlacement.strategy': 'SIMPLE',
};
const elk = new ELK();

export const parseNode = node => {
  const { id, label, name, inputs, outputs, position, type = 'pathwayNode', data } = node;

  return {
    id,
    data: {
      id,
      label,
      name,
      inputs: sortBy(inputs, 'name'),
      outputs: sortBy(outputs, 'name'),
      ...data,
    },
    position,
    type,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  };
};

export const serializeHandle = (handle, type) => {
  return handle.split(`_${type}_`);
};

export const applyNodeInfo = (nodes, nodeInfo, params) => {
  const succeededSystems = resourcesActions.getSucceededSystems();
  const systemsById = keyBy(succeededSystems, 'id');

  return nodes.map(node => {
    let label = nodeInfo[node.id]?.name || node.label;
    let type = NODE_TYPES.pathway;
    const systemId = params?.[node.id]?.system_id;
    const data = {};

    if (systemId) {
      const system = systemsById?.[systemId];
      label = system?.name || label;
      type = NODE_TYPES.system;
      data.system = system;
    }

    return { ...node, label, type, data };
  });
};

/**
 * Serializes an edge into a format suitable for the server.
 * @param {Object} edge - The edge object to be serialized.
 * @returns {Object} - The serialized edge object in server format.
 */
export const serializeEdge = edge => {
  const { sourceHandle, targetHandle } = edge;
  const [output_node, output_name] = serializeHandle(sourceHandle, 'output');
  const [input_node, input_name] = serializeHandle(targetHandle, 'input');

  return {
    input_name,
    input_node,
    output_name,
    output_node,
  };
};

/**
 * Converts pathway nodes returned from the server into a format compatible with React Flow.
 * @param {Object[]} nodes - An array of pathway nodes to be parsed into React Flow format.
 * @returns {Object[]} - An array of nodes parsed into React Flow format.
 */
export const parseNodes = nodes => {
  return nodes.map(node => parseNode(node));
};

/**
 * Converts an connection received from the server into a format compatible with React Flow.
 * @param {Object} edge - The edge/connection object received from the server.
 * @returns {Object} - The parsed edge object in React Flow format.
 */
export const parseEdge = edge => {
  const { sourceHandle, targetHandle, source, target, label, name, anchorable, unit } = edge;

  return {
    id: `${sourceHandle}-${targetHandle}`,
    label,
    source,
    target,
    type: 'pathwayEdge',
    markerEnd: {
      type: MarkerType.Arrow,
      width: 20,
      height: 20,
      color: '#000000',
    },
    style: {
      strokeWidth: 2,
      stroke: '#000000',
    },
    sourceHandle,
    targetHandle,
    selected: true,
    name,
    data: {
      name,
      anchorable,
      unit,
    },
  };
};

/**
 * Converts an array of node objects into a map where each key represents a node ID,
 * and the corresponding value is a node object augmented with inputs and outputs indexed by name.
 * @param {Object[]} nodes - An array of node objects to be transformed into a map.
 * @returns {Object<string, Object>} - A map where keys are node IDs and values are node objects
 * with inputs and outputs indexed by name.
 */
export const getNodesById = nodes => {
  return keyBy(nodes, 'id', node => ({
    ...node,
    inputsByName: keyBy(node.inputs, 'name'),
    outputsByName: keyBy(node.outputs, 'name'),
  }));
};

/**
 * Converts pathway connections returned from the server into React Flow edges.
 * @param {Object[]} connections - An array of pathway connections to be parsed into edges.
 * @param {Object<string, Object>} nodesById - A map where keys are node IDs and values are node objects
 * @returns {Object[]} - An array of React Flow edges parsed from the pathway connections.
 */
export const parseEdges = (connections, nodesById) => {
  return connections.map(edge => {
    const { input_node: target, input_name: inputName, output_node: source, output_name: outputName } = edge;
    const output = nodesById[source].outputsByName[outputName];
    const sourceHandle = `${source}_output_${outputName}`;
    const targetHandle = `${target}_input_${inputName}`;
    const outputType = output.type;
    const label = outputType.label;
    const unit = outputType.unit;
    const name = outputName;
    const anchorable = output.anchorable;

    return parseEdge({ sourceHandle, targetHandle, source, target, label, name, anchorable, unit });
  });
};

// position each node
export const getLayoutedNodes = async (nodes, cachedNodes, edges) => {
  const graph = {
    id: 'root',
    layoutOptions,

    children: nodes.map(n => {
      n.data.inputs = sortBy(n.data.inputs, 'name');
      n.data.outputs = sortBy(n.data.outputs, 'name');

      const targetPorts = n.data.inputs.map(o => ({
        id: `${n.id}_input_${o.name}`,
      }));

      const sourcePorts = n.data.outputs.map(o => ({
        id: `${n.id}_output_${o.name}`,
      }));

      return {
        id: n.id,
        width: n.width ?? 400,
        height: n.height ?? 400,
        properties: {
          'org.eclipse.elk.portConstraints': 'FIXED_SIDE',
        },
        ports: [{ id: n.id }, ...targetPorts, ...sourcePorts],
      };
    }),
    edges: edges.map(e => ({
      id: e.id,
      sources: [e.sourceHandle || e.source],
      targets: [e.targetHandle || e.target],
    })),
  };

  const layoutedGraph = await elk.layout(graph);
  const layoutedNodes = nodes.map(node => {
    const layoutedNode = layoutedGraph.children?.find(lgNode => lgNode.id === node.id);

    return {
      ...node,
      position: {
        x: cachedNodes?.[node.id]?.x ?? layoutedNode?.x ?? 0,
        y: cachedNodes?.[node.id]?.y ?? layoutedNode?.y ?? 0,
      },
    };
  });

  return layoutedNodes;
};

export const getNodeOptions = nodes => nodes.map(({ id, label }) => ({ label, value: id }));

export const getIOOptions = node => {
  let result = node?.outputs
    .filter(output => output.allocatable)
    .map(output => ({
      value: JSON.stringify({ io_name: output.name, io_type: 'output' }),
      label: output.type.label,
    }));

  if (!result?.length) {
    result = node?.inputs
      .filter(input => input.allocatable)
      .map(input => ({
        value: JSON.stringify({ io_name: input.name, io_type: 'input' }),
        label: input.type.label,
      }));
  }

  return result;
};

export const getOutputOptions = node => {
  return node?.outputs
    .filter(output => output.allocatable)
    .map(output => ({
      value: output.name,
      label: output.type.label,
    }));
};

export const getEmissionTypes = nodes => {
  const emissionTypes = nodes.flatMap(getEmissionTypesPerNode);
  const emissionTypesByLabel = keyBy(emissionTypes, 'label');
  const result = Object.values(emissionTypesByLabel);

  result.unshift(CO2_EQUIVALENT_EMISSION_TYPE);

  return result;
};

export const getEmissionTypesPerNode = node => {
  const emissionTypes = node.emission_types.filter(({ name }) => name !== CO2_EQUIVALENT);
  const result = [];

  emissionTypes.forEach(({ name, label, unit }) => {
    result.push({ value: JSON.stringify({ co2_equivalent: false, emission_type: name, unit, label }), label });
    result.push({
      value: JSON.stringify({ co2_equivalent: true, emission_type: name, unit, label }),
      label: `${label} (in CO2e)`,
    });
  });

  return result;
};

export const getUnitsByIO = node => {
  let result = (node?.outputs ?? [])
    .filter(output => output.allocatable)
    .map(output => ({
      io: JSON.stringify({ io_name: output.name, io_type: 'output' }),
      units: [output.type.unit, ...output.type.convertible_units],
    }));

  if (!result?.length) {
    result = (node?.inputs ?? [])
      .filter(input => input.allocatable)
      .map(input => ({
        io: JSON.stringify({ io_name: input.name, io_type: 'input' }),
        units: [input.type.unit, ...input.type.convertible_units],
      }));
  }

  return keyBy(result, 'io');
};

export const getUnitOptions = units => {
  const uniqueUnits = new Set();

  return units
    ?.map(unit => ({
      label: unitToString(unit),
      value: JSON.stringify(unit),
    }))
    .filter(option => {
      if (uniqueUnits.has(option.value)) {
        return false;
      } else {
        uniqueUnits.add(option.value);
        return true;
      }
    });
};
