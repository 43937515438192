import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

const settings = [
  { label: 'Change text size', url: '/settings/theme' },
  { label: 'Change password', url: '/settings/password' },
];

const PathwayList = () => {
  let { state } = useLocation();

  return (
    <div className="flex flex-col gap-6">
      <ul className="mt-1 px-1">
        <li className="flex items-center justify-between rounded-md py-2 px-2 pl-8 font-bold text-sm leading-6 text-gray-700">
          General
        </li>
        {settings.map(setting => (
          <li key={setting.url} className="pl-2">
            <NavLink
              state={state}
              className={({ isActive }) =>
                `flex items-center justify-between rounded-md py-2 px-2 pl-6 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-50'}`
              }
              to={setting.url}
            >
              <span className="truncate">{setting.label}</span>
            </NavLink>
          </li>
        ))}
      </ul>

      {state?.pathname && (
        <NavLink
          className={`flex items-center justify-between rounded-md py-2 px-2 pl-9 text-sm leading-6 text-gray-700 font-semibold underline `}
          to={state.pathname}
        >
          <span className="truncate">Return to previous page</span>
        </NavLink>
      )}
    </div>
  );
};

PathwayList.propTypes = {
  project: PropTypes.object,
};

export default PathwayList;
