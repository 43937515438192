import PropTypes from 'prop-types';

const DataGrid = ({ mainColumns, columns, rows, className }) => (
  <div className="prose min-w-full prose-sm prose-thead:text-sm prose-thead:font-semibold prose-thead:border-b prose-thead:border-gray-600 prose-td:px-2 prose-tr:border-transparent">
    <table className={`${className}`}>
      <thead>
        {mainColumns && (
          <tr>
            {Object.keys(mainColumns).map(name => (
              <th scope="col" colSpan={mainColumns[name]} key={`column-${name}`}>
                {name}
              </th>
            ))}
          </tr>
        )}
        <tr>
          {columns.map((column, i) => (
            <th scope="col" key={`column-${column}-${i}`} className="text-xs font-semibold">
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, i) => (
          <tr key={`row-${i}`} className="border-dashed !border-black/30 border-t border-b-0">
            {row.map((column, j) => (
              <td scope="col" key={`column-${j}`} className="whitespace-nowrap first:text-left text-center">
                {column}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

DataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array),
  columns: PropTypes.arrayOf(PropTypes.string),
  mainColumns: PropTypes.object,
  className: PropTypes.string,
};

export default DataGrid;
