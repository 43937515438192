import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import useSetDefaultValue from '../useSetDefaultValue';

const ToggleField = ({ param, name, defaultValue, watchedFields }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { label, validators: validate, tooltip, source } = param;
  const error = get(errors, name);
  const errorMessage = error?.message;

  useSetDefaultValue(name, defaultValue, watchedFields);

  return (
    <FormRow label={label} name={name} tooltip={tooltip} source={source}>
      <Controller
        control={control}
        rules={{ validate }}
        name={name}
        render={({ field: { value, onChange } }) => (
          <>
            <input
              type="checkbox"
              id={name}
              name={name}
              className={`toggle mt-1 toggle-success ${errorMessage ? 'input-error' : ''}`}
              onChange={e => onChange(e.target.checked ? true : false)}
              checked={value === true}
            />
            <ErrorMessage text={errorMessage} />
          </>
        )}
      />
    </FormRow>
  );
};

ToggleField.propTypes = {
  param: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.bool]),
  watchedFields: PropTypes.object,
};

export default ToggleField;
