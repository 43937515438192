import ChangePasswordForm from './ChangePasswordForm';
import SettingsRoute from './SettingsRoute';
import ThemeRoute from './ThemeRoute';
import userApi from '@/api/user';

const userRouter = {
  path: '/settings',
  element: <SettingsRoute />,
  loader: async () => {
    const { data } = await userApi.getUserInfo();

    return data;
  },
  children: [
    {
      path: 'theme',
      element: <ThemeRoute />,
    },
    {
      path: 'password',
      element: <ChangePasswordForm />,
    },
  ],
};

export default userRouter;
