import { useLCAPlot, useNodeById } from '@/stores/analysisStore';
import { getLCAYaxisLabel } from '@/utils/analysisUtils';
import CostBreakdownChart from '@/components/charts/CostBreakdownChart';
import Decimal from 'decimal.js';

const LCABreakdownPlot = () => {
  const plot = useLCAPlot();
  const { nodeId, scope3Breakdown, results, levelize } = plot;
  const node = useNodeById(nodeId);
  const digits = levelize === 'true' ? 2 : 0;

  if (!scope3Breakdown) {
    return null;
  }

  const breakdownResults = results
    .map(item => {
      return {
        label: item.name.join(' '),
        amount: new Decimal(item.value),
      };
    })
    .filter(item => !item.label.match(/Scope|Total/));

  return (
    <CostBreakdownChart
      breakdownResults={breakdownResults}
      title="Emissions Breakdown"
      yAxisLabel={`Scope 3 Breakdown - ${node.label}\n[${getLCAYaxisLabel(plot)}]`}
      digits={digits}
      unit={getLCAYaxisLabel(plot)}
    />
  );
};

export default LCABreakdownPlot;
