import { Navigate, Outlet } from 'react-router-dom';
import { useEmail } from '@/stores/userStore';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import Layout from '@/components/core/Layout';
import { authActions } from '@/stores/authStore';

const PrivateRoute = () => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const email = useEmail();

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await authActions.isAuthenticated();
      setIsAuthorized(authStatus);
    };

    checkAuth();
  }, [setIsAuthorized]);

  useEffect(() => {
    if (email) {
      Sentry.setUser({ email });
    }
  }, [email]);

  if (isAuthorized === null) {
    return null;
  }

  if (isAuthorized === false) {
    return <Navigate replace to="/login" />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoute;
