import axios from 'axios';

const { post, get, put } = axios;

export default {
  login: data => post('/v1/login/', data),
  logout: () => post('/v1/logout/'),
  updatePassword: data => put('/v1/user/password/', data),
  getUserInfo: () => get('/v1/user/'),
  getSettings: () => get('/'),
};
