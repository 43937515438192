import StackedBarChart from '@/components/charts/StackedBarChart';
import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import { useCapacities } from '@/stores/systemAnalysisStore';
import { unitToString } from '@/utils/unitUtils';

const CapacitiesResults = () => {
  const { rows, columns, unit } = useCapacities();

  if (!columns.length) return null;

  const yAxisLabel = unitToString(unit);

  return (
    <AnalysisContainer title="Capacities">
      <AnalysisBox>
        <StackedBarChart
          rows={rows}
          columns={columns.map(c => c.split(' '))}
          yAxisLabel={`\n${yAxisLabel}`}
          colors={['#D0E2FF', '#639EFF']}
        />
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default CapacitiesResults;
