import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import SearchIcons from '@/assets/search.svg?react';

const SearchInput = ({ name, className = '' }) => {
  const { register } = useFormContext();
  const sizeClass = className.match('select-') ? '' : 'input-xs';

  return (
    <label className={`${sizeClass} flex items-center relative ${className}`}>
      <input
        {...register(name)}
        type="text"
        className="input input-bordered input-sm grow pr-10"
        placeholder="Search"
      />
      <SearchIcons className="absolute right-0 top-0 h-full w-10 p-1 pointer-events-none" />
    </label>
  );
};

SearchInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SearchInput;
