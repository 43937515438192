import {
  registryActions,
  useActiveDatasets,
  useTagCategories,
  useSavedDatasetList,
  useSubmittedFilterTags,
} from '@/stores/dataRegistryStore';
import DataRegistryForm from './DataRegistryForm';
import dataSources from '@/dataSources';
import { NavLink, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import DataRegistrySearchFilter from './DataRegistrySearchFilter';

const defaultValues = {};

const datasetList = dataSources.map(dataSource => dataSource.id);

const formatTag = tag => {
  let formattedTag = '';
  if (tag.includes('_')) {
    const parts = tag.split('_');
    formattedTag = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  } else if (tag.includes('/')) {
    const parts = tag.split('/');
    formattedTag = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join('/');
  } else {
    formattedTag = tag.charAt(0).toUpperCase() + tag.slice(1);
  }
  return formattedTag;
};

const DataRegistry = () => {
  let activeDatasets = useActiveDatasets(); //this will return my filtered data

  let savedDatasets = useSavedDatasetList();

  let submittedFilterTags = useSubmittedFilterTags();

  const { projectId } = useParams();

  activeDatasets.filter(item => datasetList.includes(item.datatable_name));

  let tagsByCategory = useTagCategories();

  const handleSaveButton = (datasetToBeSaved, savedDatasets) => {
    if (!savedDatasets.includes(datasetToBeSaved)) {
      const updatedSavedDatasets = savedDatasets.concat(datasetToBeSaved);
      registryActions.updateSavedDatasets(updatedSavedDatasets);
    } else if (savedDatasets.includes(datasetToBeSaved)) {
      const savedDatasetsFiltered = savedDatasets.filter(dataset => dataset !== datasetToBeSaved);
      registryActions.updateSavedDatasets(savedDatasetsFiltered);
    }
  };

  return (
    <div className="h-[87vh] flex flex-col mb-5 w-full pt-10 ">
      <div className="flex flex-row">
        <DataRegistryForm
          defaultValues={defaultValues}
          onRender={registryActions.clear}
          onSubmit={registryActions.updateSearch}
        />{' '}
        <DataRegistrySearchFilter
          defaultValues={defaultValues}
          onSubmit={registryActions.updateFilter}
          tagsByCategory={tagsByCategory}
        />
      </div>

      {submittedFilterTags && submittedFilterTags.length > 0 && (
        <div>
          <ul className="mt-1 px-1 inline-flex flex flex-wrap gap-1">
            {submittedFilterTags.map(tag => (
              <li key={tag} className="group pl-2 rounded-lg mb-5">
                <label className="px-1 py-1 bg-green-500 text-white rounded text-sm mr-1 whitespace-nowrap">
                  {tag
                    .split('_')
                    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
                    .join(' ')}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="text-gray-500 ml-4 mt-3 text-lg">{activeDatasets.length} datasets</div>
      <div className="border-t border-gray-500 mt-3"></div>
      <div className="mt-5 overflow-x-auto scrollbar-thin mt-1">
        <ul className="mt-1 px-1">
          {activeDatasets.map(dataset => (
            <li key={dataset.datatable_name} className="group pl-2 rounded-lg mb-5">
              <div className="ml-5 w-full flex items-center">
                <button onClick={() => handleSaveButton(dataset, savedDatasets)}>
                  {!savedDatasets.includes(dataset) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                      />
                    </svg>
                  )}

                  {savedDatasets.includes(dataset) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
                      />
                    </svg>
                  )}
                </button>

                <NavLink
                  className={({ isActive }) =>
                    `w-full flex items-center justify-between rounded-md py-2 pr-2 pl-3 ml-3 text-sm leading-6 text-gray-700 ${isActive ? 'bg-gray-50' : 'hover:bg-gray-50 '}`
                  }
                  to={`/projects/${projectId}/datasources/${dataset.datatable_name}`}
                >
                  <span className="truncate text-xl"> {dataset.table_label}</span>

                  <div className="mt-2 mb-2 mt-10">
                    {' '}
                    {dataset.tags &&
                      dataset.tags.sort().map(tag => (
                        <span className="px-1 py-1 bg-green-500 text-white rounded text-sm mr-5" key={tag}>
                          {formatTag(tag)}
                        </span>
                      ))}
                  </div>
                </NavLink>
              </div>

              <div className="w-full border-t border-gray-300 mt-4"></div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

DataRegistry.propTypes = {
  metadata: PropTypes.object,
  tags: PropTypes.object,
  pointers: PropTypes.object,
};

export default DataRegistry;
