import { useState } from 'react';

const useToggle = (initiallyOpen, onToggle) => {
  const [isOpen, open] = useState(initiallyOpen);

  const toggle = () => {
    open(!isOpen);
    onToggle && onToggle();
  };

  return { isOpen, toggle, open };
};

export default useToggle;
