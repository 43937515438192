import {
  comparisonActions,
  useComparisonCases,
  useComparisonError,
  usePathwaysOptions,
} from '@/stores/comparisonStore';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import ComparisonCaseForm from './ComparisonCaseForm';
import Walkthrough from '../core/Walkthrough';

const ComparisonCasesList = () => {
  const comparisonCases = useComparisonCases();
  const pathwaysOptions = usePathwaysOptions();
  const error = useComparisonError();

  return (
    <div className="bg-white inline-block min-w-full pt-4 border-t-gray-200 border-b-gray-200 border-t border-b">
      <table className="text-left min-w-full divide-y divide-gray-200">
        {comparisonCases.length > 0 && (
          <thead className="text-sm">
            <tr>
              <th className="p-1 pl-12">Name</th>
              <th className="p-1">Pathway</th>
              <th className="p-1">Module</th>
              <th className="p-1">Stream</th>
              <th className="p-1 pr-8"></th>
            </tr>
          </thead>
        )}
        <tbody className="divide-y divide-gray-200 comparison-case-row">
          {comparisonCases.map(comparisonCase => (
            <ComparisonCaseForm
              key={comparisonCase.key}
              pathwaysOptions={pathwaysOptions}
              defaultValues={comparisonCase}
              onSubmit={comparisonActions.updateComparisonCase}
            />
          ))}
        </tbody>
        <tfoot>
          {error && (
            <tr>
              <td colSpan={5} className="error p-2 text-error text-sm text-center">
                {error}
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={5} className="p-2">
              <div className="flex justify-center">
                <button
                  onClick={comparisonActions.addComparisonCase}
                  className="btn btn-sm btn-ghost add-comparison-case"
                >
                  <PlusCircleIcon className="h-5 w-5" /> Add Stream
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <Walkthrough type="comparison" />
    </div>
  );
};

export default ComparisonCasesList;
