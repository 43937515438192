import { cssTransition, toast as reactToast } from 'react-toastify';

const NoAnimation = cssTransition({
  enter: 'none',
  exit: 'hidden',
  collapse: true,
});

export const toastOptions = {
  autoClose: false,
  transition: NoAnimation,
};

export const toast = {
  success: (message, options) => {
    reactToast.clearWaitingQueue();
    reactToast.dismiss();
    reactToast.success(message, { ...toastOptions, ...options });
  },
  error: (message, options) => {
    reactToast.clearWaitingQueue();
    reactToast.dismiss();
    reactToast.error(message, { ...toastOptions, ...options });
  },
};
