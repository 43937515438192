import { useCashFlowsPlot } from '@/stores/analysisStore';
import { analysisActions } from '@/stores/analysisStore';
import CashFlowsForm from './CashFlowsForm';
import CashFlowDataGrid from '@/components/datagrids/CashFlowDataGrid';

const CashFlowDataGridContainer = () => {
  const plot = useCashFlowsPlot();
  const { results } = plot;
  const updatePlot = formData => analysisActions.updatePlot('cash_flows', formData);

  return (
    <>
      <div>
        <CashFlowsForm onSubmit={updatePlot} defaultValues={plot} />
      </div>
      <div>{results && <CashFlowDataGrid data={results.data} />}</div>
    </>
  );
};

export default CashFlowDataGridContainer;
