import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
//import Select from '@/components/core/form/Select';
import SearchInput from '../core/form/SearchInput';
import debounce from 'debounce';
import { useEffect } from 'react';

//import React from 'zustand/react';

const DataRegistryForm = ({ defaultValues, onRender, onSubmit }) => {
  onRender;

  const methods = useForm({ defaultValues, mode: 'onChange' });

  const { handleSubmit } = methods;

  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);

  useEffect(() => {
    onRender();
  }, [onRender]);

  return (
    <FormProvider {...methods}>
      <form className="" onChange={debouncedSave}>
        {' '}
        <div className="flex mb-4 items-center">
          <SearchInput className="inline-flex" name="term" />
        </div>
      </form>
    </FormProvider>
  );
};

DataRegistryForm.propTypes = {
  //params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onRender: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DataRegistryForm;
