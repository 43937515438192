import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import LineChart from '@/components/charts/LineChart';
import { useTEAPlot, workbenchActions } from '@/stores/workbenchStore';
import WorkbenchPlotForm from '../WorkbenchPlotForm';
import { getTEAYaxisLabel } from '@/utils/analysisUtils';

const TEAWorkbenchPlot = () => {
  const plot = useTEAPlot();
  const { node, paramLabels, results, workbenchUnits } = plot;
  const updatePlot = formData => {
    workbenchActions.updatePlot('tea', formData);
  };

  if (!results) {
    return;
  }

  const legendTitle = paramLabels[paramLabels.length - 1];
  const legendUnit = workbenchUnits[workbenchUnits.length - 1];

  return (
    <AnalysisContainer title="Techno-Economic Analysis">
      <AnalysisBox>
        <LineChart
          results={results}
          label={`${paramLabels[0]} - ${node.label}`}
          legendTitle={`${legendTitle} (${legendUnit})`}
          yAxisLabel={getTEAYaxisLabel(plot, node)}
        />
        {node && <WorkbenchPlotForm onSubmit={updatePlot} defaultValues={plot} />}
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default TEAWorkbenchPlot;
