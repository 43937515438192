import { getServerUrl, getStackEnv } from './utils/stackUtils';

const config = {
  datasourceUrl: import.meta.env.VITE_DATASOURCE_URL,
  serverUrl: getServerUrl(),
  mapBoxAccessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
  agLicenseKey: getStackEnv('AG_LICENSE_KEY'),
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  featureFlags: {
    enableDatasource: getStackEnv('ENABLE_DATASOURCE'),
  },
};

export default config;
