import PropTypes from 'prop-types';
import { unitToString } from '@/utils/unitUtils';
import { TrashIcon, PencilIcon } from '@heroicons/react/24/outline';
import { caseActions } from '@/stores/caseStore';
import { confirm } from '@/components/core/createConfirmation';

const CostOverrideSummary = ({ costOverride, onToggleForm }) => {
  const { cost, unit, id } = costOverride || {};
  const unitString = unit ? unitToString(unit) : '';

  const onRemove = async () => {
    if (await confirm('Are you sure you want to remove cost override?')) {
      caseActions.deleteCost(id);
    }
  };

  if (!costOverride) {
    return (
      <button type="button" className="btn-sm btn-link btn font-normal p-0 text-sky-600" onClick={onToggleForm}>
        Need to override cost?
      </button>
    );
  }

  return (
    <div className="text-xs flex justify-between items-center grow">
      <div>
        <div className="text-gray-400">Cost Override</div>
        {cost} {unitString}
      </div>
      <div>
        <button type="button" className="btn btn-square btn-sm btn-ghost" onClick={onRemove}>
          <TrashIcon className="w-4 h-4" />
        </button>
        <button type="button" className="btn btn-square btn-sm btn-ghost" onClick={onToggleForm}>
          <PencilIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

CostOverrideSummary.propTypes = {
  costOverride: PropTypes.object,
  onToggleForm: PropTypes.func,
};

export default CostOverrideSummary;
