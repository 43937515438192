import PropTypes from 'prop-types';
import NavLinkList from './NavLinkList';

const Tabs = ({ tabs }) => <NavLinkList links={tabs} />;

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  isCollapsed: PropTypes.bool,
};

export default Tabs;
