import PropTypes from 'prop-types';
import { keyBy } from '@/utils/miscUtils';
import { Bar } from 'react-chartjs-2';
import { customGridLine } from './plugins';

const parseOptions = (data, baseline, xAxisLabel, type) => {
  const allData = data.datasets.flatMap(dataset => dataset.data);
  const minValue = Math.min(...allData);
  const maxValue = Math.max(...allData);
  const maxAbsValue = Math.max(Math.abs(minValue), Math.abs(maxValue));
  const adjustedMin = 1.2 * -maxAbsValue;
  const adjustedMax = 1.2 * maxAbsValue;
  const stepSize = (adjustedMax - adjustedMin) / 10;
  const uniqueLabels = Object.values(keyBy(data.datasets, 'nodeId'));

  const options = {
    indexAxis: 'y',
    xAxisLabel,
    maxBarThickness: 70,
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    scales: {
      x: {
        min: adjustedMin,
        max: adjustedMax,
        stacked: false,
        ticks: {
          stepSize,
          callback: function (value, index) {
            const decimalPlace = 3;
            return index % 2 === 1 ? (value + baseline).toFixed(decimalPlace) : '';
          },
          padding: 20,
        },
        grid: {
          color: function (context) {
            if (context.tick.value === 0) {
              return 'black';
            }
            return 'rgba(0, 0, 0, 0.1)';
          },
          drawTicks: true,
          drawOnChartArea: true,
        },
        border: {
          dash: [4, 4],
          display: false,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          autoSkip: false,
        },
        grid: {
          display: false,
        },
        border: {
          dash: [4, 4],
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          generateLabels: () => {
            return uniqueLabels.map(label => {
              return {
                text: label.label,
                fillStyle: label.backgroundColor,
                hidden: false,
                lineCap: 'butt',
                lineDash: [],
                lineDashOffset: 0,
                lineJoin: 'miter',
                lineWidth: 0,
                strokeStyle: '',
                pointStyle: 'rect',
                rotation: 0,
              };
            });
          },
        },
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: context => {
            const { paramValue, baselineParamValue } = context.dataset.customData[context.dataIndex];
            const change = context.raw + baseline > baseline ? 'increases' : 'decreases';
            const subLabel = type === 'lca' ? 'emissions' : 'costs';

            return [
              context.dataset.label,
              `Changing the ${context.label} from ${baselineParamValue.toFixed(2)} to ${paramValue.toFixed(2)}`,
              `${change} ${subLabel} to ${(context.raw + baseline).toFixed(2)} ${xAxisLabel}.`,
            ];
          },
        },
      },
    },
  };

  return options;
};

const TornadoChart = ({ baseline, datasets, labels, xAxisLabel, type }) => {
  const data = {
    datasets,
    labels,
  };

  const options = parseOptions(data, baseline, xAxisLabel, type);

  return (
    <div className="min-h-96 relative mr-2 w-full">
      <Bar options={options} data={data} plugins={[customGridLine]} />
    </div>
  );
};

TornadoChart.propTypes = {
  baseline: PropTypes.number,
  datasets: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.array,
  xAxisLabel: PropTypes.string,
  type: PropTypes.string,
};

export default TornadoChart;
