import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { SYSTEM_STATUS } from '@/consts';
import SystemActionsDropdown from '@/components/systems/SystemActionsDropdown';

const SystemRow = ({ system, projectId }) => {
  const navigate = useNavigate();

  const viewAnalysis = e => {
    e.preventDefault();
    navigate(`/projects/${projectId}/analysis/systems/${system.id}`);
  };

  const continueBuild = e => {
    e.preventDefault();
    navigate(`/projects/${projectId}/build/systems/${system.id}`);
  };

  return (
    <tr className="bg-white">
      <td>
        <Link
          className="group flex justify-start rounded-md items-center py-2 px-4 text-sm leading-6 text-gray-700 hover:bg-gray-100"
          to={`/projects/${projectId}/build/systems/${system.id}`}
        >
          <span className="truncate w-2/5">{system.name}</span>
          <span className="w-2/5 flex group-hover:hidden">
            {system.status === SYSTEM_STATUS.succeeded ? (
              <div className="inline-flex rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                Analysis Complete
              </div>
            ) : (
              <div className="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                In Progress
              </div>
            )}
          </span>
          <span className="w-2/5 group-hover:flex hidden">
            {system.status === SYSTEM_STATUS.succeeded ? (
              <button onClick={viewAnalysis} className="btn btn-xs btn-secondary">
                View Analysis
              </button>
            ) : (
              <button onClick={continueBuild} className="btn btn-xs btn-secondary">
                Continue Build
              </button>
            )}
          </span>
          <div className="w-1/5 justify-end items-center group-hover:flex hidden">
            <SystemActionsDropdown system={system} />
          </div>
        </Link>
      </td>
    </tr>
  );
};

SystemRow.propTypes = {
  system: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
  }),
  projectId: PropTypes.string,
};

export default SystemRow;
