import MapChart from '@/components/charts/MapChart';
import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import { useSystemLocations } from '@/stores/systemStore';

const LocationResult = () => {
  const locations = useSystemLocations();

  if (!locations?.length) return null;

  return (
    <AnalysisContainer title="Locations">
      <AnalysisBox>
        <MapChart locations={locations} />
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default LocationResult;
