import PropTypes from 'prop-types';
import { PlusIcon } from '@heroicons/react/24/outline';

const ListHeader = ({ icon: Icon, title, onAdd }) => {
  return (
    <button
      onClick={onAdd}
      className="flex w-full items-center gap-x-3 p-2 text-left text-sm font-medium leading-6 text-gray-600 hover:text-gray-900 hover:bg-gray-100"
    >
      <Icon className="h-4 w-4 shrink-0" />
      <span className="truncate">{title}</span>
      {onAdd && <PlusIcon className="ml-auto rounded h-6 w-6 bg-gray-100 p-1 shrink-0 text-gray-600 hover:bg-white" />}
    </button>
  );
};

ListHeader.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  title: PropTypes.string,
  onAdd: PropTypes.func,
};

export default ListHeader;
