import axios from 'axios';

const { get, post, put } = axios;

export const getProject = projectId => get(`/v1/projects/${projectId}/`);
export const getProjects = () => get('/v1/projects/');
export const createProject = data => post('/v1/projects/', data);
export const updateProject = (projectId, data) => put(`/v1/projects/${projectId}/`, data);
export const deleteProject = projectId => axios.delete(`/v1/projects/${projectId}/`);
export const addMember = (projectId, data) => put(`/v1/projects/${projectId}/members/`, data);
export const removeMember = (projectId, data) => axios.delete(`/v1/projects/${projectId}/members/`, { data });
