import PropTypes from 'prop-types';

const SummaryItem = ({ name, value, unit, label, color }) => {
  return (
    <div className="flex gap-7">
      <div
        className="border-r-[12px]"
        style={{
          borderColor: color,
        }}
      ></div>
      <div className="flex flex-col gap-1">
        <div className="font-bold text-sm">{name}</div>
        <div className="flex gap-2">
          <span className="font-bold text-4xl">{value.toFixed(2)}</span>
          <span className="text-sm text-gray-400 self-end mb-1">{unit}</span>
        </div>
        <div className="text-sm">{label}</div>
      </div>
    </div>
  );
};

SummaryItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.string,
  color: PropTypes.string,
};

export default SummaryItem;
