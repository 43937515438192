import { comparisonActions, useIsLCAVisible, useIsTEAVisible } from '@/stores/comparisonStore';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

const ComparisonOptionsDropdown = () => {
  const isTEAVisible = useIsTEAVisible();
  const isLCAVisible = useIsLCAVisible();
  const teaLabel = isTEAVisible ? 'Hide' : 'Show';
  const lcaLabel = isLCAVisible ? 'Hide' : 'Show';

  const closeDropdown = () => {
    document?.activeElement?.blur();
  };

  const hideLCA = () => {
    comparisonActions.togglePlot('lca');
    closeDropdown();
  };

  const hideTEA = () => {
    comparisonActions.togglePlot('tea');
    closeDropdown();
  };

  return (
    <div className="dropdown dropdown-bottom dropdown-left absolute top-5 right-2">
      <div tabIndex={0} role="button" className="btn m-1 btn-circle btn-sm btn-ghost">
        <EllipsisVerticalIcon className="w-5 h-5" />
      </div>
      <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
        <li>
          <button onClick={hideLCA}>{lcaLabel} LCA</button>
        </li>
        <li>
          <button onClick={hideTEA}>{teaLabel} TEA</button>
        </li>
      </ul>
    </div>
  );
};

export default ComparisonOptionsDropdown;
