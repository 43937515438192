import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import SvgViewer from './SvgViewer';
import sesameLogo from '@/assets/sesame.svg';
import AccountActionsDropdown from './AccountActionsDropdown';
import Breadcrumbs from '@/components/core/Breadcrumbs';

const Header = ({ children }) => {
  return (
    <header className="bg-slate-50 pt-2 pb-2 pl-4 pr-4 border-gray-200">
      <nav className="flex items-center justify-between">
        <div className="flex items-center space-x-4 ml-5">
          <Link to="/" className="flex items-center space-x-4">
            <div className="w-8 h-8">
              <SvgViewer x={0} y={0} width={202} height={202} src={sesameLogo} />
            </div>
          </Link>
          <Breadcrumbs />
        </div>
        <div className="flex lg:gap-x-12">{children}</div>
        <div className="lg:flex lg:justify-end flex-col">
          <AccountActionsDropdown />
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
