import CashFlowChartContainer from '@/components/analyses/cashFlows/CashFlowChartContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import CashFlowDataGrid from '@/components/datagrids/CashFlowDataGrid';
import { useCashFlows } from '@/stores/systemAnalysisStore';

const CashFlowResult = () => {
  const { label = '', cash_flows: cashFlows } = useCashFlows();

  if (!cashFlows) {
    return null;
  }
  return (
    <AnalysisContainer title={label} className="gap-3">
      <AnalysisBox>
        <CashFlowChartContainer data={cashFlows} />
      </AnalysisBox>

      <AnalysisBox>
        <CashFlowDataGrid data={cashFlows} />
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default CashFlowResult;
