import PropTypes from 'prop-types';

const BalancedValues = ({ values, className = 'text-left' }) => (
  <div className={className}>
    {values.map(value => (
      <p key={value}>{value}</p>
    ))}
  </div>
);

BalancedValues.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

export default BalancedValues;
