const filterDatasets = (term, filterTagsArray, selectedFilterLogic, datasets) => {
  //let activeDatasets = datasets[activeCategory] || [];

  let activeDatasets = datasets || [];
  activeDatasets.forEach(dataset => (dataset.datatable_name_normalized = dataset.datatable_name.replace(/_/g, ' ')));
  activeDatasets.forEach(dataset => (dataset.tags_normalized = dataset.tags.map(tag => tag.replace(/_/g, ' '))));

  if (term) {
    const normalizedTerm = term.toLowerCase().replace(/[^\w\s]/g, '');
    activeDatasets = activeDatasets.filter(
      dataset =>
        dataset.datatable_name_normalized.includes(normalizedTerm) ||
        dataset.tags_normalized.some(tag => tag.includes(normalizedTerm)),
    );
  }

  if (filterTagsArray) {
    const normalizedFilterTags = Object.values(filterTagsArray).map(tag => tag.replace(/_/g, ' '));

    if (selectedFilterLogic === 'AND') {
      activeDatasets = activeDatasets.filter(dataset =>
        normalizedFilterTags.every(tag => dataset.tags_normalized.includes(tag)),
      );
    } else {
      activeDatasets = activeDatasets.filter(
        dataset =>
          dataset.datatable_name_normalized.includes(normalizedFilterTags.some(tag => tag)) ||
          dataset.tags_normalized.some(tag => normalizedFilterTags.includes(tag)),
      );
    }
  }

  // if (!tag || tag === 'Select Tag') {
  //   return activeDatasetCategories;
  // }

  // return activeDatasetCategories.filter(
  //   dataset => dataset.tags.some(o => o.label === tag) || dataset.tags.some(i => i.label === tag),
  // );

  return activeDatasets;
};

export default filterDatasets;
