import PropTypes from 'prop-types';
import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import { useSystemResult } from '@/stores/systemAnalysisStore';
import AttributesTable from './AttributesTable';
import CollapsibleButton from '@/components/core/CollapsibleButton';
import useCollapsible from '@/hooks/useCollapsible';

const AttributesResult = ({ name, className, isCollapsible = false, tableClassName = '' }) => {
  const results = useSystemResult(name);
  const { isOpen, toggle, isCollapsible: shouldCollapse } = useCollapsible({ isCollapsible });

  if (!results) return null;

  const { label } = results;

  return (
    <AnalysisContainer
      key={name}
      title={
        <span className="flex items-center gap-3">
          {label}
          {shouldCollapse ? <CollapsibleButton onClick={toggle} isOpen={isOpen} /> : null}
        </span>
      }
      className="relative"
    >
      {isOpen && (
        <AnalysisBox>
          <div className={className}>
            <AttributesTable name={name} className={tableClassName} />
          </div>
        </AnalysisBox>
      )}
    </AnalysisContainer>
  );
};

AttributesResult.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  isCollapsible: PropTypes.bool,
  tableClassName: PropTypes.string,
};

export default AttributesResult;
