import { comparisonActions, useCOACComparisonPlot } from '@/stores/comparisonStore';
import COACComparisonForm from './COACComparisonForm';
import StackedBarChart from '@/components/charts/StackedBarChart';
import COACComparisonSummary from './COACComparisonSummary';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import { isEmpty } from '@/utils/miscUtils';

const COACComparisonPlot = () => {
  const plot = useCOACComparisonPlot();
  const updatePlot = formData => comparisonActions.updatePlot('coac', formData);
  const { results } = plot;

  if (isEmpty(plot)) {
    return null;
  }

  return (
    <AnalysisContainer title="Cost Of Avoided Carbon">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <AnalysisBox>
            <>
              {results && (
                <StackedBarChart
                  rows={results.rows}
                  columns={results.columns}
                  yAxisLabel={`\n${results.yAxis}`}
                  labelsInCSV={true}
                />
              )}
              <COACComparisonForm onSubmit={updatePlot} defaultValues={plot} />
            </>
          </AnalysisBox>
        </div>
        {results && results?.rows?.[0]?.length > 1 && (
          <AnalysisBox>
            <COACComparisonSummary
              errors={results.errors}
              minTotal={results.minTotal}
              maxTotal={results.maxTotal}
              unit={results.yAxis}
            />
          </AnalysisBox>
        )}
      </div>
    </AnalysisContainer>
  );
};

export default COACComparisonPlot;
