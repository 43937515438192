import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ComparisonEmptyState from '@/components/comparisons/ComparisonEmptyState';

const ComparisonsRoute = () => {
  const { pathname } = useLocation();
  const displayEmptyState = pathname.match(/comparisons$/);

  return (
    <div className="w-full !overflow-y-scroll bg-canvas flex-1">
      {displayEmptyState ? <ComparisonEmptyState /> : <Outlet />}
    </div>
  );
};

export default ComparisonsRoute;
