import { createBrowserRouter, Navigate, redirect } from 'react-router-dom';
import LoginRoute from '@/routes/LoginRoute';
import PrivateRoute from '@/routes/PrivateRoute';
import ProjectsRoute from '@/routes/ProjectsRoute';
import ProjectRoute from '@/routes/ProjectRoute';
import { createProject, deleteProject, getProject, getProjects, updateProject } from '@/api/projects';

import ErrorBoundary from '@/components/core/ErrorBoundry';

import EditProjectModal from './components/projects/EditProjectModal';
import { getRegistry } from './api/registry';
import { registryActions } from './stores/registryStore';
import buildRouter from './routes/build';
import analysesRouter from './routes/analyses';
import { getPathways } from './api/pathways';
import { getSystems } from './api/systems';
import comparisonsRouter from './routes/comparisons';
import userRouter from './routes/user';
import RootRouter from './routes/RootRouter';
import { getComparisons } from './api/comparisons';
import { resourcesActions } from './stores/resourcesStore';
import dataSourceRouter from './routes/datasources';
import dashboardRouter from './routes/dashboard';
import config from './config';

const { featureFlags } = config;
const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorBoundary />,
    element: <RootRouter />,
    children: [
      {
        path: '/login',
        element: <LoginRoute />,
      },
      {
        element: <PrivateRoute />,
        id: 'root',
        shouldRevalidate: () => false,
        loader: async () => {
          const {
            data: { node_types },
          } = await getRegistry();

          registryActions.init(node_types);
          return { registry: node_types };
        },
        children: [
          userRouter,
          {
            path: '/',
            id: 'projects',
            element: <ProjectsRoute />,
            shouldRevalidate: () => false,
            loader: getProjects,
            handle: {
              title: _ => {
                return {
                  title: 'Welcome to Sesame',
                };
              },
            },
            children: [
              {
                path: '/projects/new',
                element: <EditProjectModal title="New Project" />,
                action: async ({ request }) => {
                  const form = await request.formData();
                  const project = Object.fromEntries(form);

                  const { data } = await createProject(project);
                  return redirect(`/projects/${data.id}`);
                },
              },
              {
                path: '/projects/:projectId/edit',
                element: <EditProjectModal title="Edit Project" />,
                loader: async ({ params: { projectId } }) => {
                  const { data: project } = await getProject(projectId);
                  return project;
                },
                action: async ({ request, params }) => {
                  const { projectId } = params;
                  const form = await request.formData();
                  const project = Object.fromEntries(form);

                  try {
                    await updateProject(projectId, project);
                  } catch (err) {
                    console.error(err);
                  }

                  return redirect('/');
                },
              },
              {
                path: '/projects/:projectId/destroy',
                action: async ({ params }) => {
                  const { projectId } = params;
                  try {
                    await deleteProject(projectId);
                  } catch (err) {
                    console.error(err);
                  }

                  return redirect('/');
                },
              },
            ],
          },
          ...(featureFlags.enableDatasource === 'true' ? [dataSourceRouter] : []),
          {
            path: '/projects/:projectId',
            id: 'project',
            element: <ProjectRoute />,

            loader: async ({ params }) => {
              const [project, pathways, systems, comparisons, projects] = await Promise.all([
                getProject(params.projectId),
                getPathways(params.projectId),
                getSystems(params.projectId),
                getComparisons(params.projectId),
                getProjects(),
              ]);

              resourcesActions.setResources({
                project: project?.data?.results,
                pathways: pathways?.data?.results,
                systems: systems?.data?.results,
                comparisons: comparisons?.data?.results,
              });

              return {
                project: project.data,
                pathways: pathways.data,
                systems: systems.data,
                comparisons: comparisons.data,
                projects: projects.data,
              };
            },
            handle: {
              crumb: data => data?.project?.name,
              title: data => {
                return { title: `Dashboard: ${data.project.name}` };
              },
            },
            children: [
              {
                index: true,
                element: <Navigate to="dashboard" replace />,
              },
              dashboardRouter,
              buildRouter,
              analysesRouter,
              comparisonsRouter,
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
