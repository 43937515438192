import { settingsActions, useTheme } from '@/stores/settingsStore';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const RootRouter = () => {
  const theme = useTheme();

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    settingsActions.fetchSettings();
  }, []);

  return <Outlet />;
};

export default RootRouter;
