import PropTypes from 'prop-types';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodeById, useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import Select from '@/components/core/form/Select';
import { getIOOptions, getUnitOptions, getUnitsByIO } from '@/utils/pathwayUtils';

import debounce from 'debounce';
import { useCostOverridesByNode } from '@/stores/caseStore';

const TEAPlotForm = ({ defaultValues, onSubmit, plotType = 'tea' }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, control, setValue, register, reset } = methods;
  const nodes = useNodes();
  const nodeOptions = nodes.map(({ id, label }) => ({ label, value: id }));
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);
  const isTeaPlot = plotType === 'tea';

  const [nodeId, levelizeBy] = useWatch({
    control,
    name: ['nodeId', 'levelizeBy'],
    defaultValue: defaultValues,
  });

  const node = useNodeById(nodeId);
  const unitsByIO = getUnitsByIO(node);
  const levelizeByOptions = getIOOptions(node);
  const defaultLevelizeBy = levelizeByOptions.find(o => o.value === levelizeBy)?.value ?? levelizeByOptions?.[0]?.value;
  const defaultLevelizeByLabel =
    levelizeByOptions.find(o => o.value === levelizeBy)?.label ?? levelizeByOptions?.[0]?.label;
  const levelizeUnitOptions = getUnitOptions(unitsByIO?.[levelizeBy]?.units);
  const defaultLevelizeUnit = levelizeUnitOptions?.[0].value;
  const costOverride = useCostOverridesByNode(nodeId);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  // when node is changed update default levelizeBy
  useEffect(() => {
    setValue('levelizeBy', defaultLevelizeBy);
  }, [defaultLevelizeBy, setValue]);

  // when levelizeBy is changed update the default levelizeUnit
  useEffect(() => {
    setValue('levelizeUnit', defaultLevelizeUnit);
  }, [defaultLevelizeUnit, setValue]);

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <form onChange={debouncedSave}>
          <div className="mt-2 grid grid-cols-2 gap-1">
            <FormRow className="overflow-hidden" label="Module" name="nodeId">
              <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
            </FormRow>
            <FormRow className="overflow-hidden" label="Functional Unit" name="levelizeUnit">
              <div className="flex join items-center overflow-hidden w-full mb-2">
                <div className="join-item w-full overflow-hidden truncate input input-bordered input-xs mr-1">
                  {defaultLevelizeByLabel}
                </div>
                <Select name="levelizeUnit" className="join-item w-full truncate" options={levelizeUnitOptions} />
              </div>
            </FormRow>
            {isTeaPlot && !costOverride && (
              <FormRow className="overflow-hidden" label="Upstream Costs Breakdown" name="upstreamCostsBreakdown">
                <input
                  {...register('upstreamCostsBreakdown')}
                  id="upstreamCostsBreakdown"
                  name="upstreamCostsBreakdown"
                  type="checkbox"
                  className="toggle toggle-sm toggle-success mt-1 w-full truncate"
                />
              </FormRow>
            )}
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

TEAPlotForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  plotType: PropTypes.string,
};

export default TEAPlotForm;
