import { getStack } from '@/utils/stackUtils';
import * as Sentry from '@sentry/react';

const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = getStack();

const ignoreErrors = [/status code 400/im, /Request failed with status code 401/im];

if (dsn) {
  Sentry.init({
    dsn,
    integrations: [
      Sentry.feedbackIntegration({
        autoInject: false,
        showBranding: false,
        showName: false,
        showEmail: false,
      }),
    ],
    ignoreErrors,
    environment,
  });
}
