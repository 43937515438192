import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import useSetDefaultValue from '../useSetDefaultValue';
import ConversionField from '../ConversionField';
import useConversion from '../useConversion';
import { validatorRules } from '@/utils/validators';
import MCFieldsWrapper from '@/components/nodes/MCFieldsWrapper';
import MCFields from '@/components/nodes/MCFields';
import { useIsUserDefined } from '../useIsUserDefined';

const InputField = ({ param, name, defaultValue, watchedFields }) => {
  const { label, validators, type, tooltip, source, conversion, mc_eligible: isMcEligible, conversions } = param;
  const {
    control,
    register,
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();
  const valueAsNumber = type === 'numeric';
  const numericValidator = valueAsNumber && !validators.numeric ? { numeric: validatorRules.numeric() } : {};
  const error = get(errors, name);
  const errorMessage = error?.message;
  const mcEvents = {
    onChange: e => {
      e.preventDefault();
    },
    onBlur: e => {
      const prevValue = getValues(name);
      const curValue = type === 'numeric' ? parseFloat(e.target.value) : e.target.value;

      if (prevValue !== curValue) {
        setValue(name, e.target.value, { shouldValidate: true, shouldDirty: true });
      }
    },
  };

  useSetDefaultValue(name, defaultValue, watchedFields, conversion);

  const { validators: dynamicValidators } = useConversion(param, name);
  const validate = { ...validators, ...dynamicValidators, ...numericValidator };
  const registration = register(name, {
    validate,
    valueAsNumber,
  });

  const mcParam = { ...param, defaultValue, validators: validate };

  const { isUserDefined, convertedDefaultValue } = useIsUserDefined({
    name,
    control,
    defaultValue,
    conversion,
  });

  return (
    <>
      <FormRow label={label} tooltip={tooltip} source={source} name={name}>
        <div className="flex flex-col flex-1">
          <div className="join flex">
            <input
              id={name}
              {...registration}
              type="text"
              name={name}
              {...(!isNaN(convertedDefaultValue)
                ? {
                    placeholder: convertedDefaultValue,
                    defaultValue: convertedDefaultValue,
                  }
                : {})}
              {...(isMcEligible ? mcEvents : {})}
              className={`[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none input input-bordered input-sm w-full flex-1 join-item ${errorMessage ? 'input-error' : ''} ${isUserDefined ? 'font-bold placeholder-shown:font-normal' : ''}`}
            />
            {conversion && <ConversionField name={`conversion.${name}`} conversions={conversions} />}
          </div>
          <ErrorMessage text={errorMessage} />
        </div>
      </FormRow>
      {isMcEligible && (
        <MCFieldsWrapper param={mcParam}>
          <MCFields param={mcParam} />
        </MCFieldsWrapper>
      )}
    </>
  );
};

InputField.propTypes = {
  param: PropTypes.object,
  index: PropTypes.number,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  watchedFields: PropTypes.object,
  children: PropTypes.node,
};

export default InputField;
