import { analysisActions, useCostBreakdown, useNodeById } from '@/stores/analysisStore';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import TEAPlotForm from '../tea/TEAPlotForm';
import { getTEAYaxisLabel } from '@/utils/analysisUtils';

const CostBreakdown = () => {
  const plot = useCostBreakdown();
  const { nodeId, results } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot('tea_breakdown', formData);

  if (!node || !results) {
    return null;
  }

  const unit = getTEAYaxisLabel(plot, node, false);

  return (
    <AnalysisContainer title="Techno-Economic Analysis">
      <AnalysisBox>
        {results?.map(({ header, rows, total }) => {
          return (
            rows &&
            rows.length > 0 && (
              <div className="prose max-w-full" key={header}>
                <h4>{header}</h4>
                <table className="prose text-xs min-w-full">
                  {rows.map(({ label, children }) => (
                    <tbody key={`${header}-${label}`}>
                      <tr>
                        <td colSpan={3}>{label}</td>
                      </tr>
                      {children.map(({ label, amount }) => (
                        <tr className="border-transparent" key={`${header}-${label}`}>
                          <td className="w-4/6 before:content-['•'] before:px-2">{label}</td>
                          <td>{amount && amount.toFixed(2)}</td>
                          <td className="w-1/6">{unit}</td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
                  <tfoot>
                    <tr className="border-b border-black font-bold">
                      <td>Total</td>
                      <td>{total.toFixed(2)}</td>
                      <td>{unit}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )
          );
        })}
        <TEAPlotForm onSubmit={updatePlot} defaultValues={plot} plotType="tea_breakdown" />
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default CostBreakdown;
