import DataRegistry from '@/components/pathways/DataRegistry';

//Is this redundant? Can't I just replace this component with the DataRegistry component itself?
const DataSourcesDashboardRoute = () => {
  return (
    <div className="w-full h-[90vh] mb-20 flex justify-center items-center">
      <div className="h-full w-full mr-20 ml-20 flex justify-center items-center">
        <DataRegistry />
      </div>
    </div>
  );
};

export default DataSourcesDashboardRoute;
