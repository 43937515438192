import { SYSTEM_STATUS } from '@/consts';
import { useSystemStatus } from '@/stores/systemStore';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { Link, useParams } from 'react-router-dom';

const OpenSystemAnalysisButton = () => {
  const status = useSystemStatus();
  const { projectId, systemId } = useParams();

  if (status === SYSTEM_STATUS.succeeded) {
    return (
      <Link to={`/projects/${projectId}/analysis/systems/${systemId}`} className="btn ml-2 btn-sm btn-primary">
        <ChartBarIcon className="w-5 h-5" />
        View Analysis
      </Link>
    );
  }
};

export default OpenSystemAnalysisButton;
