import Chart from 'chart.js/auto';

const wrapText = (ctx, text, maxWidth) => {
  const words = text.split(' ');
  let lines = [];
  let line = '';

  words.forEach(word => {
    let testLine = line + word + ' ';
    let metrics = ctx.measureText(testLine);
    let testWidth = metrics.width;

    if (testWidth > maxWidth && line.length > 0) {
      lines.push(line.trim());
      line = word + ' ';
    } else {
      line = testLine;
    }
  });

  if (line.length > 0) {
    lines.push(line.trim());
  }

  return lines;
};

const fontStyles = [
  'bolder 14px Arial',
  `${Chart.defaults.font.style} ${Chart.defaults.font.size}px ${Chart.defaults.font.family}`,
];
const fontColors = ['black', '#1f2328cc'];

const yAxisLabelPlugin = {
  id: 'yAxisLabelPlugin',
  afterDraw: chart => {
    const {
      ctx,
      config,
      chartArea: { left },
    } = chart;

    if (!config.options.plugins.yAxisLabel) {
      return;
    }

    const { enabled, text } = config.options.plugins.yAxisLabel;

    if (enabled) {
      ctx.save();
      const lines = text?.split('\n');

      lines.forEach((line, index) => {
        ctx.font = fontStyles[index] || fontStyles[1];
        ctx.fillStyle = fontColors[index] || fontColors[1];
        ctx.fillText(line, left - 20, index * 18 + 18);
      });

      // ctx.restore();
    }
  },
};

const customCanvasBackgroundPlugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, _, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#ffffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const horizontalLegendBar = {
  id: 'horizontalLegendBar',
  beforeDatasetsDraw: chart => {
    const {
      ctx,
      data,
      chartArea: { bottom },
      scales: { x },
    } = chart;

    const dataPoints = data.datasets
      .map(dataset => {
        return dataset.data[0];
      })
      .filter(item => item >= 15);

    const accumulate = array =>
      array.map(
        (
          sum => value =>
            (sum += value)
        )(0),
      );
    const cumulative = accumulate(dataPoints);
    cumulative.unshift(0);

    for (let i = 0; i < dataPoints.length; i++) {
      const value = dataPoints[i];
      ctx.font = 'normal 11px Arial';
      ctx.fillStyle = 'black';

      // Calculate maxWidth based on the segment width
      const startX = x.getPixelForValue(cumulative[i]);
      const endX = x.getPixelForValue(cumulative[i + 1]);
      const maxWidth = endX - startX - 10; // Subtract some padding

      const lines = wrapText(ctx, data.datasets[i].label, maxWidth);
      for (let j = 0; j < lines.length; j++) {
        ctx.fillText(lines[j], x.getPixelForValue(cumulative[i]) + 10, bottom + 30 + j * 15);
      }

      ctx.fillText(`${value.toFixed(0)}%`, x.getPixelForValue(cumulative[i]) + 10, bottom + 35 + lines.length * 15);
    }
  },
};

export const customGridLine = {
  id: 'customGridLine',
  beforeDraw: chart => {
    const {
      ctx,
      chartArea: { bottom, left, right },
      options,
    } = chart;
    const chartArea = chart.chartArea;
    const middlePixel = (chartArea.left + chartArea.right) / 2;

    const { xAxisLabel } = options;

    ctx.save();
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(middlePixel, chart.chartArea.top);
    ctx.lineTo(middlePixel, chart.chartArea.bottom);
    ctx.stroke();
    ctx.restore();

    ctx.save();
    ctx.font = 'bold 12px Arial';
    ctx.fillStyle = 'black';
    ctx.textAlign = 'center';
    ctx.fillText(xAxisLabel, (left + right) / 2, bottom + 55);
    ctx.restore();
  },
};

Chart.register(customCanvasBackgroundPlugin);
Chart.register(yAxisLabelPlugin);
