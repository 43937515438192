import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { CHART_COLORS } from '@/consts';

const buildData = results => {
  return {
    labels: results.labels,
    datasets: results.datasets.map((dataset, index) => ({
      data: dataset.data,
      label: dataset.label,
      borderColor: CHART_COLORS[index],
      backgroundColor: CHART_COLORS[index],
      pointBackgroundColor: CHART_COLORS[index],
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: CHART_COLORS[index],
      fill: false,
      tension: 0.1,
    })),
  };
};

const buildOptions = (label, yAxisLabel, legendTitle, results) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: results?.datasets?.length > 1,
        position: 'bottom',
        title: {
          display: results?.datasets?.length > 1,
          text: legendTitle,
          padding: 15,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: label,
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
        },
      },
    },
  };

  return options;
};

const LineChart = ({ results, label, legendTitle, yAxisLabel = '' }) => {
  const lineData = buildData(results);
  const options = buildOptions(label, yAxisLabel, legendTitle, results);

  return (
    <div className="min-h-80 relative mr-2">
      <Line data={lineData} options={options} />
    </div>
  );
};

LineChart.propTypes = {
  results: PropTypes.object,
  label: PropTypes.string,
  legendTitle: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

export default LineChart;
