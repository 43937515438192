import PropTypes from 'prop-types';

const MCFieldsSummary = ({ min, max }) => (
  <div className={`flex gap-4 text-xs ml-2`}>
    <div className="flex gap-1 items-center">
      <span className="badge badge-success h-2 px-[0.2rem]"></span>
      <span className="text-gray-400">Uncertainty</span>
    </div>
    <div className="flex gap-1">
      <span className="text-gray-400">Min:</span>
      {min}
    </div>
    <div className="flex gap-1">
      <span className="text-gray-400">Max:</span>
      {max}
    </div>
  </div>
);

MCFieldsSummary.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
};

export default MCFieldsSummary;
