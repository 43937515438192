import PropTypes from 'prop-types';
import { useForm, useWatch } from 'react-hook-form';
import FormRow from '@/components/core/form/FormRow';
import { useNodeById, useNodes } from '@/stores/analysisStore';
import { useEffect } from 'react';
import Select from '@/components/core/form/Select';
import { FormProvider } from 'react-hook-form';

import debounce from 'debounce';
import { getIOOptions, getUnitOptions, getUnitsByIO } from '@/utils/pathwayUtils';

const SensitivityForm = ({ defaultValues, onSubmit }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, control, setValue } = methods;
  const nodes = useNodes();
  const nodeOptions = nodes.map(({ id, label }) => ({ label, value: id }));
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 100);

  const [nodeId, levelizeBy] = useWatch({
    control,
    name: ['nodeId', 'levelizeBy'],
    defaultValue: defaultValues,
  });

  const node = useNodeById(nodeId);
  const unitsByIO = getUnitsByIO(node);
  const levelizeByOptions = getIOOptions(node);
  const defaultLevelizeBy = levelizeByOptions?.[0]?.value;
  const levelizeUnitOptions = getUnitOptions(unitsByIO?.[levelizeBy]?.units);
  const defaultLevelizeUnit = levelizeUnitOptions?.[0].value;

  // when node is changed update default levelizeBy
  useEffect(() => {
    if (levelizeBy && levelizeBy !== defaultLevelizeBy) {
      setValue('levelizeBy', defaultLevelizeBy);
    }
  }, [levelizeBy, defaultLevelizeBy, setValue]);

  // when levelizeBy is changed update the default levelizeUnit
  useEffect(() => {
    setValue('levelizeUnit', defaultLevelizeUnit);
  }, [defaultLevelizeUnit, levelizeBy, defaultLevelizeBy, setValue]);

  return (
    <FormProvider {...methods}>
      <div className="group node">
        <form onChange={debouncedSave}>
          <div className="mt-2 grid grid-cols-6 gap-2">
            <FormRow className="overflow-hidden" label="Module" name="nodeId">
              <Select className="w-full truncate" name="nodeId" options={nodeOptions} />
            </FormRow>
            <FormRow className="overflow-hidden" label="Functional Unit" name="levelizeBy">
              <div className="flex join items-center overflow-hidden w-full">
                <Select className="join-item w-full truncate" name="levelizeBy" options={levelizeByOptions} />
                <Select className="join-item truncate" name="levelizeUnit" options={levelizeUnitOptions} />
              </div>
            </FormRow>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};

SensitivityForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default SensitivityForm;
