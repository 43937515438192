import { FormProvider, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import Select from '../core/form/Select';
import { useEffect } from 'react';
import { useNode, usePathwayByCaseId } from '@/stores/comparisonStore';
import { getNodeOptions, getOutputOptions } from '@/utils/pathwayUtils';
import debounce from 'debounce';
import { ComparisonCaseActions } from './ComparisonCaseActions';

const ComparisonCaseForm = ({ defaultValues, onSubmit, pathwaysOptions }) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset, watch, register, control, setValue } = methods;
  const debouncedSave = debounce(() => handleSubmit(onSubmit)(), 10);
  const [caseId, nodeId] = useWatch({
    control,
    name: ['case_id', 'node_id'],
    defaultValue: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const pathway = usePathwayByCaseId(caseId);
  const nodes = Object.values(pathway?.nodes ?? {});
  const nodeOptions = [{ label: 'Choose one', value: '' }, ...getNodeOptions(nodes)];
  const defaultNodeId = nodeOptions?.length === 1 ? nodes?.[0]?.id : null;
  const currentNode = useNode(caseId, nodeId);
  const outputOptions = getOutputOptions(currentNode);
  const defaultOutputName = outputOptions?.length === 1 ? outputOptions?.[0]?.value : null;

  useEffect(() => {
    if (caseId !== defaultValues.case_id && defaultNodeId) {
      setValue('node_id', defaultNodeId);
    }
  }, [caseId, defaultValues.case_id, defaultNodeId, setValue]);

  useEffect(() => {
    if (nodeId !== defaultValues.node_id) {
      setValue('output_name', defaultOutputName);
    }
  }, [nodeId, defaultValues.node_id, defaultOutputName, setValue]);

  useEffect(() => {
    const subscription = watch(debouncedSave);
    return () => subscription.unsubscribe();
  }, [watch, debouncedSave]);

  const events = {
    onChange: e => e.preventDefault(),
    onBlur: e => setValue('name', e.target.value),
  };

  return (
    <FormProvider {...methods}>
      <tr>
        <td className="p-1 pl-8 comparison-case-name">
          <input
            {...register('name')}
            {...events}
            name="name"
            type="text"
            className="input-bordered input-sm w-3/4 rounded-none truncate"
          />
        </td>
        <td className="p-1">
          <div className="flex">
            <Select name="case_id" className="select-sm max-w-[80%] flex-1" options={pathwaysOptions} />
          </div>
        </td>
        <td>
          <div className="flex">
            <Select name="node_id" className="select-sm max-w-[80%] flex-1" options={nodeOptions} />
          </div>
        </td>
        <td>
          <div className="flex">
            <Select name="output_name" className="select-sm max-w-[80%] flex-1" options={outputOptions} />
          </div>
        </td>
        <td className="pr-8">
          <ComparisonCaseActions comparisonCase={defaultValues} />
        </td>
      </tr>
    </FormProvider>
  );
};

ComparisonCaseForm.propTypes = {
  defaultValues: PropTypes.object,
  pathwaysOptions: PropTypes.arrayOf(PropTypes.object),
  onSubmit: PropTypes.func,
};

export default ComparisonCaseForm;
