import { useEffect } from 'react';
import { useMaintenance } from '@/stores/settingsStore';
import ToastContainer from './ToastContainer';
import { convertToLocalDate, formatDate } from '@/utils/miscUtils';
import { toast } from '@/utils/toast';

const toastOptions = {
  position: 'bottom-center',
};

const getMaintenanceMessage = (start, end, message) =>
  `Warning: Sesame will undergo a scheduled maintenance from ${formatDate(start)} to ${formatDate(end)}.
Services may temporarily be unavailable. ${message ?? ''}`;

const MaintenanceBanner = () => {
  const maintenance = useMaintenance();
  const start = maintenance?.window?.start;
  const end = maintenance?.window?.end;
  const message = maintenance?.message;

  useEffect(() => {
    if (start && end) {
      const stateDate = convertToLocalDate(start);
      const endDate = convertToLocalDate(end);
      const nowDate = new Date(Date.now());

      if (endDate >= nowDate) {
        const toastMessage = getMaintenanceMessage(stateDate, endDate, message);
        toast.error(toastMessage, toastOptions);
      }
    } else if (message) {
      toast.error(message, toastOptions);
    }
  }, [start, end, message]);

  return <ToastContainer />;
};

export default MaintenanceBanner;
