import PropTypes from 'prop-types';
import { useParams, useNavigate, useSubmit } from 'react-router-dom';
import ActionsDropdown from '../core/ActionsDropdown';
import { confirm } from '@/components/core/createConfirmation';

const ComparisonActionDropdown = ({ comparison }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();
  const edit = comparison => navigate(`/projects/${projectId}/comparisons/${comparison.id}/edit`);
  const remove = async () => {
    if (await confirm('Are you sure you want to remove this comparison?')) {
      submit(comparison, {
        method: 'delete',
        action: `/projects/${projectId}/comparisons/${comparison.id}/destroy`,
      });
    }
  };

  return <ActionsDropdown onDelete={remove} onRename={edit} resource={comparison} />;
};

ComparisonActionDropdown.propTypes = {
  comparison: PropTypes.object,
};

export default ComparisonActionDropdown;
