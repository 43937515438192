import AttributesResult from './results/v2/AttributesResult';
import CapacitiesResults from './results/v2/CapacitiesResult';
import CostResult from './results/v2/CostResult';
import DataFrameResults from './results/v2/DataFrameResults';
import OperationsTimeSeriesResultContainer from './results/v2/OperationsTimeSeriesResultContainer';
import TechnologiesResult from './results/v2/TechnologiesResult';
import TimeSeriesResultContainer from './results/v2/TimeSeriesResultContainer';
import { useAsyncValue } from 'react-router-dom';
import LocationResult from './results/LocationResult';
import CashFlowResult from './results/v2/CashFlowResult';

const SystemResults = () => {
  const { id } = useAsyncValue();

  return (
    <div className="relative bg-canvas px-4 pb-4" key={id}>
      <AttributesResult name="main_inputs" className="grid grid-cols-3 gap-4" isCollapsible />
      <div className="grid grid-cols-2 gap-4">
        <AttributesResult name="systems_performance" tableClassName="w-80" />
        <LocationResult />
      </div>
      <div className="grid grid-cols-2">
        <CapacitiesResults />
      </div>
      <CostResult />
      <DataFrameResults />
      <TechnologiesResult />
      <TimeSeriesResultContainer />
      <OperationsTimeSeriesResultContainer />
      <CashFlowResult />
    </div>
  );
};

export default SystemResults;
