import { useNodeById, useTEAPlot } from '@/stores/analysisStore';
import WaterfallChart from '../../charts/WaterfallChart';
import { analysisActions } from '@/stores/analysisStore';
import TEAPlotForm from './TEAPlotForm';
import { getTEAYaxisLabel } from '@/utils/analysisUtils';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const TEAPlot = () => {
  const plot = useTEAPlot();
  const { nodeId, results } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot('tea', formData);

  return (
    <AnalysisContainer title="Techno-Economic Analysis">
      <AnalysisBox>
        {results && (
          <WaterfallChart
            data={results}
            label={node.label}
            yAxisLabel={`Levelized Cost\n[${getTEAYaxisLabel(plot, node)}]`}
          />
        )}

        {node ? (
          <TEAPlotForm onSubmit={updatePlot} defaultValues={plot} />
        ) : (
          <span className="loading loading-dots loading-md"></span>
        )}
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default TEAPlot;
