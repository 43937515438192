import { getSystem, getSystemResult, getSystemResults } from '@/api/systems';
import { parseCategories } from '@/utils/paramsUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { systemActions } from './systemStore';

const CAPACITY_TYPES = {
  EXISTING: 'existing_capacity',
  NEW: 'new_capacity',
  TOTAL: 'total_capacity',
};

const getCapacityValue = (capacity, name) => capacity.attributes.find(a => a.name === name)?.value.scalar || 0;

const initialValues = {
  results: {},
  systemId: null,
};

const useSystemAnalysisStore = create()(immer(() => initialValues));

const { setState: set, getState: get } = useSystemAnalysisStore;

const parsers = {
  costs: ({ costs }) => ({ costs }),
  hourly_timeseries: ({ series }) => ({ series }),
  dataframe: result => result,
  attributes: ({ attributes }) => ({ attributes, categories: parseCategories(attributes) }),
  cash_flows: result => result,
};

// actions
export const systemAnalysisActions = {
  init: async systemId => {
    systemAnalysisActions.clear();
    const { data: system } = await getSystem(systemId);
    const { id, results: systemResults } = system;
    const resultNames = systemResults.map(result => ({ result_name: result.name }));
    const { data } = await getSystemResults(id, resultNames);
    const results = {};

    data.forEach((result, index) => {
      const resultMeta = systemResults[index];
      const parsedData = parsers[resultMeta.result_type](result);
      results[resultMeta.name] = { ...resultMeta, ...result, ...parsedData, systemType: system.system_type };
    });

    set({ results, systemId });

    systemActions.init(system);

    return system;
  },

  clear: () => set(initialValues, true),

  updateResult: async data => {
    const { systemId, results } = get();
    const { data: result } = await getSystemResult(systemId, {
      result_name: data.plot,
      unit: JSON.parse(data.unit),
    });

    const currentResult = results[data.plot];
    const resultType = currentResult.result_type;
    const parsedData = parsers[resultType](result);

    set(state => {
      state.results[data.plot] = { ...currentResult, ...result, ...parsedData };
    });
  },
};

// selectors
export const useSystemResult = name =>
  useSystemAnalysisStore(store => {
    return store.results[name];
  });

export const useSystemResults = () => useSystemAnalysisStore(store => store.results);

export const useTechnologies = () =>
  useSystemAnalysisStore(store =>
    Object.values(store.results).filter(result => result.name.match(/summary_|_attributes/)),
  );
export const useCapacities = () =>
  useSystemAnalysisStore(store => {
    const capacities = Object.values(store.results)
      .filter(result => result.result_type === 'attributes' && result.name.match(/_attributes/))
      .map(result => ({
        label: result.label,
        attributes: result.attributes.filter(a => Object.values(CAPACITY_TYPES).includes(a.name)),
      }));

    const existingCapacityLabel = capacities[0]?.attributes.find(a => a.name === CAPACITY_TYPES.EXISTING)?.label;
    const newCapacityLabel = capacities[0]?.attributes.find(a => a.name === CAPACITY_TYPES.NEW)?.label;
    const totalCapacityLabel = capacities[0]?.attributes.find(a => a.name === CAPACITY_TYPES.TOTAL)?.label;

    const unit = capacities[0]?.attributes[0]?.value.unit;

    const columns = capacities.map(c => c.label);

    const rows =
      existingCapacityLabel && newCapacityLabel
        ? [
            [existingCapacityLabel, ...capacities.map(c => getCapacityValue(c, CAPACITY_TYPES.EXISTING))],
            [newCapacityLabel, ...capacities.map(c => getCapacityValue(c, CAPACITY_TYPES.NEW))],
          ]
        : [[totalCapacityLabel, ...capacities.map(c => getCapacityValue(c, CAPACITY_TYPES.TOTAL))]];

    return { rows, columns, unit };
  });
export const useHourlyTimeSeriesOptions = () =>
  useSystemAnalysisStore(store => {
    return Object.values(store.results)
      .filter(result => result.result_type === 'hourly_timeseries' && !result.name.match(/operations_/))
      .map(result => ({
        label: result.label,
        value: result.name,
        systemType: result.systemType,
        unit: result.unit,
        convertibleUnits: result.convertible_units,
      }));
  });
export const useOperationsHourlyTimeSeriesOptions = () =>
  useSystemAnalysisStore(store => {
    return Object.values(store.results)
      .filter(result => result.result_type === 'hourly_timeseries' && result.name.match(/operations_/))
      .map(result => ({
        label: result.label,
        value: result.name,
        systemType: result.systemType,
        unit: result.unit,
        convertibleUnits: result.convertible_units,
      }));
  });

export const useCosts = () => useSystemAnalysisStore(store => store.results.costs || store.results.system_costs);
export const useCashFlows = () => useSystemAnalysisStore(store => store?.results?.cash_flows || {});
export const useDataframes = () =>
  useSystemAnalysisStore(store => Object.values(store.results).filter(result => result.result_type === 'dataframe'));

export const useResults = () => useSystemAnalysisStore(store => store.results);

export default useSystemAnalysisStore;
