import PropTypes from 'prop-types';

import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { settingsActions } from '@/stores/settingsStore';

const WalkthroughButton = ({ type, parentRef }) => {
  const handleClick = () => {
    if (parentRef) {
      parentRef.current.scrollIntoView({ behavior: 'auto' });
    }
    settingsActions.enableWalkthrough(type);
  };

  return (
    <button className="absolute z-[100] bottom-0 right-0 rounded-full mr-6 mb-6" onClick={handleClick}>
      <QuestionMarkCircleIcon className="transition ease-in-out fill-gray-300 hover:fill-gray-400 duration-150 w-9 h-9" />
    </button>
  );
};

WalkthroughButton.propTypes = {
  parentRef: PropTypes.object,
  type: PropTypes.string,
};

export default WalkthroughButton;
