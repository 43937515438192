import { useIsCOACVisible, useIsLCAVisible, useIsTEAVisible } from '@/stores/comparisonStore';
import ComparisonOptionsDropdown from './ComparisonOptionsDropdown';
import LCAComparisonPlot from './lca/LCAComparisonPlot';
import TEAComparisonPlot from './tea/TEAComparisonPlot';
import COACComparisonPlot from './coac/COAComparisonPlot';

const ComparisonsReport = () => {
  const teaVisible = useIsTEAVisible();
  const lcaVisible = useIsLCAVisible();
  const coacVisible = useIsCOACVisible();

  return (
    <div className="bg-canvas flex flex-col gap-4 px-4 pb-4 relative">
      <ComparisonOptionsDropdown />
      {lcaVisible && <LCAComparisonPlot />}
      {teaVisible && <TEAComparisonPlot />}
      {coacVisible && <COACComparisonPlot />}
    </div>
  );
};

export default ComparisonsReport;
