import PropTypes from 'prop-types';

const ErrorMessage = ({ text }) =>
  text && (
    <div className="label">
      <span className="label-text-alt text-error">{text}</span>
    </div>
  );

ErrorMessage.propTypes = {
  text: PropTypes.string,
};

export default ErrorMessage;
