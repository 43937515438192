import Joyride from 'react-joyride';
import PropTypes from 'prop-types';
import walkthroughSteps from '@/walkthroughSteps';
import { settingsActions, useIsWalkthroughEnabled } from '@/stores/settingsStore';

const Walkthrough = ({ type, singlePass = true }) => {
  const steps = walkthroughSteps[type];
  const isEnabled = useIsWalkthroughEnabled(type);

  const handleCallback = event => {
    if (event.status === 'finished' || event.status === 'skipped') {
      if (singlePass) {
        settingsActions.disableWalkthrough(type);
      }
    }
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <Joyride
      steps={steps}
      callback={handleCallback}
      continuous
      showProgress
      showSkipButton
      scrollToFirstStep={true}
      disableScrollParentFix
      disableScrolling
      styles={{
        options: {
          primaryColor: 'rgba(0, 173, 116, 1)',
        },
      }}
    />
  );
};

Walkthrough.propTypes = {
  type: PropTypes.string,
  singlePass: PropTypes.bool,
};

export default Walkthrough;
