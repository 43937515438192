import { patchNode } from '@/api/nodes';
import { removeEmpty } from '@/utils/miscUtils';
import { prepareConversionParams, prepareMcParams } from '@/utils/paramsUtils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { caseActions } from './caseStore';

const initialValues = null;
const useNodeStore = create()(immer(() => initialValues));
const { setState: set, getState: get } = useNodeStore;

// actions
export const nodeActions = {
  init: (node, caseNode) => {
    set({ node, caseNode });
  },

  clear: () => set(initialValues, true),

  patchParams: async (nodeId, paramsData) => {
    const {
      caseNode: { case_id: caseId },
    } = get();
    const cleanedParams = removeEmpty(paramsData);
    const mcParams = prepareMcParams(paramsData);
    // eslint-disable-next-line no-unused-vars
    const { mc_params, conversion, ...params } = cleanedParams;
    const paramsWithConversions = prepareConversionParams(params, conversion);
    const payload = {
      params: paramsWithConversions,
    };

    if (mcParams) {
      payload.mc_params = mcParams;
    }

    const { data } = await patchNode(caseId, nodeId, payload);

    set(state => {
      state.caseNode.params = data.params;
      state.caseNode.mc_params = data.mc_params;
    });

    caseActions.markUnbalanced();
  },
};

// selectors
export const useNodeParams = () => useNodeStore(store => store?.node?.params);
export const useCaseNodeParams = () => useNodeStore(store => store?.caseNode?.params);
export const useCaseNodeMcParams = () =>
  useNodeStore(store => {
    const mcParams = store?.caseNode?.mc_params || {};

    return Object.keys(mcParams).reduce((acc, param) => {
      Object.keys(mcParams[param]).map(oParam => {
        acc[`mc_params.${param}.${oParam}`] = mcParams[param][oParam];
      });
      return acc;
    }, {});
  });

export default useNodeStore;
