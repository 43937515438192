import PropTypes from 'prop-types';
import { useSystemType } from '@/stores/systemStore';
import { TableCellsIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { VIEW_TYPES } from '@/consts';

const ToggleViewButton = ({ onToggle, viewType }) => {
  const systemType = useSystemType();
  const isHydrogenProduction = systemType === 'hydrogen_production';

  if (!isHydrogenProduction) {
    return;
  }

  return (
    <div className="join ml-2">
      <button
        onClick={() => {
          onToggle(VIEW_TYPES.tabs);
        }}
        type="button"
        className={`btn join-item btn-sm ${viewType === VIEW_TYPES.tabs ? 'btn-secondary' : ''}`}
      >
        <TableCellsIcon className="w-5 h-5" />
      </button>
      <button
        onClick={() => {
          onToggle(VIEW_TYPES.grid);
        }}
        type="button"
        className={`btn join-item btn-sm ${viewType === VIEW_TYPES.grid ? 'btn-secondary' : ''}`}
      >
        <Squares2X2Icon className="w-5 h-5" />
      </button>
    </div>
  );
};

ToggleViewButton.propTypes = {
  onToggle: PropTypes.func,
  viewType: PropTypes.string,
};

export default ToggleViewButton;
