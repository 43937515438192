import PropTypes from 'prop-types';
import ComparisonsList from '../comparisons/ComparisonsList';
import ListPane from './ListPane';

const ComparisonDropdownContent = ({ comparisons = [] }) => (
  <>
    <ListPane>
      <ComparisonsList comparisons={comparisons} />
    </ListPane>
  </>
);

ComparisonDropdownContent.propTypes = {
  comparisons: PropTypes.arrayOf(PropTypes.object),
};

export default ComparisonDropdownContent;
