import { useCallback, useEffect, useRef } from 'react';
import debounce from 'debounce';

// https://gist.github.com/felipecsl/afb987f8b6059814cff0a2ca6020e108
export function useLazyEffect(effect, deps = [], wait = 300) {
  const cleanUpRef = useRef();
  const effectRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  effectRef.current = useCallback(effect, deps);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lazyEffect = useCallback(
    debounce(() => {
      if (cleanUpRef.current) {
        cleanUpRef.current();
      }
      cleanUpRef.current = effectRef.current?.();
    }, wait),
    [wait],
  );

  useEffect(() => {
    lazyEffect();
    return () => lazyEffect.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    return () => {
      if (cleanUpRef.current) {
        cleanUpRef.current();
      }
    };
  }, []);
}
