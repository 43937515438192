import { analysisActions, useNodeById, useMCPlot } from '@/stores/analysisStore';
import MCPlotForm from './MCPlotForm';
import MCChart from '@/components/charts/MCChart';
import { getMCXaxisLabel } from '@/utils/analysisUtils';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const MCPlot = () => {
  const plot = useMCPlot();
  const { nodeId, results, distribution = 'emissions', scalar = 1 } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot('mc', formData);

  if (!node || !results?.[distribution]) {
    return null;
  }

  const { histogram, unit } = results[distribution];
  const { values: dataset, bin_edges: labels } = histogram;
  const xAxisLabel = getMCXaxisLabel(distribution, unit, scalar);

  return (
    <AnalysisContainer title="Monte Carlo Analysis">
      <AnalysisBox>
        <div>
          <MCChart data={dataset} labels={labels} distribution={distribution} xAxisLabel={xAxisLabel} scalar={scalar} />
        </div>
        <div>
          <MCPlotForm onSubmit={updatePlot} defaultValues={plot} />
        </div>
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default MCPlot;
