import { caseActions, useFlowRate } from '@/stores/caseStore';
import FormRow from '../core/form/FormRow';
import Select from '../core/form/Select';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { FLOW_RATE_TYPES } from '@/consts';

const FlowRateSelector = () => {
  const flowRate = useFlowRate();
  const defaultValues = { flowRate };
  const methods = useForm({ defaultValues });
  const changeFlow = e => caseActions.changeFlowRate(e.target.value);
  const { reset } = methods;

  useEffect(() => {
    reset({ flowRate });
  }, [flowRate, reset]);

  return (
    <FormProvider {...methods}>
      <div className="absolute rounded bg-base-100 border border-gray-20 right-0 p-2 pb-0 mt-2 mr-2">
        <form>
          <FormRow label="Flow Rate:" name="flowRate">
            <Select
              onChange={changeFlow}
              name="flowRate"
              registerOptions={{ valueAsNumber: true }}
              options={FLOW_RATE_TYPES}
            />
          </FormRow>
        </form>
      </div>
    </FormProvider>
  );
};

export default FlowRateSelector;
