const walkthroughSteps = {
  comparison: [
    {
      target: '.comparison-case-row',
      title: 'Make your selections',
      content: 'Comparisons always start with selecting pathways, modules and streams.',
      disableBeacon: true,
    },
    {
      target: '.comparison-case-name',
      title: 'Name each option',
      content: 'Comparisons always start with two pathways. To keep things organized give each option a unique name.',
      disableBeacon: true,
    },
    {
      target: '.add-comparison-case',
      title: 'Add additional streams',
      content: 'Want to compare more than 2 streams? Add up to 10 streams for comparison.',
      disableBeacon: true,
    },
  ],

  modules: [
    {
      target: '.module-categories',
      title: 'Module Categories',
      content: 'You can find a variety of modules by sorting through the listed module categories.',
      disableBeacon: true,
    },
    {
      target: '.module-search',
      title: 'Module Search',
      content: 'You can also find modules by using the search bar or selection dropdown.',
      disableBeacon: true,
    },
    {
      target: '.module-selection',
      title: 'Module Selection',
      content: 'Select a module by hovering, clicking and dragging it down to the canvas area below.',
      disableBeacon: true,
    },
    {
      target: '.module-palette-control',
      title: 'Module Palette Controls',
      content: 'You can minimize the module palette at any point by clicking on this icon.',
      disableBeacon: true,
    },
  ],
};

export default walkthroughSteps;
