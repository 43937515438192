import PropTypes from 'prop-types';
import SummaryItem from '../core/SummaryItem';

const COACComparisonSummary = ({ minTotal, maxTotal, unit, errors }) => {
  const [minName, minValue] = minTotal;
  const [maxName, maxValue] = maxTotal;
  const verb = errors.length > 1 ? ['are', 'they have'] : ['is', 'it has'];

  return (
    <div className="flex flex-col gap-10">
      <SummaryItem name={minName} value={minValue} unit={unit} label="Lowest cost of avoided carbon" color="#E9E8A7" />
      <SummaryItem name={maxName} value={maxValue} unit={unit} label="Highest cost of avoided carbon" color="#D1CD54" />
      {errors.length > 0 && (
        <p className="text-sm">
          {errors.join(', ')} {verb[0]} not decarbonization solutions since {verb[1]} larger emissions and costs than
          the baseline.
        </p>
      )}
    </div>
  );
};

COACComparisonSummary.propTypes = {
  minTotal: PropTypes.array,
  maxTotal: PropTypes.array,
  unit: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default COACComparisonSummary;
