import { Handle } from '@xyflow/react';
import PropTypes from 'prop-types';
import {
  caseActions,
  useAnchorId,
  useBalancedPortValue,
  useCostOverridesByNodeAndName,
  useIsBalanced,
} from '@/stores/caseStore';
import { memo } from 'react';
import { useIsIOConnected } from '@/stores/pathwayStore';
import BalancedValues from './BalancedValues';
import CostOverrideValue from './CostOverrideValue';
import WarningButton from './WarningButton';

const handleTypes = {
  input: 'target',
  output: 'source',
};

const InputOutputPort = ({ parentId, nodeName, unit, label, handleType, handlePosition, anchorable }) => {
  const handleId = `${parentId}_${handleType}_${nodeName}`;
  const anchorId = useAnchorId();
  const balancedValues = useBalancedPortValue(parentId, handleType, nodeName);
  const costOverride = useCostOverridesByNodeAndName(parentId, nodeName);
  const isBalanced = useIsBalanced();
  const isAnchored = handleId === anchorId;
  const isConnected = useIsIOConnected(handleId);
  const isInput = handleType === 'input';
  const showWarning = !isConnected && isInput && !isAnchored && isBalanced;
  const showBalancedValues = isBalanced && balancedValues && (!isConnected || isAnchored);
  const position = handlePosition === 'right' ? 'right' : 'left';
  const classes = handlePosition === 'right' ? 'ml-2 justify-end text-right' : 'mr-2 justify-start text-left';
  const subClasses = handlePosition === 'right' ? 'rounded-l-xl' : 'rounded-r-xl';
  const backgroundClass = isAnchored ? 'bg-blue-200' : showWarning ? 'bg-yellow-50' : 'bg-gray-100';
  const hoverClass = !anchorable ? 'bg-gray-100' : 'hover:bg-gray-300';

  const openAnchorForm = e => {
    e.stopPropagation();

    if (anchorable) {
      caseActions.setTempAnchor({ id: parentId, name: nodeName, label, handle: handleId, handleType, unit });
    }
  };

  return (
    <div className="mb-4">
      <div className={`relative flex items-center gap-1 ${classes}`}>
        <div
          className={`flex flex-col p-1 pl-6 pr-6 ${backgroundClass} ${subClasses} ${hoverClass}`}
          onClick={openAnchorForm}
        >
          <div className="flex">
            <span>{label}</span>
          </div>
          {showBalancedValues && <BalancedValues values={balancedValues} />}
        </div>
        {showWarning && <WarningButton />}
        <Handle
          className={`${position} border-2 bg-gray-200 border-black h-4 w-4 hover:bg-gray-100`}
          type={handleTypes[handleType]}
          position={handlePosition}
          id={handleId}
        />
      </div>
      {costOverride && <CostOverrideValue costOverride={costOverride} />}
    </div>
  );
};

InputOutputPort.propTypes = {
  parentId: PropTypes.string,
  nodeName: PropTypes.string,
  label: PropTypes.string,
  handleType: PropTypes.string,
  handlePosition: PropTypes.string,
  unit: PropTypes.object,
  anchorable: PropTypes.bool,
  nodeId: PropTypes.string,
};

const MemoizedInputOutputPort = memo(InputOutputPort);

export default MemoizedInputOutputPort;
