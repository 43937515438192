import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const CashFlowBarChart = ({ data, labels, yAxisLabel }) => {
  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: data.map(value => (value < 0 ? '#F6CFC6' : '#BFEEDD')),
        borderColor: data.map(value => (value < 0 ? '#F6CFC6' : '#BFEEDD')),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maxBarThickness: 70,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      yAxisLabel: {
        enabled: true,
        text: yAxisLabel,
      },
    },
    layout: {
      padding: {
        top: 70,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
        },
        border: {
          dash: ctx => {
            return ctx.tick.value === 0 ? [] : [4, 4];
          },
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Year',
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

CashFlowBarChart.propTypes = {
  data: PropTypes.array,
  labels: PropTypes.array,
  yAxisLabel: PropTypes.string,
};

export default CashFlowBarChart;
