import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useState } from 'react';
import { zipObject } from '@/utils/miscUtils';
import { useLazyEffect } from '@/hooks/useLazyEffect';
import { useSystemId } from '@/stores/systemStore';
import { getDerivedValue } from '@/api/systems';
import TimeSeriesResult from '@/components/systems/results/v2/TimeSeriesResult';
import { LINE_CHART } from '@/consts';

const HourlyTimeSeriesField = ({ param, name }) => {
  const { dependencies } = param;
  const { control } = useFormContext();
  const values = useWatch({ control, name: dependencies });
  const dependencyValues = zipObject(dependencies, values);
  const systemId = useSystemId();
  const [results, setResults] = useState(null);

  useLazyEffect(
    () => {
      const fetchDerivedValue = async () => {
        const { data } = await getDerivedValue(systemId, name, dependencyValues);
        setResults(data);
      };

      fetchDerivedValue();
    },
    [name, values],
    300,
  );

  return (
    <div className="w-full">
      {results && (
        <TimeSeriesResult
          useContainer={false}
          initPlot={name}
          initUnit={results?.unit}
          chartType={LINE_CHART}
          results={{ [name]: results }}
        />
      )}
    </div>
  );
};

HourlyTimeSeriesField.propTypes = {
  param: PropTypes.object,
  name: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.bool]),
  watchedFields: PropTypes.object,
};

export default HourlyTimeSeriesField;
