import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useAnalysis } from '@/stores/analysisStore';
import { exportCaseDataUrl } from '@/api/cases';

const ExportButton = () => {
  const analysis = useAnalysis();

  return (
    <a
      href={exportCaseDataUrl(analysis.caseId)}
      target="_blank"
      rel="noreferrer"
      className="absolute right-5 top-5 btn btn-sm btn-secondary"
    >
      <ArrowDownTrayIcon className="w-5 h-5" />
      Export Data
    </a>
  );
};

export default ExportButton;
