import SettingsList from './SettingsList';
import { Outlet, useLoaderData } from 'react-router-dom';

const SettingsRoute = () => {
  const user = useLoaderData();

  return (
    <div className="flex flex-col bg-white shadow-lg shadow-slate-200 rounded-t-lg size-11/12 h-full overflow-y-scroll">
      <div className="flex m-4">
        <div className="avatar placeholder">
          <div className="bg-neutral text-neutral-content rounded-full w-12">
            <span className="text-xl">{user.name[0]}</span>
          </div>
        </div>
        <div className="flex-1 ml-4">
          <div>{user.name}</div>
          <div>{user.email}</div>
        </div>
      </div>
      <hr />
      <div className="flex">
        <div className="w-64 relative flex flex-col">
          <SettingsList />
        </div>

        <div className="w-full flex flex-col m-4 ml-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsRoute;
