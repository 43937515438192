import { settingsActions, useTheme } from '@/stores/settingsStore';

const TextSizePicker = () => {
  const currentTheme = useTheme();
  const changeTheme = event => {
    const theme = event.target.value;
    settingsActions.updateTheme(theme);
  };

  return (
    <div className="w-64">
      <label className="text-sm">Text Size</label>
      <select
        name="theme"
        defaultValue={currentTheme}
        className="select select-bordered w-full select-sm max-w-xs"
        onChange={changeTheme}
      >
        <option value="sesame-xsmall">Extra Small</option>
        <option value="sesame-small">Small (default)</option>
        <option value="sesame-medium">Medium</option>
        <option value="sesame-large">Large</option>
        <option value="sesame-xlarge">Extra Large</option>
      </select>
    </div>
  );
};

export default TextSizePicker;
