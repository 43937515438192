import PropTypes from 'prop-types';
import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/24/outline';
import { comparisonActions } from '@/stores/comparisonStore';
import { confirm } from '@/components/core/createConfirmation';

export const ComparisonCaseActions = ({ comparisonCase }) => {
  const remove = async () => {
    if (await confirm('Are you sure you want to remove this comparison case?')) {
      comparisonActions.removeComparisonCase(comparisonCase);
    }
  };

  const clone = () => comparisonActions.cloneComparisonCase(comparisonCase);

  return (
    <div className="flex">
      <button onClick={clone}>
        <DocumentDuplicateIcon className="h-5 w-5 mr-1 text-zinc-400 hover:text-black" />
      </button>
      <button onClick={remove}>
        <TrashIcon className="h-5 w-5 mr-1 text-zinc-400 hover:text-black" />
      </button>
    </div>
  );
};

ComparisonCaseActions.propTypes = {
  comparisonCase: PropTypes.object,
};
