import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { toast, Zoom } from 'react-toastify';

// eslint-disable-next-line react/prop-types
const WarningMessage = ({ closeToast }) => {
  return (
    <div className="flex gap-4 bg-yellow-50 text-black prose prose-sm">
      <div>
        <b>
          <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" />
        </b>
      </div>
      <div className="flex flex-col">
        <b>Warning: Unconnected Module Input</b>
        <p>
          When a module input is left unconnected, Sesame will use the costs and emissions associated with the relevant
          feedstock module. This can lead to unexpected or incorrect analysis results (e.g. using a low upstream gas
          price, rather than a delivered cost when analyzing power plant costs). Please connect and configure a
          feedstock module to fully specify the pathway.
        </p>
        <div className="flex gap-4 font-bold">
          <button className="btn btn-ghost btn-sm hover:bg-yellow-300" onClick={closeToast}>
            Dismiss
          </button>
        </div>
      </div>
    </div>
  );
};

const WarningButton = () => {
  const showMessage = () => {
    toast(WarningMessage, {
      bodyClassName: 'bg-yellow-50',
      className: '!bg-yellow-50 !w-full !max-w-lg',
      position: 'bottom-center',
      transition: Zoom,
      closeButton: false,
      autoClose: false,
    });
  };

  return (
    <button onClick={showMessage} className="text-yellow-500 hover:text-yellow-600">
      <ExclamationTriangleIcon className=" h-8 w-8" />
    </button>
  );
};

export default WarningButton;
