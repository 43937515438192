import { convert } from './unitUtils';

export const conditionalRules = {
  param_equal_to: conditionalValue => currentValue => conditionalValue === currentValue,
  param_not_equal_to: conditionalValue => currentValue => conditionalValue !== currentValue,
};

export const validateConditionals = (conditionals, fields = {}) => {
  for (let key in conditionals) {
    const conditional = conditionals[key];
    if (!conditional(fields[key])) {
      return false;
    }
  }

  return true;
};

// Conditionals can be attached to:
// - parameters,
// - parameter defaults
// - options
export const buildConditionals = (conditionals = [], prefix = '', conversionParams, baseConversion) => {
  const pickedConditionals = {};
  conditionals.forEach(({ name, args }) => {
    const rule = conditionalRules[name];
    const [fieldName, fieldValue] = args;

    if (rule) {
      let value = fieldValue;
      const conversion = conversionParams?.[fieldName] ? JSON.parse(conversionParams[fieldName]) : null;

      if (conversion) {
        // convert from base to a new conversion factor
        value = convert(baseConversion, conversion, fieldValue);
      }

      const name = combineWithPrefix([fieldName], prefix).pop();
      pickedConditionals[name] = rule(value);
    }
  });

  return pickedConditionals;
};

export const validateConditionalOptions = (options, conditionals, fields = {}) => {
  const validOptions = [];

  options.forEach((option, index) => {
    if (validateConditionals(conditionals?.[index], fields)) {
      validOptions.push(option.value);
    }
  });

  return validOptions;
};

export const getCondNames = conditionals => {
  return (
    conditionals?.map(conditional => {
      const fieldName = conditional?.args?.[0];

      return fieldName;
    }) ?? []
  );
};

export const combineWithPrefix = (names, prefix) => {
  const prefixSegments = prefix.split('.').filter(segment => isNaN(segment));

  return names.map(name => {
    const nameSegments = name.split('.');
    const startsWithPrefix = prefixSegments.every((segment, index) => segment === nameSegments[index]);

    if (startsWithPrefix) {
      const remainingName = nameSegments.slice(prefixSegments.length).join('.');
      return prefix + remainingName;
    }

    return name;
  });
};

export const parseConditionalNames = (param, prefix = '') => {
  const { conditionals, defaults, options } = param;
  const optionsConditionals = options?.map(el => getCondNames(el.conditionals))?.flat() ?? [];
  const defaultConditionals = defaults?.map(el => getCondNames(el.conditionals))?.flat() ?? [];
  let names = [...getCondNames(conditionals), ...optionsConditionals, ...defaultConditionals].flat();

  if (prefix) {
    names = combineWithPrefix(names, prefix);
  }

  return [...new Set(names)];
};

export const processConditionals = (conditionals, prefix = '', conversionParams, baseConversion, values) => {
  const fieldConds = buildConditionals(conditionals, prefix, conversionParams, baseConversion);
  return validateConditionals(fieldConds, values);
};

export const filterParamsByConditionals = (params, values) => {
  return params.filter(param => processConditionals(param.conditionals, '', {}, param.baseConversion, values));
};
