import { buildValidators } from './validators';
import { isDefined, removeEmpty, roundValue, isObject, keyBy } from './miscUtils';
import { processConditionals } from './conditionalUtils';
import { convert, findConversionByUnit } from './unitUtils';

const convertDefaultValue = (defaultValue, baseConversion, conversion) => {
  if (isDefined(conversion) && conversion?.factor != 1 && isDefined(defaultValue)) {
    return convert(baseConversion, conversion, defaultValue);
  }

  return defaultValue;
};

export const getDefaultValue = (param, watchedFields, conversionParams, prefix = '') => {
  const { defaults, baseConversion, conversion } = param;

  for (let i = 0; i < defaults.length; i++) {
    const defaultData = defaults[i];

    // if not conditionals are present just return default value
    if (!defaultData?.conditionals?.length) {
      const defaultValue = defaultData?.value;

      if (Array.isArray(defaultValue)) {
        const paramsByName = keyBy(param.params, 'name');

        return defaultValue.map(val => {
          const result = {};
          for (const key in val) {
            const param = paramsByName[key];
            const { baseConversion, conversion } = param;
            const value = val?.[key]?.scalar ?? val?.[key];

            result[key] = convertDefaultValue(value, baseConversion, conversion);
          }

          return result;
        });
      }

      return convertDefaultValue(defaultValue, baseConversion, conversion);
    }

    const { conditionals } = defaultData;
    const defaultsCondMet = processConditionals(conditionals, prefix, conversionParams, baseConversion, watchedFields);

    if (defaultsCondMet) {
      const defaultValue = defaultData?.value;
      return convertDefaultValue(defaultValue, baseConversion, conversion);
    }
  }
};

export const prepareMcParams = paramsData => {
  const { mc_params } = paramsData;
  const mcParams = Object.keys(mc_params || {}).reduce((acc, key) => {
    if (isDefined(mc_params[key]?.min) && isDefined(mc_params[key]?.max)) {
      acc[key] = mc_params[key];
    }
    return acc;
  }, {});
  const cleanedMcParams = removeEmpty(mcParams);
  delete cleanedMcParams._enabled;

  return Object.keys(cleanedMcParams).length ? cleanedMcParams : null;
};

export const prepareConversionParamArray = (params, conversionParams) => {
  return params.map((param, index) => {
    if (conversionParams[index]) {
      return prepareConversionParams(param, conversionParams[index]);
    } else {
      return param;
    }
  });
};

export const prepareConversionParams = (params, conversionParams = {}) => {
  return Object.keys(params || {}).reduce((acc, key) => {
    if (Array.isArray(params[key])) {
      acc[key] = prepareConversionParamArray(params[key], conversionParams[key]);
    } else {
      if (conversionParams[key]) {
        const { unit } = JSON.parse(conversionParams[key]);
        acc[key] = {
          scalar: params[key],
          unit,
        };
      } else {
        acc[key] = params[key];
      }
    }

    // handle group params
    if (params?.[key]?.conversion) {
      const { conversion, ...nestedParams } = params[key];
      acc[key] = prepareConversionParams(nestedParams, conversion);
    }
    return acc;
  }, {});
};

// loop through the params again and apply default values based on the conditionals
export const applyConditionalDefaults = params => {
  const conversionParams = getConversionParams(params);
  const currentValues = getDefaultValues(params);

  params.forEach(param => {
    const { name } = param;
    const initDefaultValue = getDefaultValue(param, currentValues, conversionParams);
    const currentValue = currentValues?.[name];
    let defaultValue = isDefined(currentValue) ? currentValue : initDefaultValue;

    param.defaultValue = defaultValue;
  });
};

const getConversionParams = params => {
  return params.reduce((acc, param) => {
    const { conversion, name } = param;

    if (conversion) {
      acc[name] = JSON.stringify(conversion);
    }

    return acc;
  }, {});
};

export const parseParams = (params, currentValues) => {
  return params.map(param => {
    const { name, options, type, mc_eligible = false } = param;
    const validators = buildValidators(param.validators);
    const defaults = structuredClone(param.defaults);

    if (!defaults?.length) {
      if (type === 'options') {
        defaults[0] = { value: options?.[0]?.value };
      }

      if (type === 'boolean') {
        defaults[0] = { value: false };
      }
    }

    const initDefaultValue = getDefaultValue(param, currentValues);
    const caseValue = currentValues?.[name];
    let defaultValue = isDefined(caseValue) ? caseValue : initDefaultValue;

    const parsedParam = {
      ...param,
      validators,
      mc_eligible,
      defaultValue: structuredClone(defaultValue),
      initValidators: param.validators,
      defaults,
    };

    if (Array.isArray(parsedParam.defaultValue)) {
      parsedParam.defaultValue.forEach((val, index) => {
        for (const key in val) {
          parsedParam.defaultValue[index][key] = val?.[key]?.scalar ?? val?.[key];
        }
      });

      if (Array.isArray(caseValue)) {
        const conversions = new Array(parsedParam.defaultValue.length).fill({});
        conversions.forEach((_val, index) => {
          const conversion = {};
          for (const key in caseValue?.[index]) {
            if (caseValue?.[index]?.[key]?.unit) {
              conversion[key] = caseValue?.[index]?.[key];
            }
          }
          conversions[index] = conversion;
        });

        parsedParam.conversions = conversions;
      }
    } else if (isObject(caseValue) && caseValue.unit) {
      const conversion = findConversionByUnit(param.conversions, caseValue.unit);
      parsedParam.conversion = conversion ?? { unit: caseValue.unit, factor: 1 };
      parsedParam.defaultValue = caseValue.scalar;
    }

    if (mc_eligible) {
      parsedParam.mcValues = {
        min: currentValues?.[`mc_params.${name}.min`],
        max: currentValues?.[`mc_params.${name}.max`],
      };
    }

    if (param.params) {
      parsedParam.params = parseParams(param.params, currentValues?.[name]);
    }

    return parsedParam;
  });
};

export const calculateDistributionRange = (param, value, conversion) => {
  const { mc_distribution = {}, initValidators, validators, baseConversion } = param;

  const { min_deviation = 0, max_deviation = 0 } = mc_distribution;
  let min = +((1 - min_deviation) * value).toFixed(7);
  let max = +((1 + max_deviation) * value).toFixed(7);

  if (!initValidators) return { min, max };

  initValidators.map(({ name, args }) => {
    if (/gte|gt/.test(name)) {
      const validateMin = validators[name](min);
      if (validateMin !== true) {
        min = conversion ? convert(baseConversion, conversion, args[0]) : args[0];
      }
    } else if (/lte|lt/.test(name)) {
      const validateMax = validators[name](max);

      if (validateMax !== true) {
        max = conversion ? convert(baseConversion, conversion, args[0]) : args[0];
      }
    } else if (/integer/.test(name)) {
      const validateMin = validators[name](min);
      if (validateMin !== true) {
        min = roundValue(min, 0);
      }
      const validateMax = validators[name](max);
      if (validateMax !== true) {
        max = roundValue(max, 0);
      }
    }
  });

  return {
    min,
    max,
  };
};

export const prepareFormData = (currentParams, currentValues) => {
  const params = parseParams(currentParams, currentValues);
  const categories = parseCategories(params);

  applyConditionalDefaults(params);

  const defaultValues = getDefaultValues(params);

  return {
    params,
    categories,
    defaultValues,
  };
};

// group params by top level categories
export const parseCategories = (params, level = 0) => {
  return params.reduce((acc, param) => {
    const categoryName = param?.category?.[level] ?? '';

    if (!acc[categoryName]) {
      acc[categoryName] = { categoryName, params: [] };
    }

    acc[categoryName].params.push(param);
    return acc;
  }, {});
};

export const getCategories = params => {
  const categorySet = params.reduce((acc, param) => {
    const categoryName = param?.category?.[0];

    if (categoryName) {
      acc.add(categoryName);
    }

    return acc;
  }, new Set());

  return Array.from(categorySet);
};

export const getDefaultValues = params => {
  const defaultValues = {};

  params.forEach(param => {
    const { name, defaultValue, mc_eligible, mcValues, conversion, conversions, params: nestedParams } = param;

    if (nestedParams) {
      if (!Array.isArray(defaultValue)) {
        const nestedDefaultValues = getDefaultValues(nestedParams);
        defaultValues[name] = nestedDefaultValues;
      } else {
        const nestedDefaultValues = getDefaultValues(nestedParams);

        if (!nestedDefaultValues.conversion) {
          defaultValues[name] = defaultValue;
          return;
        }

        if (!defaultValues.conversion) {
          defaultValues.conversion = {};
        }

        defaultValues.conversion[name] = [];

        defaultValue.forEach((val, index) => {
          if (conversions?.[index]) {
            const defaultConversion = nestedDefaultValues.conversion;

            for (const key in defaultConversion) {
              if (conversions[index][key]) {
                const conversionByUnit = findConversionByUnit(
                  param.paramConversions[key].conversions,
                  conversions[index][key].unit,
                );
                if (conversionByUnit) {
                  defaultConversion[key] = JSON.stringify(conversionByUnit);
                }
              }
            }
          }

          defaultValues.conversion[name].push(nestedDefaultValues.conversion);
        });

        defaultValues[name] = defaultValue;
      }
    } else {
      defaultValues[name] = defaultValue;
    }

    if (mc_eligible) {
      if (!defaultValues.mc_params) {
        defaultValues.mc_params = {};
      }

      defaultValues.mc_params[name] = {
        min: mcValues.min,
        max: mcValues.max,
      };
    }

    if (conversion) {
      if (!defaultValues.conversion) {
        defaultValues.conversion = {};
      }

      defaultValues.conversion[name] = JSON.stringify(conversion);
    }
  });

  return defaultValues;
};

export const setConversions = (param, paramUnits) => {
  if (paramUnits?.[param.name] && !param?.params) {
    const { base_unit, default_conversion, conversions } = paramUnits?.[param.name] ?? {};
    const baseConversion = { unit: base_unit, factor: 1 };
    const conversion = default_conversion ?? baseConversion;
    param.conversions = [conversion, ...conversions];
    param.conversion = conversion;
    param.baseConversion = baseConversion;
  }

  // handle subparams
  if (param?.params?.length) {
    param.params.forEach(subParam => setConversions(subParam, paramUnits?.[param.name]?.params));
    param.paramConversions = paramUnits?.[param.name]?.params;
  }
};
