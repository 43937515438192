import PropTypes from 'prop-types';
import DoughnutChart from '@/components/charts/DoughnutChart';

const DoughnutSummaryItem = ({ name, label, data, labels, maxScope }) => {
  return (
    <div className="flex gap-7">
      <div className="max-w-28 relative">
        <DoughnutChart data={{ data, labels }} />
        <div className="absolute left-1/2 -translate-x-1/2 top-1/3 -translate-y-1/3 text-center">
          <div className="text-xs">{maxScope.label}</div>
          <div className="text-lg font-bold leading-none">{maxScope.proportion.toFixed(0)}%</div>
        </div>
      </div>
      <div className="flex flex-col gap-1 self-center">
        <div className="font-bold text-sm">{name}</div>
        <div className="text-sm">{label}</div>
      </div>
    </div>
  );
};

DoughnutSummaryItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  labels: PropTypes.array,
  maxScope: PropTypes.object,
};

export default DoughnutSummaryItem;
