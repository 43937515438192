import { useMemo } from 'react';
import { useEmail } from '@/stores/userStore';
import { useRouteLoaderData } from 'react-router-dom';

const useIsAdmin = () => {
  const {
    project: { id, members },
  } = useRouteLoaderData('project');
  const email = useEmail();

  const isAdmin = useMemo(() => {
    return members.some(member => member.email === email && member.role === 'admin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return isAdmin;
};

export default useIsAdmin;
