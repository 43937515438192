import ErrorMessage from '@/components/parameters/fields/ErrorMessage';
import { FormProvider, useForm } from 'react-hook-form';
import userApi from '@/api/user';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { toast } from 'react-toastify';

const defaultValues = {};

const ChangePasswordForm = () => {
  const methods = useForm({ defaultValues });
  const [pwVisible, setPwVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = methods;

  const submit = async data => {
    try {
      await userApi.updatePassword(data);
      toast.success('Password has been changed successfully');
      reset();
    } catch (err) {
      if (err?.response?.data) {
        for (const fieldName in err?.response?.data) {
          setError(fieldName, { message: err?.response?.data[fieldName] });
        }
      }
    }
  };

  const togglePassword = event => {
    event.preventDefault();
    setPwVisible(!pwVisible);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className="mt-7 w-80">
        <div className="mb-4">
          <input
            {...register('current_password', { required: true })}
            type="password"
            name="current_password"
            placeholder="Enter current password"
            className="input input-sm input-bordered w-full"
          />
          {errors.current_password?.message && <ErrorMessage text={errors.current_password?.message.toString()} />}
        </div>
        <div className="join w-full">
          <div className="flex-1">
            <input
              {...register('new_password', { required: true })}
              type={pwVisible ? 'text' : 'password'}
              name="new_password"
              placeholder="Enter new password"
              className="input input-sm join-item input-bordered w-full"
            />

            {errors.new_password?.message && <ErrorMessage text={errors.new_password?.message.toString()} />}
          </div>
          <div className="join-item">
            <button onClick={togglePassword} className="btn btn-sm">
              {pwVisible ? <EyeIcon className="w-5 h-5" /> : <EyeSlashIcon className="w-5 h-5" />}
            </button>
          </div>
        </div>
        <div className="modal-action">
          <button type="submit" className="btn btn-primary btn-sm">
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ChangePasswordForm;
