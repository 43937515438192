import PropTypes from 'prop-types';
import { useParams, useNavigate, useSubmit } from 'react-router-dom';
import ActionsDropdown from '../core/ActionsDropdown';
import { confirm } from '@/components/core/createConfirmation';
import { lockPathway, unlockPathway } from '@/api/pathways';
import { resourcesActions } from '@/stores/resourcesStore';

const PathwayActionsDropdown = ({ pathway, isAdmin }) => {
  const { projectId, pathwayId } = useParams();
  const navigate = useNavigate();
  const submit = useSubmit();

  const actionPath = pathwayId
    ? `/projects/${projectId}/build/pathways/${pathway.id}`
    : `/projects/${projectId}/dashboard/pathways/${pathway.id}`;
  const remove = async () => {
    if (await confirm('Are you sure you want to remove this pathway?')) {
      submit(pathway, {
        method: 'delete',
        action: `${actionPath}/destroy`,
      });
    }
  };

  const edit = pathway => {
    navigate(`${actionPath}/edit`, { state: { pathway } });
  };

  const duplicate = pathway => {
    navigate(`${actionPath}/duplicate`, { state: { pathway } });
  };

  const lock = async () => {
    const actionPath = pathwayId
      ? `/projects/${projectId}/build/pathways/${pathway.id}`
      : `/projects/${projectId}/dashboard`;
    await lockPathway(pathway.id);
    resourcesActions.updatePathway({ id: pathway.id, locked: true });
    navigate(actionPath, { replace: true });
  };

  const unlock = async () => {
    const actionPath = pathwayId
      ? `/projects/${projectId}/build/pathways/${pathway.id}`
      : `/projects/${projectId}/dashboard`;
    await unlockPathway(pathway.id);
    resourcesActions.updatePathway({ id: pathway.id, locked: false });
    navigate(actionPath, { replace: true });
  };

  const props = {
    ...(pathway.locked && { onUnlock: unlock }),
    ...(!pathway.locked && { onLock: lock }),
  };

  return (
    <ActionsDropdown
      {...(isAdmin && props)}
      onDelete={remove}
      onDuplicate={duplicate}
      onRename={edit}
      resource={pathway}
    />
  );
};

PathwayActionsDropdown.propTypes = {
  pathway: PropTypes.object,
  pathwayRef: PropTypes.object,
  isAdmin: PropTypes.bool,
};

export default PathwayActionsDropdown;
