import PropTypes from 'prop-types';

import { Doughnut } from 'react-chartjs-2';
import { useRef } from 'react';
import './plugins';

const defaultColors = ['#4977CE', '#ABC4FB', '#D4E1FD'];

const buildOptions = () => ({
  cutout: '80%',
  radius: '90%',
  responsive: true,
  elements: {
    arc: {
      spacing: 4,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
});

const DoughnutChart = ({ data }) => {
  const ref = useRef();
  const options = buildOptions();

  return (
    <Doughnut
      ref={ref}
      options={options}
      data={{ labels: data.labels, datasets: [{ data: data.data, backgroundColor: defaultColors }] }}
    />
  );
};

DoughnutChart.propTypes = {
  data: PropTypes.object,
};

export default DoughnutChart;
