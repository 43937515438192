import PropTypes from 'prop-types';

const AnalysisContainer = ({ title, children, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <p className="mt-8 ml-4 mr-2 mb-4 font-semibold text-lg">{title}</p>
      {children}
    </div>
  );
};

AnalysisContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AnalysisContainer;
