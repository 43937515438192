import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import CashFlowChartContainer from './CashFlowChartContainer';
import CashFlowDataGridContainer from './CashFlowDataGridContainer';
import { useCashFlowsPlot, useNodeById } from '@/stores/analysisStore';

const CashFlowsPlot = () => {
  const { results, nodeId } = useCashFlowsPlot();
  const node = useNodeById(nodeId);

  return (
    <AnalysisContainer title="Cash Flow Analysis" className="gap-3">
      <AnalysisBox>
        <CashFlowChartContainer data={results?.data} label={node?.label} />
      </AnalysisBox>

      <AnalysisBox>
        <CashFlowDataGridContainer />
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default CashFlowsPlot;
