import { Panel } from '@xyflow/react';
import WarningsButton from './WarningsButton';
import BalanceButton from './BalanaceButton';
import AnalysisButton from './AnalysisButton';
import OpenAnalysisButton from './OpenAnalysisButton';

const PathwayPanel = () => {
  return (
    <Panel className="mr-60 flex gap-4" position="bottom-right">
      <WarningsButton />
      <BalanceButton />
      <AnalysisButton />
      <OpenAnalysisButton />
    </Panel>
  );
};

export default PathwayPanel;
