import MembersList from '@/components/members/MembersList';
import { useRouteLoaderData, Outlet, Link } from 'react-router-dom';
import List from '@/components/core/List';
import GettingStartedPrompt from '@/components/core/GettingStartedPrompt';
import useIsAdmin from '@/hooks/useIsAdmin';
import PathwayRow from '@/components/pathways/PathwayRow';
import SystemRow from '@/components/systems/SystemRow';

const ProjectHomeRoute = () => {
  const {
    project: { id, members },
    pathways: { results: pathways },
    systems: { results: systems },
  } = useRouteLoaderData('project');

  const isAdmin = useIsAdmin();

  return (
    <div className="overflow-y-scroll">
      <GettingStartedPrompt />
      <div className="flex">
        <div className="w-2/3 p-4">
          <div className="flex items-center justify-between pb-2 border-b mr-6 border-gray-200">
            <p className="text-lg font-semibold"> Pathways ({pathways.length})</p>
            <Link to={`pathways/new`} className="btn btn-secondary btn-xs">
              Add Pathway
            </Link>
          </div>
          <List className="mt-2" items={pathways}>
            {(pathway, index) => <PathwayRow key={index} pathway={pathway} projectId={id} isAdmin={isAdmin} />}
          </List>

          <div className="flex items-center justify-between pb-2 border-b mr-6 border-gray-200 mt-6">
            <p className="text-lg font-semibold">Systems ({systems.length})</p>
            <Link to="systems/new" className="btn btn-secondary btn-xs">
              Add System
            </Link>
          </div>
          <List className="mt-2 mb-12" items={systems}>
            {(system, index) => <SystemRow key={index} system={system} projectId={id} />}
          </List>
        </div>
        <div className="w-1/3 p-4">
          <div className="flex items-center justify-between pb-2 mr-6 border-b border-gray-200">
            <div className="flex items-center">
              <p className="pr-2 text-lg font-semibold">Members ({members.length})</p>
            </div>
            <Link to="new-member" className="btn btn-secondary btn-xs">
              Add Member
            </Link>
          </div>
          <MembersList members={members} />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default ProjectHomeRoute;
