import { useTempAnchor } from '@/stores/caseStore';
import { useCaseId } from '@/stores/pathwayStore';
import { useEffect } from 'react';
import useAnchorStore, { anchorActions } from '@/stores/anchorStore';
import AnchorFormDialog from './AnchorFormDialog';

const AnchorContainer = () => {
  const tempAnchor = useTempAnchor();
  const caseId = useCaseId();
  const nodeId = tempAnchor?.id;
  const anchorInfo = useAnchorStore();

  // load node info
  useEffect(() => {
    if (caseId && nodeId) {
      anchorActions.loadInfo(caseId, nodeId);
    }
  }, [caseId, nodeId]);

  if (!tempAnchor || !anchorInfo) {
    return null;
  }

  return <AnchorFormDialog />;
};

export default AnchorContainer;
