import PropTypes from 'prop-types';
import { useNodeById, useSensitivityPlot } from '@/stores/analysisStore';
import { analysisActions } from '@/stores/analysisStore';
import TornadoChart from '@/components/charts/TornadoChart';
import SensitivityForm from './SensitivityForm';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import { getSensitivityXaxisLabel } from '@/utils/analysisUtils';

const SensitivityPlot = ({ type, label }) => {
  const plot = useSensitivityPlot(type);
  const { nodeId, results } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot(`sensitivity_${type}`, formData);

  const xAxisLabel = node ? getSensitivityXaxisLabel(plot, node, type) : '';

  return (
    <AnalysisContainer title={label}>
      <AnalysisBox>
        {results && (
          <TornadoChart
            xAxisLabel={xAxisLabel}
            datasets={results.datasets}
            labels={results.labels}
            baseline={results.baseline}
            type={type}
          />
        )}
        {node ? (
          <SensitivityForm onSubmit={updatePlot} defaultValues={plot} />
        ) : (
          <span className="loading loading-dots loading-md"></span>
        )}
      </AnalysisBox>
    </AnalysisContainer>
  );
};

SensitivityPlot.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};

export default SensitivityPlot;
