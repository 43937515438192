import SystemResults from '@/components/systems/SystemResults';
import { Suspense } from 'react';
import { Await, useLoaderData } from 'react-router-dom';

const SystemAnalysisRoute = () => {
  const data = useLoaderData();

  return (
    <Suspense fallback={<span className="m-6 loading loading-dots loading-md"></span>}>
      <Await resolve={data.promise}>
        <SystemResults />
      </Await>
    </Suspense>
  );
};

export default SystemAnalysisRoute;
