import PropTypes from 'prop-types';
import { Link, Form, useLoaderData, useActionData } from 'react-router-dom';

const EditComparisonsModal = ({ title }) => {
  const comparison = useLoaderData();
  const errors = useActionData();
  const name = comparison?.name ?? '';

  return (
    <dialog className="modal" open>
      <div className="modal-box">
        <Form method="put">
          <h3 className="font-bold text-lg">{title}</h3>
          <label className="form-control w-full">
            <div className="label">
              <span className="label-text">Name</span>
            </div>
            <input
              type="text"
              name="name"
              placeholder="Enter comparison name"
              className="input input-bordered input-sm w-full"
              defaultValue={name}
            />
            <input type="hidden" name="curName" defaultValue={name} />
            <div className="label">
              <span className="label-text-alt text-error">{errors?.name}</span>
            </div>
          </label>
          <div className="modal-action">
            <Link className="btn btn-sm btn-secondary" to={-1} replace>
              Cancel
            </Link>
            <button type="submit" className="btn btn-primary btn-sm">
              Save
            </button>
          </div>
        </Form>
      </div>
    </dialog>
  );
};

EditComparisonsModal.propTypes = {
  title: PropTypes.string,
};

export default EditComparisonsModal;
