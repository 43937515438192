import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

const NumberInputField = ({ name, rules, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <NumericFormat
          {...rest}
          getInputRef={ref}
          value={value}
          onValueChange={({ floatValue }) => onChange(floatValue)}
          fixedDecimalScale
          thousandSeparator
        />
      )}
    />
  );
};

NumberInputField.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
};

export default NumberInputField;
