import config from '@/config';
import axios from 'axios';

const { get, patch, post } = axios;

// comparisons
export const getComparisons = projectId => get(`/v1/projects/${projectId}/comparisons/`);
export const createComparison = (projectId, data) => post(`/v1/projects/${projectId}/comparisons/`, data);
export const getComparison = comparisonId => get(`/v1/comparisons/${comparisonId}/`);
export const patchComparison = (comparisonId, data) => patch(`/v1/comparisons/${comparisonId}/`, data);
export const deleteComparison = comparisonId => axios.delete(`/v1/comparisons/${comparisonId}/`);
export const exportComparisonsDataUrl = comparisonId => `${config.serverUrl}/v1/comparisons/${comparisonId}/export/`;

// comparison cases
export const createComparisonCase = (comparisonId, data = {}) =>
  post(`/v1/comparisons/${comparisonId}/comparison_cases/`, data);
export const patchComparisonCase = (caseId, data) => patch(`/v1/comparison_cases/${caseId}/`, data);
export const deleteComparisonCase = caseId => axios.delete(`/v1/comparison_cases/${caseId}/`);

export const getComparisonResults = (comparisonId, type, data, options = {}) =>
  post(`/v1/comparisons/${comparisonId}/${type}/`, data, options);
