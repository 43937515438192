import PropTypes from 'prop-types';

const SvgViewer = ({ x, y, width, height, src }) => (
  <svg viewBox={`${x} ${y} ${width} ${height}`}>
    <image xlinkHref={src} />
  </svg>
);

SvgViewer.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
};

export default SvgViewer;
