import PropTypes from 'prop-types';
import {
  TrashIcon,
  PencilSquareIcon,
  EllipsisVerticalIcon,
  DocumentDuplicateIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/24/outline';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useState } from 'react';

const ActionsDropdown = ({ onRename, onDuplicate, onDelete, onLock, onUnlock, resource, renameLabel = 'Rename' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  const rename = event => {
    event.preventDefault();
    onRename(resource);
  };

  const duplicate = event => {
    event.preventDefault();
    onDuplicate(resource);
  };

  const remove = event => {
    event.preventDefault();
    onDelete(resource);
  };

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  return (
    <div>
      <EllipsisVerticalIcon
        ref={refs.setReference}
        {...getReferenceProps({
          onClick(event) {
            event.preventDefault();
            event.stopPropagation();
          },
        })}
        role="button"
        className="h-5 w-5 float-right"
      />
      {isOpen && (
        <ul
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="border dropdown-content border-gray-75 z-[1002] menu p-2 shadow-md bg-base-100 rounded-sm"
        >
          <li>
            <button onClick={rename}>
              <PencilSquareIcon className="h-5 w-5 mr-1" />
              {renameLabel}
            </button>
          </li>
          {onDuplicate && (
            <li>
              <button onClick={duplicate}>
                <DocumentDuplicateIcon className="h-5 w-5 mr-1" />
                Duplicate
              </button>
            </li>
          )}
          {onLock && (
            <li>
              <button onClick={onLock}>
                <LockClosedIcon className="h-5 w-5 mr-1" />
                Lock
              </button>
            </li>
          )}
          {onUnlock && (
            <li>
              <button onClick={onUnlock}>
                <LockOpenIcon className="h-5 w-5 mr-1" />
                Unlock
              </button>
            </li>
          )}
          <li>
            <button onClick={remove}>
              <TrashIcon className="h-5 w-5 mr-1" />
              Delete
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

ActionsDropdown.propTypes = {
  resource: PropTypes.object,
  position: PropTypes.string,
  onRename: PropTypes.func,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  onLock: PropTypes.func,
  onUnlock: PropTypes.func,
  renameLabel: PropTypes.string,
};

export default ActionsDropdown;
