import CostBreakdownChart from '@/components/charts/CostBreakdownChart';
import { useTEAPlot, useNodeById } from '@/stores/analysisStore';
import { getTEAYaxisLabel } from '@/utils/analysisUtils';
import { unitToString } from '@/utils/unitUtils';
import Decimal from 'decimal.js';

const TEABreakdownPlot = () => {
  const plot = useTEAPlot();
  const { nodeId, feedstock_costs = [], upstreamCostsBreakdown } = plot;
  const node = useNodeById(nodeId);

  if (!upstreamCostsBreakdown) {
    return null;
  }

  const results = feedstock_costs.reduce((acc, item) => {
    // #TODO: remove this once we have a better way to identify these items
    if (/Stream Mixer|Stream Splitter|Power Infrastructure Emissions/.test(item.description)) {
      return acc;
    }

    const { description, levelized_contribution } = item;
    const { scalar, unit } = levelized_contribution;
    const value = new Decimal(scalar);

    const resultItem = {
      label: description,
      amount: value,
      unit: unitToString(unit),
    };
    acc.push(resultItem);

    return acc;
  }, []);

  const unit = getTEAYaxisLabel(plot, node, false);

  return (
    <CostBreakdownChart
      breakdownResults={results ?? []}
      title="TEA Cost Breakdown"
      yAxisLabel={`Feedstock Breakdown - ${node.label}\n[${unit}]`}
      digits={2}
      unit={unit}
    />
  );
};

export default TEABreakdownPlot;
