import PropTypes from 'prop-types';
import SummaryItem from '../core/SummaryItem';
import DoughnutSummaryItem from '../core/DoughnutSummaryItem';

const LCAComparisonSummary = ({ minTotal, maxTotal, maxProportions, unit }) => {
  const [minName, minValue] = minTotal;
  const [maxName, maxValue] = maxTotal;
  const [proportionName, scopes] = maxProportions;

  return (
    <div className="flex flex-col gap-10">
      <SummaryItem name={minName} value={minValue} unit={unit} label="Lowest carbon intensity" color="#E9E8A7" />
      <SummaryItem name={maxName} value={maxValue} unit={unit} label="Highest carbon intensity" color="#D1CD54" />
      {scopes.maxScope.proportion > 0 && (
        <DoughnutSummaryItem
          name={proportionName}
          label="Largest proportion of upstream emissions"
          data={scopes.data}
          labels={scopes.labels}
          maxScope={scopes.maxScope}
        />
      )}
    </div>
  );
};

LCAComparisonSummary.propTypes = {
  minTotal: PropTypes.array,
  maxTotal: PropTypes.array,
  maxProportions: PropTypes.array,
  unit: PropTypes.string,
};

export default LCAComparisonSummary;
