import PropTypes from 'prop-types';

const ProjectSelect = ({ defaultValue, options }) => {
  return (
    <label className="form-control w-full">
      <div className="label">
        <span className="label-text">Project</span>
      </div>
      <select name="project_id" className="select select-sm w-full select-bordered" defaultValue={defaultValue}>
        {options?.map((option, index) => (
          <option key={`${index}-${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};

ProjectSelect.propTypes = {
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default ProjectSelect;
