import { ToastContainer as ReactToastContainer } from 'react-toastify';

const contextClass = {
  success: 'bg-green-600',
  error: 'bg-red-600',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

const ToastContainer = () => (
  <ReactToastContainer
    autoClose={5000}
    theme="colored"
    newestOnTop
    hideProgressBar
    limit={1}
    position="bottom-right"
    toastClassName={context =>
      `${contextClass[context?.type || 'default']} bg-red-600' relative flex p-1 min-h-10 rounded justify-between overflow-hidden cursor-pointer`
    }
  />
);

export default ToastContainer;
