import PropTypes from 'prop-types';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import HorizontalStackedBarChart from '@/components/charts/HorizontalStackedBarChart';
import sortBy from 'just-sort-by';
import { CHART_COLORS } from '@/consts';
import { numberFormat } from '@/utils/unitUtils';
import { smartRound } from '@/utils/miscUtils';
import Decimal from 'decimal.js';

const colors = ['#BFEEDD', '#D4E1FD', '#ABC4FB', ...CHART_COLORS];

const CostBreakdownChart = ({ breakdownResults = [], yAxisLabel, title, unit, digits = 2 }) => {
  const amounts = breakdownResults.map(item => item.amount);
  const total = amounts.reduce((a, b) => a.plus(b), new Decimal(0));

  const results = breakdownResults.reduce((acc, item) => {
    const i = { ...item, percentage: !item.amount.isZero() ? (item.amount / total) * 100 : 0 };
    acc.push(i);
    return acc;
  }, []);

  const byPercentage = sortBy(results, 'percentage').reverse();
  const totalPercent = byPercentage.reduce((a, b) => a + b.percentage, 0);
  const rows = byPercentage.map(item => item.percentage);
  const labels = byPercentage.map(item => item.label);

  return (
    <AnalysisContainer title={title}>
      <AnalysisBox>
        <div className="grid grid-cols-2 gap-8">
          {breakdownResults && (
            <HorizontalStackedBarChart colors={colors} rows={rows} labels={labels} yAxisLabel={`${yAxisLabel}`} />
          )}
          <div className="prose min-w-full">
            <table className="w-full text-xs">
              <thead>
                <tr className="border-b border-black leading-loose text-left">
                  <th></th>
                  <th>Feedstock Item</th>
                  <th>Percentage</th>
                  <th className="text-right">{unit}</th>
                </tr>
              </thead>
              <tbody>
                {byPercentage.map(({ label, amount, percentage }, index) => (
                  <tr key={`${label}-${index}`} className="leading-loose">
                    <td style={{ backgroundColor: colors[index] }} className="ml-2 w-2"></td>
                    <td className="pl-2">{label}</td>
                    <td>{percentage && smartRound(percentage)}%</td>
                    <td className="text-right">{amount && numberFormat(amount, digits)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="font-bold">
                <tr className="text-left">
                  <td></td>
                  <td>Total</td>
                  <td>{totalPercent.toFixed(0)}%</td>
                  <td className="text-right">{numberFormat(total, digits)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </AnalysisBox>
    </AnalysisContainer>
  );
};

CostBreakdownChart.propTypes = {
  breakdownResults: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  digits: PropTypes.number,
};

export default CostBreakdownChart;
