import PropTypes from 'prop-types';
import OpenIcon from '@/assets/open.svg?react';
import CloseIcon from '@/assets/close.svg?react';

export const CollapsibleButton = ({ onClick, isOpen }) => (
  <button onClick={onClick} type="button" className="btn btn-sm border-gray-200">
    {isOpen ? (
      <>
        <CloseIcon /> Hide
      </>
    ) : (
      <>
        <OpenIcon /> Show
      </>
    )}
  </button>
);

CollapsibleButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CollapsibleButton;
