import PropTypes from 'prop-types';
import FormRow from '@/components/core/form/FormRow';
import { validateConditionalOptions } from '@/utils/conditionalUtils';
import { useFormContext } from 'react-hook-form';
import get from 'just-safe-get';
import ErrorMessage from './ErrorMessage';
import { buildConditionals } from '@/utils/conditionalUtils';
import useSetDefaultValue from '../useSetDefaultValue';
import ConversionField from '../ConversionField';
import useConversion from '../useConversion';
import { isInteger, isNumber } from '@/utils/miscUtils';

const setValueAs = v => {
  if (isInteger(v)) {
    return parseInt(v);
  }

  if (isNumber(v)) {
    return parseFloat(v);
  }

  return v;
};

const SelectField = ({ param, name, prefix, watchedFields, defaultValue }) => {
  const { label, validators, tooltip, source, conversion, conversions, baseConversion } = param;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);
  const errorMessage = error?.message;

  const { validators: dynamicValidators, convertedOptions } = useConversion(param, name);
  const conditionals = convertedOptions?.map(option => buildConditionals(option.conditionals, prefix, baseConversion));
  const validOptions = validateConditionalOptions(convertedOptions, conditionals, watchedFields);

  const validate = { ...validators, ...dynamicValidators };

  const registration = register(name, {
    validate,
    setValueAs,
  });

  useSetDefaultValue(name, defaultValue, watchedFields, conversion);

  return (
    <FormRow label={label} name={name} tooltip={tooltip} source={source}>
      <>
        <select
          id={name}
          name={name}
          {...registration}
          className={`select select-bordered select-sm w-full ${errorMessage ? 'input-error' : ''}`}
        >
          {validOptions.map(option => (
            <option value={option} key={`${name}-${option}`}>
              {option}
            </option>
          ))}
        </select>
        {conversion && <ConversionField name={`conversion.${name}`} conversions={conversions} />}
        <ErrorMessage text={errorMessage} />
      </>
    </FormRow>
  );
};

SelectField.propTypes = {
  param: PropTypes.object,
  name: PropTypes.string,
  prefix: PropTypes.string,
  watchedFields: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SelectField;
