import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import PanelHeader from '../core/PanelHeader';
import { isEmpty } from '@/utils/miscUtils';
import ParameterFields from './ParameterFields';
import { VIEW_TYPES } from '@/consts';
import { useEffect } from 'react';

const ParameterForm = ({
  params,
  categories,
  actions,
  defaultValues,
  title,
  onSubmit,
  className = '',
  viewType = VIEW_TYPES.grid,
}) => {
  const methods = useForm({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
  } = methods;

  const onInnerSubmit = async data => {
    await onSubmit(data);
    reset(data);
  };

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onInnerSubmit)} noValidate className="flex flex-col min-h-0">
        <PanelHeader title={title}>
          <div className="flex mt-2">
            {actions}
            {isSubmitting ? (
              <button className="btn btn-sm mr-2 btn-secondary">
                <span className="loading loading-spinner"></span>
              </button>
            ) : (
              <button
                type="submit"
                className={`btn mr-2 btn-primary btn-sm ${isEmpty(dirtyFields) ? 'btn-disabled' : ''}`}
              >
                Save
              </button>
            )}
          </div>
        </PanelHeader>
        <ParameterFields params={params} categories={categories} className={className} viewType={viewType} />
      </form>
    </FormProvider>
  );
};

ParameterForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  actions: PropTypes.node,
  className: PropTypes.string,
  categories: PropTypes.object,
  viewType: PropTypes.string,
};

export default ParameterForm;
