import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { MapIcon } from '@heroicons/react/24/outline';
import SystemsIcon from '@/assets/react-icons/md/SystemsIcon.svg?react';
import { SYSTEM_STATUS } from '@/consts';
import ListHeader from '../core/ListHeader';
import ListItemLink from '../core/ListItemLink';

const SystemAnalysesList = ({ systems }) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const succeeded = systems.filter(system => system.status === SYSTEM_STATUS.succeeded);

  const goToSystem = (event, system) => {
    event.preventDefault();
    navigate(`/projects/${projectId}/build/systems/${system.id}`);
  };

  return (
    <div className="divide-y divide-gray-75">
      <ListHeader title="Systems" icon={SystemsIcon} />
      {succeeded.length > 0 && (
        <ul className="p-2">
          {succeeded.map(system => (
            <li key={system.id} className="group/item">
              <ListItemLink to={`/projects/${projectId}/analysis/systems/${system.id}`}>
                <span className="truncate">{system.name}</span>
                <div className="group-hover/item:flex absolute right-5 hidden bg-canvas">
                  <MapIcon onClick={event => goToSystem(event, system)} className="h-5 w-5" />
                </div>
              </ListItemLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SystemAnalysesList.propTypes = {
  project: PropTypes.object,
  systems: PropTypes.arrayOf(PropTypes.object),
};

export default SystemAnalysesList;
