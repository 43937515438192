import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import InputField from '../parameters/fields/InputField';
import useMcParams from '../parameters/useMcParams';
import { useEffect } from 'react';
import { isDefined } from '@/utils/miscUtils';

const MCFields = ({ param }) => {
  const { setValue } = useFormContext();
  const { min, max } = useMcParams(param);

  useEffect(() => {
    if (isDefined(min.defaultValue) && !isNaN(min.defaultValue)) {
      setValue(min.name, min.defaultValue);
    }

    if (isDefined(max.defaultValue) && !isNaN(max.defaultValue)) {
      setValue(max.name, max.defaultValue);
    }
  }, [min.defaultValue, max.defaultValue, setValue, min.name, max.name]);

  return (
    <div className="flex gap-4 items-start">
      <InputField
        param={{ label: 'Min', type: param.type, validators: min.validators }}
        name={min.name}
        defaultValue={min.distribution}
      />
      <InputField
        param={{ label: 'Max', type: param.type, validators: max.validators }}
        name={max.name}
        defaultValue={max.distribution}
      />
    </div>
  );
};

MCFields.propTypes = {
  param: PropTypes.object,
};

export default MCFields;
