import { useState, useCallback } from 'react';

export function useCollapsible({ initialState = false, isCollapsible = false }) {
  const [isOpen, setIsOpen] = useState(isCollapsible ? initialState : true);

  const toggle = useCallback(() => {
    if (isCollapsible) {
      setIsOpen(prev => !prev);
    }
  }, [isCollapsible]);

  return { isOpen, toggle, isCollapsible };
}

export default useCollapsible;
