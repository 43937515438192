import PropTypes from 'prop-types';

const List = ({ items, children, className = '' }) => (
  <table className={`w-full text-left text-sm ${className}`}>
    <tbody>{items.map((item, index, array) => children(item, index, array))}</tbody>
  </table>
);

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default List;
