import PropTypes from 'prop-types';
import { useIsBalanced, useBalanceWarnings } from '@/stores/caseStore';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

const WarningItem = ({ node, warnings = [] }) => (
  <div>
    <p>
      <strong>{node.label}</strong>
    </p>
    <ul>
      {warnings.map((w, index) => (
        <li key={`${node.id}-${index}`}>{w}</li>
      ))}
    </ul>
  </div>
);

WarningItem.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
  warnings: PropTypes.arrayOf(PropTypes.string),
};

const WarningModal = ({ data = [] }) => {
  return (
    <dialog id="warning_modal" className="modal">
      <div className="modal-box">
        <div className="flex mb-4 gap-4 pb-4 border-b border-gray-200">
          <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" />
          <h3 className="font-bold text-md">The following warnings were detected in this pathway</h3>
        </div>

        <div className="prose prose-sm">
          {data.map(({ node, warnings }) => (
            <WarningItem key={node.id} node={node} warnings={warnings} />
          ))}
        </div>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn btn-sm">Dismiss</button>
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};

WarningModal.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
      warnings: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

const WarningButton = () => {
  const isBalanced = useIsBalanced();
  const warnings = useBalanceWarnings();

  const showModal = () => {
    document.getElementById('warning_modal')?.showModal();
  };

  if (!isBalanced || warnings.length === 0) {
    return null;
  }

  return (
    <>
      <button onClick={showModal} className="text-yellow-500 hover:text-yellow-600">
        <ExclamationTriangleIcon className=" h-8 w-8" />
      </button>
      <WarningModal data={warnings} />
    </>
  );
};

export default WarningButton;
