import NodeModal from '@/components/nodes/NodeModal';
import { useParams } from 'react-router-dom';

const NodeRoute = () => {
  const { nodeId } = useParams();

  return <NodeModal key={nodeId} />;
};

export default NodeRoute;
