import PropTypes from 'prop-types';
import { CubeIcon } from '@heroicons/react/24/outline';

const EmptyState = ({ title, children }) => {
  return (
    <div className="flex flex-col items-center place-content-center h-full">
      <CubeIcon className="h-12 w-12 stroke-1 mb-4 stroke-orange-400" />
      <div className="font-small mb-4">{title}</div>
      <div className="flex flex-wrap gap-2">{children}</div>
    </div>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default EmptyState;
