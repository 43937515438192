import PropTypes from 'prop-types';
import markdownit from 'markdown-it';
import markdownitSup from 'markdown-it-sup';
import { useEffect, useRef } from 'react';
import katex from 'katex';
import mk from '@iktakahiro/markdown-it-katex';
import * as Sentry from '@sentry/react';

import 'katex/dist/katex.min.css';

const md = markdownit();
md.use(markdownitSup);
md.use(mk, { blockClass: 'katex-block' });

const MarkdownViewer = ({ content, className }) => {
  const containerRef = useRef(null);
  const classes = className ?? 'p-10 prose w-full max-w-max';

  useEffect(() => {
    if (!content) return;
    try {
      const container = containerRef.current;

      container.innerHTML = md.render(content);

      const mathElements = container.querySelectorAll('p');
      const linkElements = container.querySelectorAll('a');

      // this is only for backwards compatibility
      mathElements.forEach(element => {
        const latexFormula = element.textContent;
        if (latexFormula.startsWith('\\')) {
          // If the content starts with a backslash, it's a LaTeX formula
          katex.render(latexFormula.slice(1), element);
          element.classList.add('katex-block');
        }
      });

      linkElements.forEach(element => {
        element.target = '_blank';
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [content]);

  return <div className={classes} ref={containerRef}></div>;
};

MarkdownViewer.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};

export default MarkdownViewer;
