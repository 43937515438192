import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';
import LineChart from '@/components/charts/LineChart';
import { useLCAPlot, workbenchActions } from '@/stores/workbenchStore';
import WorkbenchPlotForm from '../WorkbenchPlotForm';
import { getLCAYaxisLabel } from '@/utils/workbenchUtils';

const LCAWorkbenchPlot = () => {
  const plot = useLCAPlot();
  const { node, paramLabels, results, workbenchUnits } = plot;
  const updatePlot = formData => {
    workbenchActions.updatePlot('lca', formData);
  };

  if (!results) {
    return;
  }

  const legendTitle = paramLabels[paramLabels.length - 1];
  const legendUnit = workbenchUnits[workbenchUnits.length - 1];

  return (
    <AnalysisContainer title="Life Cycle Analysis">
      <AnalysisBox>
        <LineChart
          legendTitle={`${legendTitle} (${legendUnit})`}
          results={results}
          label={`${paramLabels[0]} - ${node.label}`}
          yAxisLabel={getLCAYaxisLabel(plot, node)}
        />
        {node && <WorkbenchPlotForm onSubmit={updatePlot} defaultValues={plot} />}
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default LCAWorkbenchPlot;
