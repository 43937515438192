import PropTypes from 'prop-types';
import { TrashIcon } from '@heroicons/react/24/solid';
import { ScaleIcon } from '@heroicons/react/24/solid';
import { pathwayActions } from '@/stores/pathwayStore';
import { caseActions } from '@/stores/caseStore';
import useToggle from '@/hooks/useToggle';

const EdgePopover = ({ name, unit, label, anchorable, edgeId, sourceId, sourceHandle, targetHandle, children }) => {
  const { isOpen, toggle, open } = useToggle();

  const disconnectInputFromOutput = () => {
    pathwayActions.disconnectInputFromOutput({
      id: edgeId,
      sourceHandle,
      targetHandle,
    });
    toggle();
  };

  const openAnchorForm = () => {
    caseActions.setTempAnchor({ id: sourceId, name, unit, label, handle: sourceHandle, handleType: 'output' });
    toggle();
  };

  return (
    <>
      <div className="dropdown dropdown-top">
        <button onClick={open}>{children}</button>
        {isOpen && (
          <ul className="z-1000 dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
            <li>
              <button onClick={disconnectInputFromOutput}>
                <TrashIcon className="w-5 h-5" />
                Remove Connection
              </button>
            </li>
            {anchorable && (
              <li>
                <button onClick={openAnchorForm}>
                  <ScaleIcon className="w-5 h-5" />
                  Set as Basis
                </button>
              </li>
            )}
          </ul>
        )}
      </div>
    </>
  );
};

EdgePopover.propTypes = {
  edgeId: PropTypes.string,
  name: PropTypes.string,
  unit: PropTypes.object,
  label: PropTypes.string,
  sourceHandle: PropTypes.string,
  targetHandle: PropTypes.string,
  sourceId: PropTypes.string,
  anchorable: PropTypes.bool,
  children: PropTypes.node,
};

export default EdgePopover;
