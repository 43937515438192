import PropTypes from 'prop-types';
import SummaryItem from '../core/SummaryItem';

const TEAComparisonSummary = ({ minTotal, maxTotal, unit }) => {
  const [minName, minValue] = minTotal;
  const [maxName, maxValue] = maxTotal;

  return (
    <div className="flex flex-col gap-10">
      <SummaryItem name={minName} value={minValue} unit={unit} label="Least expensive" color="#A594F9" />
      <SummaryItem name={maxName} value={maxValue} unit={unit} label="Most expensive" color="#A594F9" />
    </div>
  );
};

TEAComparisonSummary.propTypes = {
  minTotal: PropTypes.array,
  maxTotal: PropTypes.array,
  unit: PropTypes.string,
};

export default TEAComparisonSummary;
