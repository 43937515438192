const dataSources = [
  {
    label: 'Electricity Projected Prices by U.S. Region',
    id: 'electricity_projected_prices_by_us_region',
  },
  {
    label: 'Electricity Retail Prices by U.S. Region',
    id: 'electricity_retail_prices_by_us_region',
  },
  {
    label: 'Wind and Solar Techno-Economics Projections U.S.',
    id: 'wind_and_solar_techno_economics_projections_us',
  },
  {
    label: 'Natural Gas Projected Prices by U.S. Region',
    id: 'natural_gas_projected_prices_by_us_region',
  },
  {
    label: 'Natural Gas Prices by U.S. Region',
    id: 'natural_gas_prices_by_us_region',
  },
  {
    label: 'Coal Prices by U.S. Region',
    id: 'coal_prices_by_us_region',
  },
  {
    label: 'Coal Heat Content by U.S. Region',
    id: 'coal_heat_content_by_us_region',
  },
  {
    label: 'Electricity Prices by Japan Region',
    id: 'electricity_prices_by_japan_region',
  },
  {
    label: 'Electricity Prices by E.U. Country',
    id: 'electricity_prices_by_eu_country',
  },
  {
    label: 'Natural Gas Prices by E.U. Country',
    id: 'natural_gas_prices_by_eu_country',
  },
  {
    label: 'Electricity Wholesale Prices by Australia Trading Hub',
    id: 'electricity_wholesale_prices_by_australia_trading_hub',
  },
  {
    label: 'Natural Gas Wholesale Prices by Australia Trading Hub',
    id: 'natural_gas_wholesale_prices_by_australia_trading_hub',
  },
  {
    label: 'Electricity Prices China',
    id: 'electricity_prices_china',
  },
  {
    label: 'Onshore Wind Total Installed Cost By Country',
    id: 'onshore_wind_total_installed_cost_by_country',
  },
  {
    label: 'Utility-Scale Solar PV Total Installed Cost By Country',
    id: 'utility_scale_solar_pv_total_installed_cost_by_country',
  },
  {
    label: 'Utility-Scale Solar PV Installed Cost Breakdown By Country 2022',
    id: 'utility_scale_solar_pv_installed_cost_breakdown_by_country_2022',
  },
  {
    label: 'Natural Gas Prices by Trading Hub (Global)',
    id: 'natural_gas_prices_by_trading_hub_global',
  },
  {
    label: 'Coal Prices by Global Region',
    id: 'coal_prices_by_global_region',
  },
];

export default dataSources;
