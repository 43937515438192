import PropTypes from 'prop-types';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';

const ListActions = ({ item, onRemove, onEdit }) => {
  const closeDropdown = () => {
    document?.activeElement?.blur();
  };

  const edit = event => {
    closeDropdown();
    event.preventDefault();
    onEdit(item);
  };

  const remove = event => {
    closeDropdown();
    onRemove(item);
    event.preventDefault();
  };

  return (
    <div className="dropdown dropdown-end">
      <div tabIndex={0} role="button" className="btn btn-ghost btn-sm m-1">
        <EllipsisVerticalIcon className="w-5 h-5" />
      </div>
      <ul tabIndex={0} className="dropdown-content z-1000 menu p-2 shadow bg-base-100 rounded-box w-52">
        <li>
          {onEdit && <button onClick={edit}>Edit</button>}
          {onRemove && <button onClick={remove}>Remove</button>}
        </li>
      </ul>
    </div>
  );
};

ListActions.propTypes = {
  item: PropTypes.object,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
};

export default ListActions;
