import PropTypes from 'prop-types';

import Tooltip from '../Tooltip';

const FormRow = ({ label, name, tooltip, source, children, className = '' }) => {
  const nodeLabelClasses = 'group-[.node]:pt-0';
  return (
    <div className="flex group-[.node]:flex-col gap-4 group-[.node]:gap-0">
      <div className={`pb-2 pt-1 relative ${nodeLabelClasses} ${className} flex-1`}>
        <div className="flex items-center gap-1 self-center">
          {label && (
            <label htmlFor={name} className="block truncate text-xs font-semibold">
              {label}
            </label>
          )}
          {tooltip && <Tooltip label={label} text={tooltip} source={source} />}
        </div>
      </div>
      <div className={`pb-2 ${className} flex items-start flex-1 group-[.node]:grow-[2]`}>{children}</div>
    </div>
  );
};

FormRow.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  source: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FormRow;
