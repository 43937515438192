import { useLoaderData, useSubmit, useNavigate } from 'react-router-dom';
import ListActions from '@/components/core/ListActions';
import { confirm } from '@/components/core/createConfirmation';

const ProjectList = () => {
  const {
    data: { results: projects },
  } = useLoaderData();
  const submit = useSubmit();
  const navigate = useNavigate();

  const remove = async project => {
    if (await confirm('Are you sure you want to remove this project?')) {
      submit(
        {},
        {
          method: 'delete',
          action: `/projects/${project.id}/destroy`,
        },
      );
    }
  };

  const onOpen = (projectId, event) => {
    if (event.target.tagName === 'TD') {
      navigate(`/projects/${projectId}`);
    }
  };

  const edit = project => navigate(`/projects/${project.id}/edit`);

  if (!projects.length) {
    return <p className="p-5 text-center">No projects found</p>;
  }

  return (
    <>
      <table className="w-full text-gray-500 dark:text-gray-400">
        <tbody>
          
          {projects.map(project => (
            <tr
              key={project.id}
              onClick={event => onOpen(project.id, event)}
              className="group cursor-pointer flex justify-between items-center dark:bg-gray-200 dark:border-gray-200 hover:bg-gray-100 hover:text-gray-800"
            >
              <td className="px-6 py-3 text-gray-700 text-sm flex-1">{project.name}</td>
              <td className="justify-end group-hover:flex hidden">
                <ListActions item={project} onRemove={remove} onEdit={edit} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ProjectList;
