import PropTypes from 'prop-types';
import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import { systemAnalysisActions } from '@/stores/systemAnalysisStore';
import TimeSeriesBarChart from '@/components/charts/TimeSeriesBarChart';
import { useEffect, useState } from 'react';
import { chooseSeriesByPage, divideSeries } from '@/utils/systemAnalysisUtils';
import TimeSeriesForm from './TimeSeriesForm';
import { getMonthRanges, getWeekRanges, transpose } from '@/utils/miscUtils';
import { HOURS_PER_MONTH, HOURS_PER_WEEK, HOURS_PER_YEAR, LINE_CHART } from '@/consts';
import TimeSeriesChart from '@/components/charts/TimeSeriesChart';
import { unitToString } from '@/utils/unitUtils';
import { getUnitOptions } from '@/utils/pathwayUtils';

const ranges = {
  [HOURS_PER_WEEK]: getWeekRanges,
  [HOURS_PER_MONTH]: getMonthRanges,
  [HOURS_PER_YEAR]: () => [],
};

const TimeSeriesResult = ({ initPlot, initUnit, chartType, results, options, useContainer = true }) => {
  const [formData, setFormData] = useState({
    pageIndex: 0,
    plot: initPlot,
    flowRate: HOURS_PER_WEEK,
  });
  const [selectedUnit, setSelectedUnit] = useState(JSON.stringify(initUnit));
  const { flowRate, pageIndex, plot } = formData;
  const result = results[plot];

  useEffect(() => {
    if (result?.unit) {
      setSelectedUnit(JSON.stringify(result.unit));
    }
  }, [result]);

  if (!result) return null;

  const onSubmit = data => {
    if (data.unit !== selectedUnit) {
      systemAnalysisActions.updateResult({ unit: data.unit, plot: formData.plot });
      setSelectedUnit(data.unit);
    }
    setFormData(data);
  };

  const { label, series, unit, convertible_units } = result;
  const dividedSeries = divideSeries(series, flowRate);
  const pages = dividedSeries[0].chunks;
  const pageOptions = ranges[flowRate](pages);
  const seriesByPage = chooseSeriesByPage(dividedSeries, pageIndex);
  const columns = seriesByPage.map(m => m.label);
  const rows = transpose(seriesByPage.map(m => m.data));
  const isDispatch = formData.plot === 'dispatch';

  const unitString = unitToString(unit);
  const unitOptions = getUnitOptions(convertible_units);

  const content = (
    <>
      {chartType == LINE_CHART ? (
        <TimeSeriesChart
          rows={rows}
          columns={columns}
          flowRate={flowRate}
          pageIndex={pageIndex}
          series={seriesByPage}
          label={label}
          yAxisLabel={`\n${unitString}`}
          unit={unitString}
          fill={isDispatch}
        />
      ) : (
        <TimeSeriesBarChart flowRate={flowRate} pageIndex={pageIndex} series={seriesByPage} label={label} />
      )}
      <TimeSeriesForm
        defaultValues={{ ...formData, unit: selectedUnit }}
        pageOptions={pageOptions}
        hourlyTimeSeriesOptions={options}
        unitOptions={unitOptions}
        onSubmit={onSubmit}
      />
    </>
  );

  if (!useContainer) return content;

  return (
    <AnalysisContainer title={label}>
      <AnalysisBox>{content}</AnalysisBox>
    </AnalysisContainer>
  );
};

TimeSeriesResult.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  chartType: PropTypes.string,
  results: PropTypes.object,
  initPlot: PropTypes.string,
  initUnit: PropTypes.object,
  useContainer: PropTypes.bool,
};

export default TimeSeriesResult;
