import PropTypes from 'prop-types';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from '@xyflow/react';
import EdgePopover from '../nodes/EdgePopover';
import { useBalancedPortValue, useIsBalanced } from '@/stores/caseStore';
import BalancedValues from './BalancedValues';

const PathwayEdge = props => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style,
    markerEnd,
    sourceHandleId,
    targetHandleId,
    label,
    source,
    data: { name, anchorable, unit } = {},
  } = props;

  const balancedValues = useBalancedPortValue(source, 'output', name);
  const isBalanced = useIsBalanced();
  const showBalancedValues = isBalanced && balancedValues;

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeStyle = {
    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
    pointerEvents: 'all',
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={edgeStyle}
          className="z-1000 bg-base-100 p-2 pointer nodrag nopan absolute rounded border border-gray-300 hover:bg-gray-300"
        >
          <EdgePopover
            anchorable={anchorable}
            name={name}
            label={label}
            edgeId={id}
            sourceId={source}
            sourceHandle={sourceHandleId}
            targetHandle={targetHandleId}
            unit={unit}
          >
            <div className="text-center font-semibold p-2">{label}</div>
            {showBalancedValues && <BalancedValues className="text-center" values={balancedValues} />}
          </EdgePopover>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

PathwayEdge.propTypes = {
  id: PropTypes.string,
  sourceX: PropTypes.number,
  sourceY: PropTypes.number,
  targetX: PropTypes.number,
  targetY: PropTypes.number,
  sourcePosition: PropTypes.string,
  targetPosition: PropTypes.string,
  style: PropTypes.object,
  markerEnd: PropTypes.string,
  sourceHandleId: PropTypes.string,
  targetHandleId: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  data: PropTypes.object,
};

export default PathwayEdge;
