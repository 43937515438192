import { useBalance, useNodeById } from '@/stores/analysisStore';
import { analysisActions } from '@/stores/analysisStore';
import BalanceForm from './BalanceForm';
import { Fragment } from 'react';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AnalysisBox from '@/components/core/AnalysisBox';

const Balance = () => {
  const plot = useBalance();
  const { nodeId, results } = plot;
  const node = useNodeById(nodeId);
  const updatePlot = formData => analysisActions.updatePlot('balance', formData);

  if (!node || !results) {
    return null;
  }

  return (
    <AnalysisContainer title="Mass and Energy Balances">
      <AnalysisBox>
        <BalanceForm onSubmit={updatePlot} defaultValues={plot} />
        <table className="w-full text-xs">
          <tbody>
            {results?.map(({ header, rows }) => {
              return (
                <Fragment key={header}>
                  <tr className="border-b border-black leading-loose">
                    <th className="text-left pl-4 pt-2" dangerouslySetInnerHTML={{ __html: header }} />
                    <th className="text-left">Quantity</th>
                    <th className="text-left">Units</th>
                  </tr>

                  {rows.map(({ label, quantity, unit }) => (
                    <tr key={`${header}-${label}`} className="leading-loose">
                      <td className="pl-4">{label}</td>
                      <td>{quantity && quantity.toFixed(2)}</td>
                      <td>{unit}</td>
                    </tr>
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default Balance;
