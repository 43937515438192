import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import AttributesTable from './AttributesTable';
import { useSystemResult, useTechnologies } from '@/stores/systemAnalysisStore';
import { useState } from 'react';
import WaterfallChart from '@/components/charts/WaterfallChart';
import { parseTEAGraphData } from '@/utils/analysisUtils';
import { unitToString } from '@/utils/unitUtils';
import { isDefined } from '@/utils/miscUtils';

const getTechnologyType = name => {
  return name?.split('summary_')[1] ?? name?.split('_attributes')[0];
};

const getSystemResult = type => {
  return type.includes('generator') ? `${type}_costs` : `costs_${type}`;
};

const TechnologiesResult = () => {
  const technologies = useTechnologies();
  const [technology, setTechnology] = useState(technologies[0] || {});
  const technologyType = getTechnologyType(technology?.name);
  const systemResult = getSystemResult(technologyType);
  const { costs } = useSystemResult(systemResult) ?? { costs: [] };

  const data = costs.map(({ category, cost }) => ({
    label: category.join(' '),
    cost,
  }));

  const waterfallData = data?.length ? parseTEAGraphData(data) : null;
  const yAxisLabel = unitToString(costs.unit);

  return (
    <AnalysisContainer title="Technologies">
      <AnalysisBox>
        <div role="tablist" className="tabs tabs-bordered">
          {technologies.map(tech => (
            <button
              className={`tab tab-border ${technology.name === tech.name ? 'tab-active font-bold' : 'font-normal'}`}
              onClick={() => setTechnology(tech)}
              key={tech.label}
            >
              {tech.label}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-3">
          <div>
            {isDefined(waterfallData) && (
              <WaterfallChart data={waterfallData} label={costs.label} yAxisLabel={`\n${yAxisLabel}`} />
            )}
          </div>

          <div>
            <AttributesTable name={technology.name} className="w-80" />
          </div>
        </div>
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default TechnologiesResult;
