import WaterfallStackedChart from '@/components/charts/WaterfallStackedChart';
import AnalysisBox from '@/components/core/AnalysisBox';
import AnalysisContainer from '@/components/core/AnalysisContainer';
import { useCosts } from '@/stores/systemAnalysisStore';
import { parseStackedWaterfallData } from '@/utils/systemAnalysisUtils';
import { unitToString } from '@/utils/unitUtils';

const CostResult = () => {
  const costsData = useCosts();

  if (!costsData) {
    return null;
  }

  const results = parseStackedWaterfallData(costsData.costs);

  const unit = unitToString(costsData.unit);

  return (
    <AnalysisContainer title={costsData.label}>
      <AnalysisBox>
        <div className="ml-4 mr-4 mt-2">
          <WaterfallStackedChart datasets={results.datasets} labels={results.labels} label={`\n${unit}`} />
        </div>
      </AnalysisBox>
    </AnalysisContainer>
  );
};

export default CostResult;
