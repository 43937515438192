import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import userApi from '@/api/user';

const initialValues = {
  email: null,
  name: null,
};

const useUserStore = create()(
  immer(
    persist(() => initialValues, {
      name: 'userStore',
      version: 1,
    }),
  ),
);

const { setState: set } = useUserStore;

// actions
export const userActions = {
  getUserInfo: async () => {
    const { data } = await userApi.getUserInfo();
    const { email, name } = data;
    set({ email, name });
  },
  setUserInfo: ({ email, name }) => {
    set({ email, name });
  },
};

// selectors
export const useEmail = () => useUserStore(store => store.email);
export const useName = () => useUserStore(store => store.name);

export default useUserStore;
