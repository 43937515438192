import PathwayList from '../pathways/PathwayList';
import SystemList from '../systems/SystemList';
import ListPane from './ListPane';

const BuildDropdownContent = () => (
  <ListPane>
    <PathwayList />
    <SystemList />
  </ListPane>
);

export default BuildDropdownContent;
