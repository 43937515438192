export const getStack = () => {
  const { hostname } = window.location;
  const [name] = hostname.split('.');

  if (name === 'app') {
    return 'prod';
  }

  if (name === 'main' || hostname.match(/staging/gi)) {
    return 'staging';
  }

  if (name === 'localhost') {
    return 'local';
  }

  return name;
};

export const getServerUrl = () => {
  const stack = getStack();

  if (stack === 'local') {
    return import.meta.env.VITE_SERVER_URL ?? 'http://localhost:8000/api';
  }

  if (stack === 'prod') {
    return 'https://api.sesame.software/api';
  }

  return `https://api-${stack}.sesame.software/api`;
};

export const getStackEnv = name => {
  const stack = getStack().toUpperCase();
  return import.meta.env[`VITE_${stack}_${name}`];
};
