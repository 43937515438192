import { parseCategories } from '@/utils/paramsUtils';
import { unitToString } from '@/utils/unitUtils';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const CashFlowDataGrid = ({ data }) => {
  const categories = parseCategories(data);

  const rendeBySubCategory = params => {
    const subCategories = parseCategories(params, 1);

    return Object.values(subCategories).map(({ categoryName, params }, index) => {
      const { data, unit, total } = params[0];

      const RowComponent = total ? 'th' : 'td';

      return (
        <tr
          key={`${categoryName}-${index}`}
          className={`${total ? 'border-b-2 border-t-2 border-black' : 'border-dashed !border-black/30 border-t border-b-0'}`}
        >
          <RowComponent className="min-w-72 pr-3 text-left">{categoryName}</RowComponent>
          <RowComponent className="px-3 text-left">{unitToString(unit)}</RowComponent>
          {data.map((item, subIndex) => {
            const value = Number.isInteger(item) ? item : item.toFixed(2);
            return (
              <RowComponent key={`${categoryName}-${index}-${subIndex}`} className="px-3 text-right">
                {value}
              </RowComponent>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="overflow-auto w-full mt-2 mb-2">
      <table className="overflow-auto w-full prose prose-sm min-w-full">
        <tbody>
          {Object.values(categories).map(({ categoryName, params }, index) => {
            return (
              <Fragment key={`${categoryName}-${index}`}>
                <tr>
                  <th className="pt-8 border-b-2 border-black text-sm" colSpan={params[0].data.length + 2}>
                    {categoryName}
                  </th>
                </tr>
                {rendeBySubCategory(params)}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

CashFlowDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export default CashFlowDataGrid;
