import { isDefined, keyBy, roundValue } from './miscUtils';
import { convert } from './unitUtils';

export const validatorRules = {
  gte: quantity => value => (value >= quantity ? true : `value has to be greater than or equal ${quantity}`),
  lte: quantity => value => (value <= quantity ? true : `value has to be lower than or equal ${quantity}`),
  gt: quantity => value => (value >= quantity ? true : `value has to be greater than ${quantity}`),
  lt: quantity => value => (value <= quantity ? true : `value has to be lower than ${quantity}`),
  numeric: () => value => (!isNaN(value) ? true : 'value has to be numeric'),
  integer: () => value => (!isNaN(value) && /^-?\d+$/.test(value) ? true : `value has to be an integer`),
  min_size: size => value => (value.length >= size ? true : `number of blocks has to be greater than ${size}`),
  max_size: size => value => (value.length < size ? true : `number of blocks has to be lower than ${size}`),
  sum_to: (quantity, paramsByName) => fields => {
    const tol = 10e-6;
    const conversions = fields.conversion;
    const values = Object.keys(fields)
      .filter(name => name !== 'conversion')
      .map(name => {
        const conversionStr = conversions[name];
        const param = paramsByName[name];
        const value = fields[name];

        if (conversionStr && param?.baseConversion) {
          const conversion = JSON.parse(conversionStr);
          const quantity = convert(conversion, param?.baseConversion, value);

          return quantity;
        }

        return value;
      });

    if (!values) {
      return true;
    }

    const sum = values.reduce((acc, value) => {
      if (isDefined(value)) {
        acc += Number(value);
      }

      return acc;
    }, 0);

    return Math.abs(sum - quantity) < tol ? true : `sum of parameters needs to equal ${quantity} in base unit (currently ${roundValue(sum,6)})`;
  },
};

export const buildGroupValidators = (validators, params) => {
  const pickedValidators = {};
  const paramsByName = keyBy(params, 'name');

  validators.forEach(({ name, args }) => {
    if (/sum_to/.test(name)) {
      const validator = validatorRules[name];
      pickedValidators[name] = validator(args[0], paramsByName);
    }
  });

  return pickedValidators;
};

export const buildDynamicValidators = (paramValidators, baseConversion, currentConversion) => {
  const pickedValidators = {};

  paramValidators.forEach(({ name, args }) => {
    if (/gte|gt|lt|lte/.test(name)) {
      const validator = validatorRules[name];
      const quantity = convert(baseConversion, currentConversion, args[0]);

      pickedValidators[name] = validator(quantity);
    }
  });

  return pickedValidators;
};

export const buildValidators = paramValidators => {
  const pickedValidators = {
    required: value => (value !== undefined ? true : 'value is required'),
  };

  paramValidators.forEach(({ name, args }) => {
    const validator = validatorRules[name];
    if (validator) {
      pickedValidators[name] = validator(...args);
    }
  });

  return pickedValidators;
};

export const buildMcValidators = (paramValidators, mainValue, type = 'min', baseConversion, currentConversion) => {
  const mcValidators = JSON.parse(JSON.stringify(paramValidators));

  return buildValidators(
    mcValidators.map(({ name, args }) => {
      if (type === 'max') {
        if (/gte|gt/.test(name)) {
          args[0] = mainValue;
        }

        if (/lte|lt/.test(name) && currentConversion) {
          args[0] = convert(baseConversion, currentConversion, args[0]);
        }
      }

      if (type === 'min') {
        if (/lte|lt/.test(name)) {
          args[0] = mainValue;
        }

        if (/gte|gt/.test(name) && currentConversion) {
          args[0] = convert(baseConversion, currentConversion, args[0]);
        }
      }

      return { name, args };
    }),
  );
};

export const parseErrors = error => {
  return error.details.reduce((acc, error) => {
    acc[error.context.key] = error.message;
    return acc;
  }, {});
};
