import zip from 'just-zip-it';
import { keyBy, sortByValue } from './miscUtils';
import { getEmissionTypes } from './pathwayUtils';
import { unitToString } from './unitUtils';
import { CO2_EQUIVALENT, ELECTRICITY_TYPE } from '@/consts';

export const parseLCAComparisonData = (data, columns) => {
  // index is used to group costs with the same `label` in the same row
  const index = {};
  const values = Object.values(data);
  const rows = [];
  const totals = [];
  const emissionsSet = new Set();
  let maxUpstreamEmission = ['', 0];

  values.forEach(({ emissions }) => {
    emissions.forEach(emission => {
      emissionsSet.add(emission.label);
    });
  });

  const allEmissionsQuantity = Array.from(emissionsSet);

  values.forEach((value, valueIndex) => {
    const { emissions } = value;
    const lsByLabel = keyBy(emissions, 'label');
    let total = 0;

    allEmissionsQuantity.forEach(label => {
      if (typeof index[label] === 'undefined') {
        index[label] = Object.keys(index).length;
        rows.push([label]); // header
      }

      if (lsByLabel[label]) {
        const emission = lsByLabel[label];
        const value = emission.quantity.scalar;
        rows[index[label]].push(value);
        total += value;
      } else {
        rows[index[label]].push(0);
      }
    });

    const scope2emissions = emissions.filter(emission => emission.type === 'scope2');
    const scope3emissions = emissions.filter(emission => emission.type === 'scope3');

    const scope2Total = scope2emissions.reduce((acc, emission) => acc + emission.quantity.scalar, 0);
    const scope3Total = scope3emissions.reduce((acc, emission) => acc + emission.quantity.scalar, 0);

    const proportion = (scope2Total + scope3Total) / total;

    if (maxUpstreamEmission[1] < proportion) {
      maxUpstreamEmission = [columns[valueIndex], proportion];
    }
    totals.push(total);
  });

  const maxTotal = zip(columns, totals).sort((a, b) => b[1] - a[1])[0];
  const minTotal = zip(columns, totals).sort((a, b) => a[1] - b[1])[0];
  const scopeProportion = maxUpstreamEmission[1] * 100;
  const maxUpstreamIndex = columns.indexOf(maxUpstreamEmission[0]);

  return {
    rows,
    columns,
    minTotal,
    maxTotal,
    maxProportions: [
      maxUpstreamEmission[0],
      {
        labels: rows,
        data: [rows[0][maxUpstreamIndex + 1], rows[1][maxUpstreamIndex + 1], rows[2][maxUpstreamIndex + 1]],
        maxScope: {
          label: 'Scope 2+3',
          proportion: scopeProportion,
        },
      },
    ],
  };
};

export const parseCOACComparisonData = (data, columns, params, savedCases) => {
  const headers = ['coac'];
  const values = Object.values(data);
  const { baseline } = params;
  const rows = headers.map(header => [header]);
  const unit = values?.[0]?.coac?.unit ?? { label: '$/kg' };
  const total = [];
  const errors = [];

  savedCases.forEach(savedCase => {
    if (baseline !== savedCase.id) {
      if (data?.[savedCase.id]?.coac) {
        const {
          coac: { scalar },
        } = data[savedCase.id];
        rows[0].push(scalar);
        total.push(scalar);
      } else {
        errors.push(savedCase.name);
      }
    }
  });

  const maxTotal = zip(columns, total).sort((a, b) => b[1] - a[1])[0];
  const minTotal = zip(columns, total).sort((a, b) => a[1] - b[1])[0];

  return { columns, rows, yAxis: unitToString(unit), minTotal, maxTotal, errors };
};

export const parseTEAComparisonData = (data, columns) => {
  // index is used to group costs with the same `label` in the same row
  const index = {};
  const values = Object.values(data);
  const rows = [];
  const totals = [];
  const levelizedCostSet = new Set();

  values.forEach(({ levelized_costs }) => {
    levelized_costs.forEach(levelizedCost => {
      levelizedCostSet.add(levelizedCost.label);
    });
  });

  const allLevelizedCosts = Array.from(levelizedCostSet);

  values.forEach(value => {
    const { levelized_costs } = value;
    // columns.push(comparisonCasesById[id].name);
    const lsByLabel = keyBy(levelized_costs, 'label');

    let total = 0;
    allLevelizedCosts.forEach(label => {
      if (typeof index[label] === 'undefined') {
        index[label] = Object.keys(index).length;
        rows.push([label]); // header
      }

      if (lsByLabel[label]) {
        const levelizedCost = lsByLabel[label];
        rows[index[label]].push(levelizedCost.cost);
        total += levelizedCost.cost;
      } else {
        rows[index[label]].push(0);
      }
    });

    totals.push(total);
  });

  const maxTotal = zip(columns, totals).sort((a, b) => b[1] - a[1])[0];
  const minTotal = zip(columns, totals).sort((a, b) => a[1] - b[1])[0];

  return { rows, columns, minTotal, maxTotal };
};

export const parseLCAComparisonFormData = formData => {
  const { emissionType, levelizationUnit, scope3Breakdown } = formData;
  const params = {
    levelization_unit: JSON.parse(levelizationUnit),
  };

  params.scope3_breakdown = !!scope3Breakdown;

  if (emissionType) {
    const emissionTypeObj = JSON.parse(emissionType);
    const { emission_type, co2_equivalent } = emissionTypeObj;

    params.co2_equivalent = co2_equivalent;

    if (emission_type !== CO2_EQUIVALENT) {
      params.emission_type = emission_type;
    }
  }

  return params;
};

export const parseCOACComparisonFormData = (formData, options) => {
  const { unit = 'ton' } = formData;
  const baseline = formData?.baseline ?? options?.savedCases?.[0]?.id;

  const params = {
    baseline,
    levelization_unit: {
      numerator: [{ name: unit }],
      denominator: [],
    },
  };

  return params;
};

export const parseTEAComparisonFormData = formData => {
  const { levelizationUnit } = formData;

  const params = {
    levelization_unit: JSON.parse(levelizationUnit),
  };

  return params;
};

const areAllOutputTypesTheSame = (outputs, outputType) => {
  return outputs.every(output => output.type.name === outputType);
};

const getNode = (comparisonCase, pathways) => {
  const { case_id, node_id } = comparisonCase;
  return pathways?.[case_id]?.nodes?.[node_id];
};

export const findOutput = (comparisonCase, pathways) => {
  const { output_name } = comparisonCase;
  const node = getNode(comparisonCase, pathways);

  return node?.outputs?.find(output => output.name === output_name);
};

export const getUnits = (comparisonCases, pathways) => {
  const outputs = Object.values(comparisonCases)
    .map(comparisonCase => findOutput(comparisonCase, pathways))
    .filter(output => output);

  if (!outputs.length) {
    return [];
  }

  const firstOutput = outputs[0];
  const outputType = firstOutput?.type?.name;

  if (areAllOutputTypesTheSame(outputs, outputType)) {
    if (firstOutput.name === ELECTRICITY_TYPE) {
      return [
        {
          numerator: [{ name: 'kWh', species: null }],
          denominator: [],
          measure: 'energy',
          label: 'kWh',
        },
        {
          numerator: [{ name: 'MWh', species: null }],
          denominator: [],
          measure: 'energy',
          label: 'MWh',
        },
      ];
    }

    if (firstOutput?.type?.convertible_units) {
      return sortByValue(firstOutput.type.convertible_units, 'label', 'ton');
    }

    if (firstOutput?.convertible_units) {
      return sortByValue(firstOutput.convertible_units, 'label', 'ton');
    }

    return [firstOutput?.type?.unit];
  }

  return [
    {
      numerator: [{ name: 'mmBtu', species: null }],
      denominator: [],
      measure: 'energy',
      label: 'mmBtu',
    },
    {
      numerator: [{ name: 'Btu', species: null }],
      denominator: [],
      measure: 'energy',
      label: 'Btu',
    },
  ];
};

export const areComparisonCasesSaved = comparisonCases => {
  return Object.values(comparisonCases).find(comparisonCase => !!comparisonCase.id);
};

export const getSavedComparisonCases = comparisonCases => {
  return Object.values(comparisonCases).filter(comparisonCase => !!comparisonCase.id);
};

export const getComparisonEmissionTypes = (comparisonCases, pathways) => {
  const emissionTypes = Object.values(comparisonCases).flatMap(comparisonCase => {
    const nodes = Object.values(pathways?.[comparisonCase.case_id]?.nodes ?? {});

    return getEmissionTypes(nodes);
  });

  if (!emissionTypes.length) {
    return [];
  }

  const emissionTypesByLabel = keyBy(emissionTypes, 'label');
  return Object.values(emissionTypesByLabel);
};

export const getLCAYaxisLabel = plot => {
  const { levelizationUnit } = plot;
  const unitObj = JSON.parse(levelizationUnit);
  const emissionType = plot?.emissionType ? JSON.parse(plot?.emissionType) : {};
  const { label, unit } = emissionType;
  const yAxisLabel = label === 'CO₂ Equivalent' ? 'CO₂e' : label;

  return `${unitToString(unit)}${yAxisLabel}/${unitToString(unitObj)}`;
};

export const getTEAYaxisLabel = plot => {
  const { levelizationUnit } = plot;
  const unitObj = JSON.parse(levelizationUnit);

  return `$/${unitToString(unitObj)}`;
};
